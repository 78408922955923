import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './checkbox.scss';

export type CheckboxProps = {
  // Shared
  name: string;
  id?: string;
  // Native
  checked?: boolean,
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({field}) => {
        return (
          <div className="checkbox">
            <input id={props.id} className={`checkbox__input ${field.value ? "checkbox__input--checked" : ""}`} type="checkbox" checked={field.value} onChange={field.onChange}/>
          </div>
        );
      }}
    />
    );
  }
  else {
    return (
      <div className="checkbox">
        <input {...props} id={props.id} className={`checkbox__input ${props.checked ? "checkbox__input--checked" : ""}`} type="checkbox" checked={props.checked} onChange={props.onChange}/>
      </div>
    );
  }
}

export default Checkbox;