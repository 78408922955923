import { FunctionComponent, useEffect, useState } from "react";
import {
  COCRequestTable,
  Modal,
  PageTitle,
  RequestCOCModal,
} from "_components";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { useLoading } from "_hooks";
import { createCOCRequests, getCOCRequests } from "_api/endpoints/COCRequest";
import { COCRequestResponseType } from "_api";
import { toast } from "react-toastify";
import "./conformity-certificate-page.scss";
import { atomCOC /*atomUserRoles */ } from "_atoms";
import { useRecoilState /*useRecoilValue */ } from "recoil";

const ConformityCertificatePage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [atomcoc] = useRecoilState(atomCOC);

  const [isToRequestOpen, setIsToRequestOpen] = useState(false);
  const [numberRequest, setNumberRequest] = useState("");
  const [orno, setOrno] = useState("");
  const [bano, setBano] = useState("");
  const [clientName, setClientName] = useState("");

  //const userRoles = useRecoilValue(atomUserRoles);

  const [COCRequests, setCOCRequests] = useState<COCRequestResponseType[]>([]);
  const { setLoading } = useLoading();
  const CUNOArray: string[] = atomcoc.map(
    (item: { CUNO: string; name: string }) => item.CUNO
  );

  //  const dealersList = userRoles && userRoles.dealers ? userRoles.dealers : [];
  // const userBrand = userRoles?.brands;

  /*const filteredDealers = dealersList.filter((dealer: any) => {
                        let found = false;
                        dealer.brands.forEach((brand: any) => {
                            if (brand.name === userBrand) {
                                found = true;
                            }
                        });
                        return found; 
                      });
                    
                      const filteredDealerIds = filteredDealers.map((dealer : any)=> dealer.id);
                    */

  const initData = () => {
    setLoading(true);
    getCOCRequests(CUNOArray)
      .then((requestResult: AxiosResponse<COCRequestResponseType[]>) => {
        setCOCRequests(requestResult.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 413) {
          toast.error(t("toast_get_data_error_pim_coc"));
        } else {
          toast.error(t("toast_get_data_error"));
        }
      });
  };

  const requestCOC = (
    orno: string,
    bano: string,
    clientName: string,
    comment: string
  ) => {
    setLoading(true);
    createCOCRequests(orno, bano, clientName, comment)
      .then((response) => {
        console.log(response);
        initData();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error creating COC request:", error);
        setLoading(false);
        toast.error("data_loading_error 2");
      });
  };

  useEffect(() => {
    if (CUNOArray && CUNOArray.length) {
      initData();
    } else {
      setCOCRequests([]);
    }
  }, [atomcoc]);

  const handleOpenToRquestModal = () => {
    setIsToRequestOpen(true);
  };

  const handleSelectedNumberRequest = (requestNumber: string) => {
    setNumberRequest(requestNumber);
  };

  const handleSelectedORNO = (orno: string) => {
    setOrno(orno);
  };

  const handleSelectedBANO = (bano: string) => {
    setBano(bano);
  };

  const handleSelectedClientName = (clientName: string) => {
    setClientName(clientName);
  };

  const handleCloseToRequestModal = () => {
    setIsToRequestOpen(false);
  };

  return (
    <div className="conformity-certificate-page">
      <PageTitle
        title={t("conformity_certificate")}
        description={t("conformity_certificate_description")}
      />
      <div className="conformity-certificate-page__main-wrapper">
        {COCRequests && COCRequests.length ? (
          <COCRequestTable
            COCRequests={COCRequests}
            setIsToRequestOpen={handleOpenToRquestModal}
            selectedNumberRequest={handleSelectedNumberRequest}
            selectedORNO={handleSelectedORNO}
            selectedBANO={handleSelectedBANO}
            selectedClientName={handleSelectedClientName}
          />
        ) : null}
        {isToRequestOpen && (
          <Modal
            content={true}
            onValidate={() => {
              setIsToRequestOpen(false);
            }}
          >
            <RequestCOCModal
              orno={orno}
              bano={bano}
              existingClientName={clientName}
              numberRequest={numberRequest}
              onClose={handleCloseToRequestModal}
              onRequestCOC={requestCOC}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ConformityCertificatePage;
