import { FunctionComponent, useEffect, useRef, useState } from "react";
import './header-dropdown.scss';
import { useMsal } from "@azure/msal-react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { atomToken, atomUser, atomUserRole } from "_atoms";
import { useLoading } from "_hooks";
import { useTranslation } from "react-i18next";

const HeaderDropdown: FunctionComponent = () => {
  const wrapperRef = useRef(null);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const resetToken = useResetRecoilState(atomToken);
  const [opened, setOpened] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const user = useRecoilValue(atomUser);
  const { t } = useTranslation();

  const [userRole] = useRecoilState(atomUserRole);

    const isADV = userRole?.name === "ADV";
    const isCommercial = userRole?.name === "Manager_commercial";
    const isCommercialLimite = userRole?.name === "Commercial";
    const isDAF = userRole?.name === "DAF";


  const disconnect = () => {
    setLoading(true);
    setOpened(false);
    instance.logoutPopup().then(() => {
      setLoading(false);
      resetToken();
      navigate('/login');
    }, (error) => {
      setLoading(false);
      console.error(error);
      toast.error(t('toast_disconnect_error'));
    })
  };
  

  useEffect(() => {
    //console 
  }, [user?.completeName]);

  return (
    <div className="header-dropdown" ref={wrapperRef} onMouseLeave={() => setOpened(false)} onMouseEnter={() => setOpened(true)}>

      <div className="header-dropdown__selector-wrapper">
        <button className="header-dropdown__icon" onClick={() => navigate('/account/profile')}></button>
        <span className="header-dropdown__userinfos">
          <span className="header-dropdown__username">{user?.completeName}</span>
        </span>
        <span className={`header-dropdown__selector-button ${opened ? 'header-dropdown__selector-button--opened' : ''}`}></span>
      </div>

      {/*<div className={`header-dropdown__dropdown-background ${opened ? 'header-dropdown__dropdown-background--active' : ''}`} ></div>*/}

      <div className={`header-dropdown__dropdown ${opened ? 'header-dropdown__dropdown--opened' : ''}`} >
        <div className="header-dropdown__dropdown-userinfos" onClick={() => navigate('/account/profile')}>
          <span className="header-dropdown__dropdown-username">{user?.completeName}</span>
        </div>
        <div className="header-dropdown__links-container">
          <NavLink to="/account/profile" className="header-dropdown__link" >
            <><i className="icon-home" />{t('header_dropdown_profile')}</></NavLink>
          <NavLink to="/account/manage-users" className={`header-dropdown__link${(isADV || isCommercial || isCommercialLimite || isDAF ) ? ' disabled' : ''}`} ><><i className="icon-account-group" />{t('header_dropdown_users')}</></NavLink>
          <NavLink to="/alerts/all" className="header-dropdown__link" ><><i className="icon-bell" />{t('header_dropdown_notifications')}</></NavLink>
          <NavLink to="/account/contacts" className="header-dropdown__link" ><><i className="icon-handshake" />{t('header_dropdown_contact')}</></NavLink>
        </div>
        <button className="header-dropdown__disconnect" onClick={disconnect}>{t('header_dropdown_disconnect')}</button>
      </div>

    </div>
  );
}

export default HeaderDropdown;