import {FunctionComponent, useEffect, useState} from "react";
import "./settings-page.scss";
import {ToggleButton} from "_components";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {atomUser, atomUserRole} from "_atoms";
import {
    AlertSettings,
    AlertsSettingsResponseType,
    getAlertsSettings,
    updateAlertSetting,
} from "_api";
import {useLoading} from "_hooks";

const SettingsPage: FunctionComponent = () => {
    const {t} = useTranslation();
    const userLogged = useRecoilValue(atomUser);
    const userRole = useRecoilValue(atomUserRole);
    const [alerts, setAlerts] = useState<AlertsSettingsResponseType>([]);
    const {setLoading} = useLoading();

    const isDAF = userRole?.name === "DAF";
    const isCommercialLimite = userRole?.name === "Commercial";
    const isManagerADV = userRole?.name === "Manager_ADV";
    const isManagerCommercial = userRole?.name === "Manager_commercial";
    const isADV = userRole?.name === "ADV";

    const loadData = () => {
        if (userLogged && userRole) {
            setLoading(true);
            getAlertsSettings(
                userLogged.id,
                userRole.name.toUpperCase().replace("-", "_"),
                userLogged.username
            ).then(
                (result) => {
                    const getAlerts: AlertsSettingsResponseType = result.data;
                    const filteredAlerts = getAlerts
                        .filter((alert) => !alert.disabled)
                        .sort((a, b) => a.order - b.order);
                    const translatedAlerts = translateAlertNames(filteredAlerts);
                    setAlerts(translatedAlerts);
                    setLoading(false);
                },
                (error) => {
                    console.log("error: ", error);
                    setLoading(false);
                }
            );
        }
    };

    const translateAlertNames = (alerts: AlertSettings[]) => {
        return alerts.map((alert: AlertSettings) => ({
            ...alert,
            name: t(`alert_${alert.name}`),
        }));
    };

    useEffect(() => {
        loadData();
    }, [t]);

    const toggleAlert = (
        settingId: string,
        type: "trigano" | "email",
        value: boolean
    ) => {
        setLoading(true);
        if (userLogged) {
            updateAlertSetting(userLogged?.id, settingId, type, value).then(
                () => {
                    setLoading(false);
                },
                (error) => {
                    console.log("error: ", error);
                    setLoading(false);
                }
            ).then(() => loadData())
        }
    };

    return (
        <div className="settings-page">
            <div className="settings-page__header">
                <div className="settings-page__title">
                    {t("settings_page_title_trigano_pro")}
                </div>
                <div className="settings-page__title second">
                    {t("settings_page_title_email")}
                </div>
            </div>
            <div className="settings-page__main-wrapper">
                <div className="settings-page__row"></div>
            </div>
            <div className="settings-page__alerts">
                <div className="settings-page__row">
                    <div className="settings-page__categories">
                        {alerts.map((alert) => {
                            // if i'm a DAF user
                            if (isDAF && (alert.id === "validationCommande" ||
                                alert.id === "tombeChaine" ||
                                alert.id === "expediee" ||
                                alert.id === "COC" ||
                                alert.id === "retardee" ||
                                alert.id === "annulee"))
                                return null;

                            // if i'm a Comercial user user
                            if (isCommercialLimite && (alert.id === "retardee" || alert.id === "annulee" || alert.id === "impaye"))
                                return null;

                            // if i'm a Manager commercial or a ADV
                            if ((isManagerCommercial || isADV) && (alert.id === "impaye")) return null

                            const disabledToggle =
                                (isDAF && alert.id === "impaye") ||
                                ((isADV || isManagerADV) && (alert.id === "retardee" || alert.id === "annulee"));
                            return (
                                <div key={alert.id} className="settings-page__category">
                                    <div className="settings-page__category-title">
                                        {alert.name}
                                    </div>
                                    <div className="settings-page__toggle-containers">
                                        <div className="settings-page__toggle-container">
                                            <ToggleButton
                                                toggled={alert.trigano}
                                                handlechange={() =>
                                                    toggleAlert(alert.id, "trigano", !alert.trigano)
                                                }
                                                disabled={disabledToggle}
                                            />
                                        </div>
                                        <div className="settings-page__toggle-container second">
                                            <ToggleButton
                                                toggled={alert.email}
                                                handlechange={() =>
                                                    toggleAlert(alert.id, "email", !alert.email)
                                                }
                                                disabled={disabledToggle}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
