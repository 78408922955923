import { AxiosResponse } from 'axios';
import { AuthenticatedClient, InfoClient, QuoteResponseType, QuotesParamsType } from '_api';
import { User } from '_types';

/*const quotes: QuoteResponseType[]= [
  {
    "id": "1ABC", // reférence du devis 
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2022",
    "clientName" : "TESTTESTTESTTEST",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 50000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "2ABC", 
    "status" : "Commandé à Trigano",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2021",
    "clientName" : "TEST2",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 40000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"


  },
  {
    "id": "3ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Commandé à Trigano",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2020",
    "clientName" : "TEST3",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 45000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "1ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Commandé à Trigano",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2022",
    "clientName" : "TEST",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 50000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "2ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis échu",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2021",
    "clientName" : "TEST2",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 40000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "3ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2020",
    "clientName" : "TEST3",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 45000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "1ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2022",
    "clientName" : "TEST",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 50000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "2ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Commandé à Trigano",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2021",
    "clientName" : "TEST2",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 40000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "3ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2020",
    "clientName" : "TEST3",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 45000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "1ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis échu",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2022",
    "clientName" : "TEST",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 50000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "2ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis échu",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2021",
    "clientName" : "TEST2",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 40000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "3ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis échu",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2020",
    "clientName" : "TEST3",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 45000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "1ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2022",
    "clientName" : "TEST",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 50000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  },
  {
    "id": "2ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2021",
    "clientName" : "TEST2",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 40000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"
  },
  {
    "id": "3ABC", // reférence du devis ? (popin sauvegarde du devis) + pour la suppression
    "status" : "Devis en cours",
    "clientDoc" : "", // pdf
    "quoteNumber" : "55632233",
    "year" : "2020",
    "clientName" : "TEST3",
    "brand": "Benimar",
    "model" : "Chausson Vans Road Line 2022 V594",
    "amount" : 45000,
    "creationDate" : "2023-05-02",
    "validityDate" : "2023/09/15",
    "infoClient"  : "",
    "clientPhone" : "0601020304",
    "dealerName" : "Caravane Cassegrain SA",
    "selectedPointOfSale" : "selectedPointOfSale"

  }
]
*/
export const getQuotes = ( params : QuotesParamsType): Promise<AxiosResponse<QuoteResponseType>> => {
  return AuthenticatedClient.post('/listquotes',params);
 /* return new Promise((resolve) => {
    const result = {
      data: quotes
    };
    resolve(result);
  });*/
};

export const getQuoteById = (id: string): Promise<AxiosResponse<QuoteResponseType[]>> => {
  return AuthenticatedClient.get('/quotedetailed/' + id);
};

export const updateQuote = (id: number, orno : string | null , client : InfoClient | null ,commentary : string | null,selectedPointOfSale : string |null ,user : User | null ): Promise<AxiosResponse<void>> => {
  const params = {
   infoClient: {
      clientId: client?.clientId ,
      firstName: client?.firstName,
      lastName: client?.lastName,
      clientPhone: client?.clientPhone,
      clientEmail: client?.clientEmail,
      dealerEmail: user?.username,
    },
    orderNumber: orno,
    commentary : commentary,
    selectedPointOfSale : selectedPointOfSale,
  };
  return AuthenticatedClient.post('/updateinfo', params);
};

export const updateQuotePointOfSale = ( orno : string | null ,commentary : string | null,selectedPointOfSale : string |null ): Promise<AxiosResponse<void>> => {
  const params = {
    orderNumber: orno,
    commentary : commentary,
    selectedPointOfSale : selectedPointOfSale,
  };
  return AuthenticatedClient.post('/updateinfo', params);
};
