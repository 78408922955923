import {
  BaseSyntheticEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { AccountStatementTable, PageTitle } from "_components";
import { useTranslation } from "react-i18next";
import {
  AccountStatementResponseType,
  BUResponse,
  TotalAccountStatementResponseType,
  getBUsByDealer,
  getComptesByDealerAndBU,
  getDealershipsById /*  DealershipResponseType, getDealerships */,
} from "_api";
import "./account-statement-page.scss";
import { useLoading } from "_hooks";
import { toast } from "react-toastify";
import { atomUser, atomUserRole } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";

type Dealer = {
  id: string;
  name: string;
  cuno: string;
};

export type Total = {
  debits: number;
  credits: number;
  balances: number;
};

const AccountStatementPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [statements, setStatements] = useState<AccountStatementResponseType[]>(
    []
  );
  const [totals, setTotals] = useState<Total>({
    credits: 0,
    debits: 0,
    balances: 0,
  });
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [dealerSelected, setDealerSelected] = useState<Dealer | null>(null);
  const [BUs, setBUs] = useState<BUResponse[]>([]);
  const [buSelected, setBUSelected] = useState<BUResponse | null>(null);
  const { setLoading } = useLoading();
  const userLogged = useRecoilValue(atomUser);
  const [noDataMessage, setNoDataMessage] = useState<boolean>(false);
  const [userRole] = useRecoilState(atomUserRole);

  const isAdmin = userRole?.name === "Admin-trigano";
  const isAgent = userRole?.name === "Agent";
  const isAgentLimite = userRole?.name === "Agent-Limite";
  const isUserTrigano = userRole?.name === "User-trigano";
  const isDealer = userRole?.name === "Dealer";
  const isManagerADV = userRole?.name === "Manager_ADV";
  const isDAF = userRole?.name === "DAF";

  const initData = () => {
    setLoading(true);
    if (userLogged && userLogged.username) {
      getDealershipsById(userLogged.username)
        .then((result) => {
          /*setDealers((result.data.map((_dealer) => {
          return {
            id: _dealer.id,
            name: _dealer.name,
            cuno: _dealer.CUNO
          }
        })))*/
          const dealers = result.data.map((dealer) => {
            return {
              label: dealer.name,
              id: dealer.id.toString(),
              name: dealer.name,
              cuno: dealer.CUNO,
              brands: dealer.brands.map((brand) => ({
                id: brand.id,
                name: brand.name,
              })),
            };
          });

          setDealers(dealers);
          if (result.data && result.data.length) {
            setDealerSelected({
              id: result.data[0].id,
              name: result.data[0].name,
              cuno: result.data[0].CUNO,
            });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 412:
                toast.error(
                  t("toast_error_retrieving_dealer_ids_linked_to_the_user")
                );
                break;
              case 413:
                toast.error(
                  t(
                    "toast_error_retrieving_dealer_information_linked_to_the_user"
                  )
                );
                break;
              case 414:
                toast.error(
                  t(
                    "toast_error_when_retrieving_brands_associated_with_dealers_on_the_pim"
                  )
                );
                break;
              default:
                toast.error(t("toast_get_data_error"));
            }
          } else {
            toast.error(t("toast_get_data_error"));
          }
        });
    }
  };

  const handleSelectChange = (e: BaseSyntheticEvent) => {
    const name = e.target.value;
    setDealerSelected(
      dealers.find((dealer) => {
        return dealer.name === name;
      }) || null
    );
  };

  const handleBUSelectChange = (e: BaseSyntheticEvent) => {
    const name = e.target.value;
    setBUSelected(
      BUs.find((_bu) => {
        return _bu.NAME === name;
      }) || null
    );
  };

  const calculateTotals = (
    statements: AccountStatementResponseType[]
  ): TotalAccountStatementResponseType => {
    if (!Array.isArray(statements)) {
      throw new Error("Input is not an array");
    }

    const creditTotal = statements.reduce(
      (acc, statement) =>
        acc + (statement?.Credit ? parseFloat(statement.Credit) : 0),
      0
    );

    const debitTotal = statements.reduce(
      (acc, statement) =>
        acc + (statement?.Debit ? parseFloat(statement.Debit) : 0),
      0
    );

    const balanceTotal = creditTotal - debitTotal;

    return {
      creditTotal,
      debitTotal: debitTotal,
      balanceTotal,
    };
  };
  useEffect(() => {
    setLoading(true);
    setStatements([]);
    if (dealerSelected && buSelected) {
      getComptesByDealerAndBU(dealerSelected.cuno, buSelected?.DIVI).then(
        (result) => {
          setStatements(result.data);
          const calculatedTotals = calculateTotals(result.data);
          setTotals({
            credits: calculatedTotals.creditTotal,
            debits: calculatedTotals.debitTotal,
            balances: calculatedTotals.balanceTotal,
          });
          setLoading(false);
          setNoDataMessage(false);
        },
        (error) => {
          console.log("error: ", error);
          setNoDataMessage(true);
          setLoading(false);
          if (error.response && error.response.status === 413) {
            toast.error(t("toast_get_data_error_pim_accounts"));
          }
        }
      );
    }
  }, [buSelected]);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    // if (userLogged && userLogged.username) {
    setLoading(true);
    if (dealerSelected && userLogged && userLogged.username) {
      getBUsByDealer(userLogged.username, dealerSelected.id).then(
        (result) => {
          setBUs(result.data);
          if (result.data && result.data.length) {
            setBUSelected(result.data[0]);
          }
          setNoDataMessage(false);
          setLoading(false);
        },
        (error) => {
          console.log("error: ", error);
          setNoDataMessage(true);
          setLoading(false);
        }
      );
    }
    /*
      setLoading(true);
      getAccountStatements(dealerSelected.cuno).then((result) => {
        console.log('result: ', result);
        setNoDataMessage(false);
        setStatements(result.data);
        let creditsTotal = 0;
        let debitsTotal = 0;
        if (result.data && result.data.length) {
          result.data.forEach((_result: AccountStatementResponseType) => {
            creditsTotal += Number(_result.Credit);
            debitsTotal += Number(_result.Debit);
          })
        }
        setTotals({
          credits: creditsTotal,
          debits: debitsTotal
        });
        setLoading(false);
      }, (error) => {
        console.log('error: ', error);

        if (error && error.response && error.response.status === 412) {
          setNoDataMessage(true);
        }
        else {
          toast.error(t('toast_get_data_error'));
          setLoading(false);
          setNoDataMessage(false);
        }
      });
    }
    */
  }, [dealerSelected]);

  return (
    <div className="account-statement">
      <PageTitle
        title={t("account_statement")}
        description={t("account_statement_description")}
      />

      <div className="account-statement__main-wrapper">
        <div className="account-statement__row">
          <div className="account-statement__actions-wrapper">
            <div className="account-statement__label">{t("table_dealers")}</div>
            <div className="account-statement__select-wrapper">
              <select
                className="account-statement__select"
                onChange={handleSelectChange}
              >
                {dealers.map((dealer, index) => (
                  <option
                    key={dealer.name}
                    value={dealer.name}
                    selected={index === 0}
                  >
                    {dealer.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="account-statement__actions-wrapper">
            <div className="account-statement__label">{t("bu")}</div>
            <div className="account-statement__select-wrapper">
              <select
                className="account-statement__select"
                onChange={handleBUSelectChange}
                value={buSelected ? buSelected.NAME : ""}
              >
                {BUs.map((bu, index) => (
                  <option key={bu.NAME} value={bu.NAME} selected={index === 0}>
                    {bu.NAME}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {(isAgent ||
          isAgentLimite ||
          isUserTrigano ||
          isAdmin ||
          isDealer ||
          isManagerADV ||
          isDAF) &&
        statements &&
        statements.length ? (
          <AccountStatementTable
            accountStatements={statements}
            totals={totals}
          />
        ) : null}

        {noDataMessage ? (
          <div className="no-data">{t("no_invoice")} </div>
        ) : null}
      </div>
    </div>
  );
};

export default AccountStatementPage;
