import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserResponseType } from "_api";
import './filterable-table.scss';

export type FilterableTableProps = {
  columns: Array<string>,
  renderRows: (data: UserResponseType[]) => ReactElement[],
  data: UserResponseType[],
  addEditColumn?: boolean;
  addDeleteColumn?: boolean;
  currentEditableId?: string;
}

type SortingParams = {
  sortingCategory: string;
  sortingDirection: 'ASC' | 'DESC';
}

const FilterableTable: FunctionComponent<FilterableTableProps> = (props) => {
  const [sortingParams, setSortingParams] = useState<SortingParams>({ sortingCategory: props.columns[0], sortingDirection: 'ASC' });
  const [filteredRows, setFilteredRows] = useState(props.renderRows(props.data));
  const { t } = useTranslation();

  const getFilteredData = (data: UserResponseType[], sortingCategory: string, sortingDirection: 'ASC' | 'DESC') => {
    let operator1: number, operator2: number;
    if (sortingDirection === "ASC") {
      operator1 = -1;
      operator2 = 1;
    } else {
      operator1 = 1;
      operator2 = -1;
    }
    
    return data.sort((a: UserResponseType, b: UserResponseType) => {
      if (sortingCategory) {
        // Specific case: role.
        if (sortingCategory === 'role') {
          const aRoleName = a[sortingCategory]?.name?.toLowerCase() || '';
          const bRoleName = b[sortingCategory]?.name?.toLowerCase() || '';
          return aRoleName < bRoleName ? operator1 : operator2;
        }
        // Specific case: dealership.
        else if (sortingCategory === 'dealers') {
          const aFirstDealerName = a[sortingCategory]?.[0]?.name?.toLowerCase() || '';
          const bFirstDealerName = b[sortingCategory]?.[0]?.name?.toLowerCase() || '';
      
          if (a[sortingCategory]?.length && a[sortingCategory]?.length === b[sortingCategory]?.length) {
            return aFirstDealerName < bFirstDealerName ? operator1 : operator2;
          } else {
            return a[sortingCategory]?.length < b[sortingCategory]?.length ? operator1 : operator2;
          }
        }
        // Case null. 
        else if (a[sortingCategory as keyof UserResponseType] === null) {
          return operator1;
        }
        // String.
        else if (typeof a[sortingCategory as keyof UserResponseType] == "string") {
          const aStringValue = a[sortingCategory as keyof UserResponseType]?.toLowerCase() || '';
          const bStringValue = b[sortingCategory as keyof UserResponseType]?.toLowerCase() || '';
          return aStringValue < bStringValue ? operator1 : operator2;
        }
        // Number.
        else {
          return a[sortingCategory as keyof UserResponseType] < b[sortingCategory as keyof UserResponseType] ? operator1 : operator2;
        }
      } else return -1;
    });
  }

  useEffect(() => {
    setFilteredRows(props.renderRows(getFilteredData(props.data, sortingParams.sortingCategory, sortingParams.sortingDirection)));
  }, [sortingParams, props.currentEditableId, props.data]);

  const isFilterActive = (column: string, direction: 'ASC' | 'DESC') => {
    return column === sortingParams.sortingCategory && direction === sortingParams.sortingDirection;
  }

  const toggleFilter = (column: string) => {
    if (isFilterActive(column, 'ASC')) {
      setSortingParams({ sortingCategory: column, sortingDirection: 'DESC' });
    }
    else {
      setSortingParams({ sortingCategory: column, sortingDirection: 'ASC' });
    }
  };

  return (
    <table className="filterable-table">
      <colgroup>
        {props.columns.map((column) => {
          return <col key={column} className="filterable-table__col" />
        })}
      </colgroup>
      <thead className="filterable-table__head">
        <tr>
          {props.columns.map((column) => {
            return <th key={column}>
              <button onClick={() => {toggleFilter(column)}} className="filterable-table__column-button">
                {t('table_' + column)}
                <span className={`filterable-table__direction-btn filterable-table__direction-btn--asc ${isFilterActive(column, 'ASC') ? ' filterable-table__direction-btn--active' : ''}`}></span>
                <span className={`filterable-table__direction-btn filterable-table__direction-btn--desc ${isFilterActive(column, 'DESC') ? ' filterable-table__direction-btn--active' : ''}`}></span>
              </button>
            </th>;
          })}
          {props.addEditColumn ? <th className="u-center">{t('edit')}</th> : null}
          {props.addDeleteColumn ? <th className="u-center">{t('delete')}</th> : null}
        </tr>
      </thead>
      <tbody className="filterable-table__body">
        {filteredRows}
      </tbody>
    </table>
  );
}

export default FilterableTable;
