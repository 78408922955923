import { FunctionComponent } from "react";
import './global-search-quote.scss';
import { SearchQuoteResponse } from "_api";
import { Link } from "react-router-dom";

export type GlobalSearchQuoteResultProps = {
  result: SearchQuoteResponse;
}

const GlobalSearchQuoteResult: FunctionComponent<GlobalSearchQuoteResultProps> = ({ result }) => {
  return (
    <Link className="global-search-quote" to={`/quotation?order=${result.ORNO}`}>
      <div className="global-search-quote__column">
      <div className={`status ${
        result.statusStk === 'Commande_a_trigano' ? 'green' :
        result.statusStk === 'devis_en_cours' ? 'orange' :
          result.statusStk === 'devis_echu' ? 'grey' :
           'red'
      }`}>
        </div>
      </div>
      <div className="global-search-quote__column">
        {result.ORNO}
      </div>
      <div className="global-search-quote__column">
        {result.year}
      </div>
      <div className="global-search-quote__column">
        {result.clientId}
      </div>
      <div className="global-search-quote__column">
        {result.brand}
      </div>
      <div className="global-search-quote__column">
        {result.model}
      </div>
      <div className="global-search-quote__column">
        {result.amount}
      </div>
      <div className="global-search-quote__column">
        {result.creationDate ? new Date(result.creationDate).toLocaleDateString() : ''}
      </div>
      <div className="global-search-quote__column">
        {result.point_of_sale_informations.address1 + " , " + result.point_of_sale_informations.postal_code}
      </div>
    </Link>
  );
}

export default GlobalSearchQuoteResult;

