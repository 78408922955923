import { VehicleResult } from "../_api";
import { TFunction } from "i18next";

export type TVehiculeConfigurability =
  | "not-configurable"
  | "partially-configurable"
  | "fully-configurable";
export type TVehicleQuantity = "none" | "low" | "medium" | "high";

const diffWeeks = (dt2: Date, dt1: Date) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7;
  return Math.round(diff);
};

function addDaysToDate(date: Date, days: number): Date {
  const result: Date = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getVehicleStatusWording(vehicle: VehicleResult, t: TFunction) {
  if (vehicle.availability == "disponible_ou_en_stock_concession")
    return t("vehicle_status_available");
  else if (
    vehicle.availability == "disponible_en_stock_usine_ou_sous_deux_semaines"
  )
    return t("vehicle_status_available_factory");
  else if (
    vehicle.availability == "commande_usine_ou_production_sous_x_semaines"
  )
    return t("vehicle_status_factory_ordered");
  else if (
    vehicle.availability ==
    "prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine"
  )
    return t("vehicle_status_in_production");
  else if (vehicle.availability === "a_configurer_ou_contactez_votre_adv") {
    return t("vehicle_status_configurable");
  } else {
    return vehicle.availability;
  }
}

export function getVehicleConfigurability(
  vehicle: VehicleResult
): TVehiculeConfigurability {
  if (vehicle.SCOM && Number(vehicle.SCOM)) {
    const SCOM = Number(vehicle.SCOM);
    if (SCOM < 30) {
      return "fully-configurable";
    } else if (SCOM === 30) {
      return "partially-configurable";
    }
  }
  return "not-configurable";
}

export function getVehicleDateOrStatus(
  vehicle: VehicleResult,
  showStock: boolean,
  t: TFunction
) {
  let weeks;
  let newDeadlineDate;
  const NB_WEEKS_MARGIN = 2;
  // 1. Stock concess / disponible
  if (
    vehicle.availability == "disponible_ou_en_stock_concession" &&
    showStock &&
    typeof vehicle.nb_stock === "number"
  ) {
    return vehicle.nb_stock + " " + t("vehicle_status_stock_nb_label");
  }

  // 2. Stock usine / disponible en stock usine
  else if (
    vehicle.availability == "disponible_en_stock_usine_ou_sous_deux_semaines"
  ) {
    if (vehicle.STAT === "STK") {
      if (parseInt(vehicle.STAS) === 2 || parseInt(vehicle.STAS) === 3) {
        newDeadlineDate = addDaysToDate(
          new Date(),
          parseInt(vehicle.dead_line_production_date)
        );
        weeks = Number(diffWeeks(new Date(newDeadlineDate), new Date()));
        return weeks > 0
          ? t("vehicle_status_period_prefix") +
              " " +
              weeks +
              " " +
              t("vehicle_status_period_suffix")
          : null;
      } else if (parseInt(vehicle.STAS) === 1) {
        newDeadlineDate = addDaysToDate(
          new Date(vehicle.FIDT),
          parseInt(vehicle.dead_line_due_date)
        );
        weeks = Number(diffWeeks(new Date(newDeadlineDate), new Date()));
        return weeks > 0
          ? t("vehicle_status_period_prefix") +
              " " +
              weeks +
              " " +
              t("vehicle_status_period_suffix")
          : null;
      }
    }
  }
  // 3. Commandé usine
  else if (
    vehicle.availability == "commande_usine_ou_production_sous_x_semaines" &&
    vehicle.production_date
  ) {
    weeks = Number(
      diffWeeks(new Date(vehicle.production_date), new Date()) + NB_WEEKS_MARGIN
    );
    return weeks > 0
      ? t("vehicle_status_period_prefix") +
          " " +
          weeks +
          " " +
          t("vehicle_status_period_suffix")
      : null;
  }
  // 4. Production usine ()
  else if (
    vehicle.availability ==
      "prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine" &&
    vehicle.production_date
  ) {
    newDeadlineDate = addDaysToDate(
      new Date(vehicle.production_date),
      parseInt(vehicle.dead_line_production_date)
    );
    weeks = Number(diffWeeks(new Date(newDeadlineDate), new Date()));
    return weeks > 0
      ? t("vehicle_status_period_prefix") +
          " " +
          weeks +
          " " +
          t("vehicle_status_period_suffix")
      : null;
  }

  // 5. A configurer // pas de date voulue // inconnu
  else return null;
}

export function getVehicleButtonWording(vehicle: VehicleResult, t: TFunction) {
  if (vehicle.availability == "disponible_ou_en_stock_concession")
    return t("vehicle_button_wording_available");
  else if (
    vehicle.availability == "disponible_en_stock_usine_ou_sous_deux_semaines"
  )
    return t("vehicle_button_wording_available_factory");
  else if (
    vehicle.availability == "commande_usine_ou_production_sous_x_semaines"
  )
    return t("vehicle_button_wording_factory_ordered");
  else if (
    vehicle.availability ==
    "prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine"
  )
    return t("vehicle_button_wording_next_availability");
  else if (vehicle.availability == "a_configurer" && vehicle.customizable) {
    return t("vehicle_button_wording_other");
  } else {
    return t("vehicle_button_wording_available");
  }
}
