import { FunctionComponent } from "react";
import './local-loader.scss';

export const LocalLoader: FunctionComponent = () => {

  return (
    <div className="local-loader">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

export default LocalLoader;