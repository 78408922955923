import { AxiosResponse } from "axios";
import {
  OrderResponseType,
  AuthenticatedClient,
  InfoClient,
  OrderParamsType,
} from "_api";

export const getOrders = (
  params: OrderParamsType
): Promise<AxiosResponse<{ content: OrderResponseType[] }>> => {
  return AuthenticatedClient.post("/orders", params);
};

export const getOrdersOne = (
  params: OrderParamsType,
  orderId: string
): Promise<AxiosResponse<{ content: OrderResponseType[] }>> => {
  const endpoint = `/orders?order=${orderId}`;
  return AuthenticatedClient.post(endpoint, params);
};

export const exportOrdersCsvData = (params: OrderParamsType) => {
  return AuthenticatedClient.post("/commande/csv", params);
};

export const exportOrdersExcelData = (params: OrderParamsType) => {
  return AuthenticatedClient.post("/commande/excel", params, {
    responseType: "arraybuffer",
  });
};

export const updateOrder = (
  orderNumber: string | null,
  client: InfoClient | null
): Promise<AxiosResponse<void>> => {
  const params = {
    infoClient: {
      clientId: client?.clientId,
      firstName: client?.firstName,
      lastName: client?.lastName,
      clientPhone: client?.clientPhone,
      clientEmail: client?.clientEmail,
      dealerEmail: client?.dealerEmail,
    },
    orderNumber: orderNumber,
  };
  return AuthenticatedClient.post("/updateinfo", params);
};
export const updateOrderCommentary = (
  ORNO: string | undefined,
  commentary: string | null
): Promise<AxiosResponse<void>> => {
  const params = {
    ORNO: ORNO,
    CUOR: commentary,
  };
  return AuthenticatedClient.post("/updatecommentaryorder", params);
};

export const validateQuote = (
  CUNO: string | null,
  ORNO: string | null
): Promise<AxiosResponse<void>> => {
  const params = {
    CUNO: CUNO,
    ORNO: ORNO
  };
  return AuthenticatedClient.post("/orders/validateorder", params);
};
