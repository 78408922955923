import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import "./legal-infos-page.scss";
import { PageTitle } from "../../_components";

const LegalInfosPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="legal-infos">
      <PageTitle title={t("legal_infos_title")} />
      <div className="legal-infos__content">
        <h2>{t("legal_infos_site_company")}</h2>
        <div className="legal-infos__box">
          <strong>{t("legal_infos_site_company_name")}</strong>
          <p>{t("legal_infos_site_company_description")}</p>
        </div>
        <h2>{t("legal_infos_site_author")}</h2>
        <div className="legal-infos__box">
          <strong>{t("legal_infos_site_author_name")}</strong>
          <p>{t("legal_infos_site_author_description")}</p>
        </div>
        <h2>{t("legal_infos_site_publishing")}</h2>
        <div className="legal-infos__box">
          <span>{t("legal_infos_site_publishing_name")}</span>
        </div>
        <h2>{t("legal_infos_site_hosting")}</h2>
        <div className="legal-infos__box">
          <strong>{t("legal_infos_site_hosting_company_name")}</strong>
          <p>{t("legal_infos_site_hosting_company_description")}</p>
          <strong>{t("legal_infos_site_hosting_photos")}</strong>
          <p>{t("legal_infos_site_hosting_photos_description")}</p>
          <strong>{t("legal_infos_site_hosting_copyright")}</strong>
          <p>{t("legal_infos_site_hosting_copyright_description")}</p>
          <strong>{t("legal_infos_site_hosting_distinctive_signs")}</strong>
          <p>{t("legal_infos_site_hosting_distinctive_signs_description")}</p>
          <strong>{t("legal_infos_site_hosting_liability")}</strong>
          <p>{t("legal_infos_site_hosting_liability_description")}</p>
        </div>
      </div>
    </div>
  );
};

export default LegalInfosPage;
