import { AxiosResponse } from "axios";
import {
  /* AuthenticatedClient, */ AuthenticatedClient,
  COCRequestResponseType,
} from "_api";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getCOCRequests = (
  CUNO: string[]
): Promise<AxiosResponse<COCRequestResponseType[]>> => {
  return AuthenticatedClient.post("/coc", { CUNO: CUNO });
};

export const createCOCRequests = (
  ORNO: string,
  bano: string,
  clientName: string,
  comment: string
): Promise<AxiosResponse<string>> => {
  return AuthenticatedClient.post("/create_coc", {
    ORNO: ORNO,
    BANO: bano,
    client_name: clientName,
    comment_coc: comment,
  });
};
