import { ReactElement } from "react";
import "./planning-calendar-card.scss";
import {
  TVehicleQuantity,
  TVehiculeConfigurability,
} from "../../../../_utils/vehicle";

export type TPlanningCalendarCardStyle = {
  configurabilities?: TVehiculeConfigurability[];
  quantity?: TVehicleQuantity;
  isSelected?: boolean;
  isDisabled?: boolean;
};

type IPlanningCalendarCardProps = {
  style: TPlanningCalendarCardStyle;
};

export function PlanningCalendarCard(
  props: IPlanningCalendarCardProps
): ReactElement {
  const { style } = props;

  const AvailabilityColors = (): ReactElement => {
    // Check 3 possible types of configurability and assign 1 or 2 (split) colors to the cards,
    // if only 1 type is present both sides are the same color
    // if 2 types are present that are assigned to left-side and right-side
    // if all 3 types are present only "fully" and "partially" are kept
    let colors: TVehiculeConfigurability[] = [];
    if (style.configurabilities && style.configurabilities?.length > 0) {
      colors = style.configurabilities;
      if (colors.length > 2) {
        colors = ["fully-configurable", "partially-configurable"];
      }
    }
    const colorA = colors[0] !== undefined ? colors[0] : "empty";
    const colorB =
      colors[1] !== undefined
        ? colors[1]
        : colors[0] !== undefined
        ? colors[0]
        : "empty";
    return (
      <>
        <div
          className={`planning-calendar-card__availability planning-calendar-card__availability--${colorA}`}
        />
        <div
          className={`planning-calendar-card__availability planning-calendar-card__availability--${colorB}`}
        />
      </>
    );
  };

  const QuantityCards = (): ReactElement => {
    // Check 3 possible levels of quantity and display 1,2 or 3 "cards" based on the quantity (low, medium, high),
    // if quantity is "none" then a cross is displayed instead
    let cards;
    if (style.quantity !== "none") {
      cards = Array.from(
        {
          length:
            style.quantity === "high" ? 3 : style.quantity === "medium" ? 2 : 1,
        },
        (_, i) => (
          <div key={i} className="planning-calendar-card__box">
            <AvailabilityColors />
          </div>
        )
      );
    } else {
      cards = <div className="planning-calendar-card__cross" />;
    }
    return <div className="planning-calendar-card__container">{cards}</div>;
  };

  return (
    <div
      className={`planning-calendar-card ${
        style.isDisabled ? "planning-calendar-card--disabled" : ""
      } ${style.isSelected ? "planning-calendar-card--selected" : ""}`}
    >
      {style.isDisabled ? (
        <div className="planning-calendar-card__container planning-calendar-card__container--disabled" />
      ) : (
        <QuantityCards />
      )}
    </div>
  );
}
