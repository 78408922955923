import { FunctionComponent } from "react";
import { ContactResponseType } from "_api";
import './card-contacts.scss';

type CardContactProps = {
    contact: ContactResponseType
}

export const CardContact: FunctionComponent<CardContactProps> = (props) => {
    const { contact } = props

    return (<div className="card">
        <img className="card__logo" src={contact.img} alt="" />
        <div className="agent">
            <span className="agent__name">
                {contact.firstname}
            </span>
            <span className="agent__last-name">
                {contact.lastname}
            </span>
        </div>
        <span className="agent__phone">
            {contact.phone}
        </span>
        <a className="agent__email" href={`mailto:${contact.email}?subject=""&body=""`}>
            {contact.email}
        </a>
    </div>);
}

export default CardContact;