import { forwardRef, useState, Ref, useImperativeHandle, memo } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "_components";
import { formatPrice } from "_utils";
import "./price-selector.scss";

export type PriceSelectorProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  min: number;
  max: number;
}

export type PriceSelectorRef = {
  reset(): void;
}

const PriceSelector = (props: PriceSelectorProps, ref: Ref<PriceSelectorRef | undefined>) => {
  const { t } = useTranslation();
  const [min, setMin] = useState<number>(props.min);
  const [max, setMax] = useState<number>(props.max);

  useImperativeHandle(ref, () => ({
    reset() {
      setMin(props.min);
      setMax(props.max);
    }
  }));

  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({ field }) => {

        const reinitFilters = (e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          setMin(props.min);
          setMax(props.max);
          field.onChange({
            min: null,
            max: null
          })
        }

        const onValidate = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
          e.preventDefault();
          const newMin = min <= max && min >= props.min && min <= props.max ? min : props.min;
          const newMax = max >= min && max <= props.max && max >= props.min ? max : props.max;
          setMin(newMin);
          setMax(newMax);
          field.onChange({
            min: newMin,
            max: newMax
          })
        };

        return (
          <div className="price-selector">

            <div className="price-selector__bounds-inputs-container">

              <div className="price-selector__bound-input">
                <label htmlFor="min" className="price-selector__bound-label" >
                  {t('min_price')}
                  <span className="price-selector__bound-value">({formatPrice(props.min)})</span>
                </label>
                <input
                  id="min"
                  type="number"
                  min="0"
                  value={min}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setMin(Number(newValue));
                  }}
                  onKeyDown={(e) => {
                    <Button type={ButtonType.TERTIARY} text="Réinitialiser les prix" onClick={onValidate} />
                    if (e.key && e.key === 'Enter') {
                      onValidate(e);
                    }
                  }
                  }
                />
              </div>

              <div className="price-selector__bound-input">
                <label htmlFor="min" className="price-selector__bound-label">
                  {t('max_price')}
                  <span className="price-selector__bound-value">({formatPrice(props.max)})</span>
                </label>
                <input
                  id="max"
                  type="number"
                  min="0"
                  value={max}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setMax(Number(newValue));
                  }}
                  onKeyDown={(e) => {
                    if (e.key && e.key === 'Enter') {
                      onValidate(e);
                    }
                  }
                  }
                />
              </div>

            </div>

            <div className="price-selector__button-container">
              <Button type={ButtonType.DARKSMALL} text={t('validate_price')} onClick={onValidate} />
              <Button type={ButtonType.TERTIARY} text={t('reinit_price')} onClick={reinitFilters} disabled={!(typeof field.value.min === 'number' && typeof field.value.max === 'number')} />
            </div>

          </div>
        );
      }}
    />
  );
}

export default memo(forwardRef(PriceSelector));
