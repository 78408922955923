import { FunctionComponent, useRef } from "react";
import { useOutsideClick } from "_hooks";
import './full-screen-pic.scss';

export type FullScreenPicProps = {
  img: string | null;
  onClose: () => void;
}

export const FullScreenPic: FunctionComponent<FullScreenPicProps> = (props) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => { props.onClose(); });

  return (
    <div className={props.img ? 'full-screen-pic full-screen-pic--open' : 'full-screen-pic full-screen-pic--hidden'}>
        {props.img ? (
        <div className="full-screen-pic__page-wrapper" ref={wrapperRef}>
            <div className="full-screen-pic__img-wrapper">
              <button className="full-screen-pic__close-button" onClick={props.onClose}></button>
              <img src={props.img} alt="" className="full-screen-pic__img" />
            </div>
          </div>
        ) : null}
     </div>
  );
}

export default FullScreenPic;
