import { FunctionComponent, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { VehicleResult } from "_api";
import { formatPrice, getVehicleDateOrStatus, getVehicleStatusWording, getVehicleButtonWording, getConfiguratorLink, isConfiguratorLinkPresents } from "_utils";
import './vignette.scss';
import { atomDealers, atomUser } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";

type VignetteCardProps = {
    vehicle: VehicleResult
    onSetFullScreenPic?: (picture: string) => void;
    config_group?: VehicleResult[];
    onSetModal?: () => void;
    isPlanningResult: boolean,
}

// function log the current vehicle

const VignetteCard: FunctionComponent<VignetteCardProps> = (props) => {
    const { t } = useTranslation();
    const [dealers] = useRecoilState(atomDealers);
    const vehicle = props.vehicle;
    const userLogged = useRecoilValue(atomUser);

    const getStatusClassName = (vehicle: VehicleResult) => {
        if (vehicle.availability == 'disponible_ou_en_stock_concession')
            return 'result-card__header--available';
        else if (vehicle.availability == 'disponible_en_stock_usine_ou_sous_deux_semaines')
            return 'result-card__header--available';
        else if (vehicle.availability == 'commande_usine_ou_production_sous_x_semaines')
            return 'result-card__header--ordered';
        else if (vehicle.availability == 'prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine')
            return 'result-card__header--production';
        else
            return 'result-card__header--configurable';
    }

    const openConfigurator = useCallback((vehicle: VehicleResult) => {
        
        const link = getConfiguratorLink(vehicle, userLogged?.country ,dealers, userLogged ? userLogged.username : null);
        window.open(link);
    }, []);

    const getPriceByCountry = (vehicle : any, country : any) => {
        switch (country) {
          case 'FR':
            return formatPrice(vehicle.price_fr);
          case 'DE':
            return formatPrice(vehicle.price_de);
          case 'ES':
            return formatPrice(vehicle.price_es);
          case 'IT':
            return formatPrice(vehicle.price_it);
          case 'EN':
            return formatPrice(vehicle.price_en);
          default:
            return formatPrice(vehicle.price);
        }
      };

    return (

        <div className="result-card-wrapper">

            <div className="result-card">

                {typeof vehicle.nb_stock === 'number' && vehicle.availability === 'disponible_ou_en_stock_concession' ? (
                    <div className="result-card__stock-nb-container">
                        <div className="result-card__stock-nb">{vehicle.nb_stock}</div>
                        <div className="result-card__stock-status">{t('search_result_stock_label')}</div>
                    </div>
                ) : null}

                <div className={`result-card__header ${getStatusClassName(vehicle)}`}>
                    <div className="result-card__status">{getVehicleStatusWording(vehicle, t)}</div>
                    <div className="result-card__date">{getVehicleDateOrStatus(vehicle, false, t)}</div>
                </div>

                <div className="result-card__background-img-container" onClick={() => props.onSetFullScreenPic ? props.onSetFullScreenPic(vehicle.image_implantationUrl) : null}>
                    <div className="result-card__background-img" style={{ backgroundImage: `url(${vehicle.image_implantationUrl}` }}></div>
                </div>

                <div className="result-card__main-content">

                    <div className="result-card__brand">{vehicle.brand_name}</div>

                    <div className="result-card__config-name">{vehicle.config_name}</div>

                    <div className="result-card__model">{vehicle.model}</div>
                    <div>{'lot n° '+ vehicle.OPDS}</div>  {/* @TODO : A enlever une fois les tests faits */}

                </div>

                <div className="result-card__footer">

                    <div className="result-card__various-infos-container">
                        <div className="result-card__item-info">
                            <div className="result-card__item-icon result-card__item-icon--seats"></div>
                            <div className="result-card__item-nb">{vehicle.seats_number}</div>
                        </div>

                        <div className="result-card__item-info">
                            <div className="result-card__item-icon result-card__item-icon--beds"></div>
                            <div className="result-card__item-nb">{vehicle.beds_number}</div>
                        </div>

                        <div className="result-card__item-info">
                            <div className="result-card__item-icon result-card__item-icon--length"></div>
                            <div className="result-card__item-nb">{Math.round(vehicle.length * 100) / 100}m</div>
                        </div>
                    </div>

                    <div className="result-card__price-container">
                        <div className="result-card__price-label">à partir de</div>
                        <div className="result-card__price">        
                        {userLogged ? getPriceByCountry(vehicle, userLogged.country) : formatPrice(vehicle.price)}
                        </div>
                    </div>
                </div>

                <div className="result-card__btn-container">
                    {isConfiguratorLinkPresents(vehicle) ? (
                        <>
                            {props.isPlanningResult ? (
                                <button className="result-card__btn" onClick={() => openConfigurator(vehicle)}>{getVehicleButtonWording(vehicle, t)}</button>
                            ) : <button className="result-card__btn" onClick={() => openConfigurator(vehicle)}>{getVehicleButtonWording(vehicle, t)} </button>}
                        </>
                    ):null}

                </div>

            </div>

        </div>);
}

export default memo(VignetteCard);