import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import "./request-coc-modal.scss";
import { useLoading } from "_hooks";
import { TextArea, TextInput } from "_components/form";

type RequestCOCModalProps = {
  onSubmit?: () => void;
  onClose: () => void;
  onRequestCOC: (
    orno: string,
    bano: string,
    clientName: string,
    comment: string
  ) => void;
  numberRequest: string;
  orno: string;
  bano: string;
  existingClientName: string;
};

const RequestCOCModal: FunctionComponent<RequestCOCModalProps> = ({
  onClose,
  numberRequest,
  orno,
  onRequestCOC,
  bano,
  existingClientName,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();

  const [clientName, setClientName] = useState(existingClientName);
  const [comment, setComment] = useState("");

  const handleSubmit = (event: { preventDefault: () => void }) => {
    if (clientName.length > 0) {
      event.preventDefault();
      setLoading(true);
      onRequestCOC(orno, bano, clientName, comment);
      onClose();
    }
  };

  const handleClientNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setClientName(event.currentTarget.value);
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComment(event.currentTarget.value);
  };

  // WIP
  // get specific field on table, order/filter lines from this field (order or filter? not sure)

  return (
    <div className="modal-request-coc-order">
      <div className="modal-request-coc-order__title">
        {t("request_coc_order") + " " + numberRequest}{" "}
      </div>
      <form className="modal-request-coc-order __form">
        <label
          className="o-form-group__label"
          htmlFor="request-coc-order-clientName"
        >
          {t("client_name_coc_modal")} *
        </label>
        <TextInput
          id="request-coc-order-clientName"
          type="text"
          name="request-coc-order-clientName"
          value={clientName}
          required
          maxLength={30}
          onChange={handleClientNameChange}
        />
        <TextArea
          name="request-coc-order-comment"
          rows={5}
          maxLength={40}
          placeholder={t("comment_coc")}
          id={""}
          label={""}
          onChange={handleCommentChange}
        ></TextArea>
        <div className="btn-container">
          <button
            className="save-btn"
            onClick={handleSubmit}
            disabled={clientName.length <= 0}
          >
            {t("share_coc_btn")}
          </button>
          <button className="cancel-btn" onClick={onClose}>
            {t("cancel_btn")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RequestCOCModal;
