import { ReactElement, useMemo } from "react";
import {
  getMonths,
  IBoxMonth,
  IMonth,
  IMonthAvailability,
  IPeriod,
} from "../../../../_utils/planning-calendar";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import "./planning-calendar-months.scss";
import {
  PlanningCalendarCard,
  TPlanningCalendarCardStyle,
} from "../PlanningCalendarCard/PlanningCalendarCard";
import { PlanningCalendarAvailabilities } from "../../../../_api";
import {
  TVehicleQuantity,
  TVehiculeConfigurability,
} from "../../../../_utils/vehicle";

type IPlanningCalendarMonthsProps = {
  currentPeriod: IPeriod;
  selectedBoxMonth?: IBoxMonth;
  monthsAvailabilities: PlanningCalendarAvailabilities;
  onMonthClick: (year: number, monthNumber: number) => void;
};

export function PlanningCalendarMonths(
  props: IPlanningCalendarMonthsProps
): ReactElement {
  const { t } = useTranslation();
  const {
    currentPeriod,
    selectedBoxMonth,
    monthsAvailabilities,
    onMonthClick,
  } = props;

  const currentDate = new Date();

  const selectedMonths: IMonthAvailability[] = useMemo(() => {
    return getMonths(currentPeriod, t).map((month) => {
      const monthDateValue = `${month.year}-${month.monthNumber}`;

      // Check count of production_month matching this month
      const productionCount =
        monthsAvailabilities.production_month.find(
          (m) => m.value === monthDateValue
        )?.count ?? 0;

      // Check count of configurable_months_none matching this month
      const configurableNoneMonths =
        monthsAvailabilities.configurable_months_none.find(
          (m) => m.value === monthDateValue
        );
      const isConfigurableNone: boolean =
        (configurableNoneMonths?.count !== undefined &&
          configurableNoneMonths.count > 0) ??
        false;

      // Check count of configurable_months_partial matching this month
      const configurablePartialMonths =
        monthsAvailabilities.configurable_months_partial.find(
          (m) => m.value === monthDateValue
        );
      const isConfigurablePartial: boolean =
        (configurablePartialMonths?.count !== undefined &&
          configurablePartialMonths.count > 0) ??
        false;

      // Check count of configurable_months_full matching this month
      const configurableFullMonths =
        monthsAvailabilities.configurable_months_full.find(
          (m) => m.value === monthDateValue
        );
      const isConfigurableFull: boolean =
        (configurableFullMonths?.count !== undefined &&
          configurableFullMonths.count > 0) ??
        false;

      return {
        ...month,
        nbVehicle: productionCount,
        isConfigurableNone: isConfigurableNone,
        isConfigurablePartial: isConfigurablePartial,
        isConfigurableFull: isConfigurableFull,
      };
    });
  }, [currentPeriod]);

  function isPastMonth(month: IMonth): boolean {
    const currentMonth = currentDate.getMonth() + 1;
    return (
      month.year < currentDate.getFullYear() ||
      (month.year === currentDate.getFullYear() &&
        currentMonth > parseInt(month.monthNumber))
    );
  }

  function getMonthClassname(month: IMonth): string {
    return `planning-calendar-months__month ${
      isPastMonth(month)
        ? "planning-calendar-months__month--inactive"
        : "planning-calendar-months__month--clickable"
    }`;
  }

  const isSelectedBoxMonth = (month: IMonth): boolean => {
    return isEqual(
      {
        monthNumber: month.monthNumber,
        year: month.year,
      },
      selectedBoxMonth
    );
  };

  function getCardStyle(month: IMonthAvailability): TPlanningCalendarCardStyle {
    const configurabilities: TVehiculeConfigurability[] = [];
    if (month.isConfigurableFull) {
      configurabilities.push("fully-configurable");
    }
    if (month.isConfigurablePartial) {
      configurabilities.push("partially-configurable");
    }
    if (month.isConfigurableNone) {
      configurabilities.push("not-configurable");
    }

    let quantity: TVehicleQuantity = "none";
    if (month.nbVehicle > 15) {
      quantity = "high";
    } else if (month.nbVehicle >= 5) {
      quantity = "medium";
    } else if (month.nbVehicle > 0) {
      quantity = "low";
    }

    return {
      configurabilities,
      quantity,
      isDisabled: isPastMonth(month),
      isSelected: isSelectedBoxMonth(month),
    };
  }

  function handleMonthClick(month: IMonth): void {
    if (!isPastMonth(month)) {
      onMonthClick(month.year, parseInt(month.monthNumber));
    }
  }

  return (
    <div className="planning-calendar-months">
      {selectedMonths.map((month) => (
        <div
          className={getMonthClassname(month)}
          key={month.monthNumber}
          onClick={() => handleMonthClick(month)}
        >
          <div
            className={`planning-calendar-months__month-label ${
              isSelectedBoxMonth(month)
                ? "planning-calendar-months__month-label--selected"
                : ""
            }`}
          >
            {month.label}
          </div>
          <PlanningCalendarCard style={getCardStyle(month)} />
        </div>
      ))}
    </div>
  );
}
