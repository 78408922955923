import {
  BaseSyntheticEvent,
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  AlertResponseType,
  getAlerts,
  markAlertsAsDelete,
  markAlertsAsRead,
} from "_api";
import { atomAlertsCount, atomUser } from "_atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLoading } from "_hooks";
import { toast } from "react-toastify";
import "./alerts-page.scss";

const AlertsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState<string[]>([]);
  const [alerts, setAlerts] = useState<AlertResponseType[]>([]);
  const [filteredAlerts, setFilteredAlerts] = useState(alerts);
  const userLogged = useRecoilValue(atomUser);
  const [currentCategory, setCurrentCategory] = useState<string>("all");
  const { setLoading } = useLoading();
  const setAlertsCount = useSetRecoilState(atomAlertsCount);
  const { i18n } = useTranslation();

  const loadAlerts = () => {
    if (userLogged && userLogged.id) {
      setLoading(true);
      getAlerts(userLogged.id, i18n.language).then(
        (result) => {
          const filteredAlerts = result.data.filter(
            (alert) => alert.onTriganoPro === "true"
          );

          const sortedAlerts = filteredAlerts.sort((a: AlertResponseType, b: AlertResponseType) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
          });

          const filteredReadAlerts = filteredAlerts.filter(
            (alert) => alert.read === "false"
          );

          const countAlerts = filteredReadAlerts.length;
          setAlertsCount(countAlerts);
          setAlerts(sortedAlerts);
          setFilteredAlerts(sortedAlerts);
          setLoading(false);
        },
        (error) => {
          console.log("error: ", error);
          toast.error(
            "Une erreur est survenue lors de la récupération des données"
          );
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    loadAlerts();
  }, [setAlertsCount]);

  const handleCheckAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setChecked(alerts.map((alert) => alert.id));
    } else {
      setChecked([]);
    }
  };

  const handleAlertChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setChecked([...checked, id]);
      console.log("handleAlertChange", [...checked, id]);
    } else {
      setChecked(checked.filter((item) => item !== id));
    }
  };

  // Categories filter
  const filterCategoryAlerts = (category: string) => {
    if (category === "all") return alerts;
    else {
      const filteredAlerts = alerts.filter((type) => type.level === category);
      return filteredAlerts;
    }
  };

  useEffect(() => {
    setFilteredAlerts(filteredAlerts);
  }, [filteredAlerts]);

  const handleCategoryClick = (e: BaseSyntheticEvent) => {
    const typeAlert = e.target.value;
    typeAlert !== "all"
      ? setFilteredAlerts(filterCategoryAlerts(typeAlert))
      : setFilteredAlerts(alerts);
    setCurrentCategory(typeAlert);
  };

  // Delete all selected rows
  const handleRemove = () => {
    handleAlertChange;
    const toDelete: Array<string> = [];

    // if I have selected rows
    if (checked && checked.length > 0) {
      checked.map((alertChecked) => {
        // if my row exists and not empty
        if (alertChecked && alertChecked !== "") {
          toDelete.push(alertChecked);
        }
      });
    }

    // if I'm logged and there is rows to delete
    if (userLogged && toDelete.length > 0) {
      setLoading(true);
      markAlertsAsDelete(userLogged.id, toDelete).then(
        () => {
          setLoading(false);
          loadAlerts();
        },
        (error) => {
          console.log("error: ", error);
          setLoading(false);
          toast.error(
            "Une erreur est survenue lors de la modification des données"
          );
          loadAlerts();
        }
      );
    }
  };

  // Mark as read all selected rows
  const handleMarkedAsRead = () => {
    const toMarkedAsRead: Array<string> = [];

    // if I have selected rows
    if (checked && checked.length > 0) {
      checked.map((alertChecked) => {
        // if my row exists and not empty
        if (alertChecked && alertChecked !== "")
          toMarkedAsRead.push(alertChecked);
      });
    }

    if (userLogged && toMarkedAsRead.length) {
      setLoading(true);
      markAlertsAsRead(userLogged.id, toMarkedAsRead).then(
        () => {
          setLoading(false);
          loadAlerts();
        },
        (error) => {
          console.log("error: ", error);
          setLoading(false);
          loadAlerts();
          toast.error(
            "Une erreur est survenue lors de la modification des données"
          );
        }
      );
    }
  };

  return (
    <div className="alerts-page">
      <div className="alerts-page__header">
        <input
          type="checkbox"
          className="alerts-page__checkbox"
          checked={checked.length === alerts.length}
          onChange={handleCheckAllChange}
        />
        <button
          className={`alerts-page__category ${
            currentCategory === "all" ? "alerts-page__category--selected" : ""
          }`}
          value="all"
          onClick={handleCategoryClick}
        >
          {t("all")}
          <span> ({alerts.length})</span>
        </button>
        <button
          className={`alerts-page__category ${
            currentCategory === "urgent"
              ? "alerts-page__category--selected"
              : ""
          }`}
          value="urgent"
          onClick={handleCategoryClick}
        >
          <span className="dot --warning"></span>
          {t("urgents")}
        </button>
        <button
          className={`alerts-page__category ${
            currentCategory === "notification"
              ? "alerts-page__category--selected"
              : ""
          }`}
          value="notification"
          onClick={handleCategoryClick}
        >
          <span className="dot --info"></span>
          {t("notifications")}
        </button>
        <button
          className={`alerts-page__category ${
            currentCategory === "alerte"
              ? "alerts-page__category--selected"
              : ""
          }`}
          value="alerte"
          onClick={handleCategoryClick}
        >
          <span className="dot --my-alerts"></span>
          {t("my_alerts")}
        </button>
        <button
          className="alerts-page__action read"
          onClick={handleMarkedAsRead}
        >
          {t("alerts_page_mark_as_read")}
        </button>
        <button className="alerts-page__action delete" onClick={handleRemove}>
          {t("alerts_page_delete")}
        </button>
      </div>
      <div className="alerts-page__body">
        {filteredAlerts.map((alert) => (
          <div
            className={`alerts-page__line ` + `${alert.read}`}
            key={alert.id}
          >
            <div className={`alerts-page__line-category ${alert.level}`}></div>
            <input
              type="checkbox"
              className="alerts-page__checkbox"
              id={alert.id}
              checked={checked.includes(alert.id)}
              onChange={(e) => handleAlertChange(e, alert.id)}
            />
            <div className="alerts-page__line-message">
              {alert.category === "mes alertes" ? (
                <div className="alerts-page__line-text">
                  {alert.messageSite}
                  <span> "{alert.model}"</span>
                </div>
              ) : (
                <div className="alerts-page__line-text">
                  {alert.messageSite}
                  <span> {alert.orderNumber}</span>
                  <span className="alerts-page__line-text--color">
                    {" "}
                    {alert.orderStatus}{" "}
                  </span>
                </div>
              )}
              <div className="alerts-page__line-info">
                <span className="alerts-page__line-info--date">
                  {alert.date ? new Date(alert.date).toLocaleDateString() : ""}
                </span>
                {alert.read === "false" ? (
                  <span className="alerts-page__line-info--unread">
                    {" "}
                    {t("unread")}{" "}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AlertsPage;
