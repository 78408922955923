import { FunctionComponent } from "react";
import { ButtonType } from './ButtonType';
import './button.scss';

export type ButtonProps = {
  type: ButtonType;
  text?: string;
  chevronRight?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  alertsNb?: number;
  tooltip?: string; // Nouvelle propriété pour l'infobulle

}

const getClass = (buttonType: ButtonType) => {
  switch (buttonType) {
    case ButtonType.PRIMARY:
      return 'button--primary';
    case ButtonType.SECONDARY:
      return 'button--secondary';
    case ButtonType.TERTIARY:
      return 'button--tertiary';
    case ButtonType.ROUNDED:
      return 'button--rounded';
    case ButtonType.ALERT:
        return 'button--alert';
    case ButtonType.LIGHTSMALL:
        return 'button--lightsmall';
    case ButtonType.DARKSMALL:
      return 'button--darksmall';
    case ButtonType.PRIMARYSMALL:
      return 'button--primary-small';
    case ButtonType.CANCEL:
        return 'button--cancel';
    case ButtonType.SAVE:
        return 'button--save';
    case ButtonType.CANCELINLINE:
        return 'button--cancel-inline';
    case ButtonType.EDIT:
        return 'button--edit';
    case ButtonType.DELETE:
      return 'button--delete';
        default:
      return '';
  }
}

const Button: FunctionComponent<ButtonProps> = (props) => {

  const isDisabled = !!props.disabled;

  return (
    <button className={`button
            ${getClass(props.type)}
            ${props.chevronRight ? ' button--chevron-right' : ''}`}
      onClick={props.onClick}
      disabled={!!props.disabled}
      title={isDisabled ? props.tooltip : undefined} 
      >
      {props.text}

      {props.type === ButtonType.ALERT && props.alertsNb ? (
        <span className="button__alerts-number">{props.alertsNb}</span>
      ) : null}
    
    </button>
  );
}

export default Button;