import { FunctionComponent, useState } from "react";
import {   TextInput } from "_components";
import { useTranslation } from "react-i18next";
import './point-of-sale-modal.scss';

type PointOfSaleProps = {
    name: string;
    pointOfSale : string | null;
    onSubmit?: () => void;
    onClose: () => void;
    onSelectedOptionChange: (pointOfSale: string) => void;
};

export const PointOfSaleModal: FunctionComponent<PointOfSaleProps> = ({ onClose ,onSelectedOptionChange ,pointOfSale }) => {


    const [pointOfSaleSelectedOption, setPointOfSaleSelectedOption] = useState({
        pointOfSale : pointOfSale
    });

    const { t } = useTranslation();


     const handleChange = (key: string, value: string) => {
        setPointOfSaleSelectedOption((prev) => {
      return {
        ...prev,
        [key]: value
      }
    });
  };
      
  const handleSubmit = (event: any) => {
    onSelectedOptionChange(pointOfSaleSelectedOption.pointOfSale || '')
    event.preventDefault();
    onClose();
  };
  
    return (
        <>
            <div className="point-of-sale-modal">
                <div className="point-of-sale-modal__group-label">{t('modal_point_of_sale_label')} :</div>
                <form className="point-of-sale-modal__form">
                    <div className="point-of-sale-modal__point-of-sale-select">
                      <TextInput id="titleSelect" type="text" name="" value={pointOfSaleSelectedOption.pointOfSale || ''} placeholder={t('point_of_sale')} onChange={(e: any) => handleChange('pointOfSale', e.target.value)} />
                    </div>
                    <div className="point-of-sale-modal__save-btn-container">
                        <button
                            onClick={handleSubmit}
                            className="point-of-sale-modal__save-btn"
                        >
                            {t('validate')}
                        </button>
                    </div>
                </form>
            </div>
        </>

    );
}

export default PointOfSaleModal;