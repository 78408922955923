import { DefaultValue } from 'recoil';

type CustomStorageValue<T> = NonNullable<T> | DefaultValue;

function customStorage<T>(key: string) {
  return process.env.NODE_ENV === 'test'
    ? undefined
    : [
      ({ setSelf, onSet }: { setSelf: (value: CustomStorageValue<T>) => void; onSet: (callback: (newValue: unknown) => void) => void }) => {
        setSelf(window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key) || '') : new DefaultValue());
        onSet((newValue: unknown) => {
          if (newValue === null) {
            window.localStorage.removeItem(key);
          } else {
            window.localStorage.setItem(key, JSON.stringify(newValue));
          }
          setSelf(newValue as CustomStorageValue<T>);
        });
      },
    ];
}

export default customStorage;
