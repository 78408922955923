import { AxiosResponse } from 'axios';
import { DealershipResponseType, AuthenticatedClient, BUResponse, AccountStatementResponseType } from '_api';

export const getDealerships = (): Promise<AxiosResponse<DealershipResponseType[]>> => {
  return AuthenticatedClient.get('/dealers');
};

export const getDealershipsById = (email: string): Promise<AxiosResponse<DealershipResponseType[]>> => {
  return AuthenticatedClient.get('/dealersdetailed/' + email);
};

// export const
// export const getBUs = (email: string, dealerId)
export const getBUsByDealer = (email: string, dealerId: string): Promise<AxiosResponse<BUResponse[]>> => {
  return AuthenticatedClient.get('/BUs/?userMail=' + email + '&dealerId=' + dealerId);
};

export const getComptesByDealerAndBU = (cuno: string, divi: string): Promise<AxiosResponse<AccountStatementResponseType[]>> => {
  return AuthenticatedClient.get('/comptes/?CUNO=' + cuno + '&DIVI=' + divi);
};


// 1. getDealersFromUser // récupérer les dealers d'un user // ON l'a deja
// 2. getBUsfromUser // récupérer les BU d'un dealer // TODO
// 3. getRelevesDeCompte // récupérer les relevé de compte from dealer et BU // A MODIFIER