import { FunctionComponent, useEffect, useState } from "react";
import {
  PageTitle,
  OrderTable,
  Modal,
  ClientInformations,
  CommentaryModal,
  PointOfSaleModalStock,
} from "_components";
import { useTranslation } from "react-i18next";
import { useLoading, useModal } from "_hooks";
import {
  OrderResponseType,
  Role,
  exportOrdersExcelData,
  getOrders,
  InfoClient,
  updateOrder,
  updateOrderCommentary,
  getOrdersOne,
  exportOrdersCsvData,
  point_of_sale,
  updatePointOfSaleOrders
} from "_api";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { atomDealers, atomUserRole } from "_atoms";
import { sortByRGD2Date } from "_utils/utils";
import "./order-page.scss";

const OrdersPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setModal } = useModal();

  const [orders, setOrders] = useState<OrderResponseType[]>([]);
  const { setLoading } = useLoading();
  const [isClientInfoModal, SetIsClientInfoModal] = useState(false);
  const [userRole] = useRecoilState(atomUserRole);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);
  const [currentClient, setCurrentClient] = useState<InfoClient | null>(null);
  const [isCommentaryModal, setIsCommentaryModal] = useState(false);
  const [currentCommentary, setCurrentCommentary] =
    useState<string | null>(null);

  function canToggle(userRole: Role | null): boolean {
    return (
      !!userRole &&
      (userRole.name === "Manager_commercial" ||
        userRole.name === "ADV" ||
        userRole.name === "Manager_ADV" ||
        userRole.name === "Dealer")
    );
  }

  const [userCanToggle] = useState<boolean>(canToggle(userRole));
  const [dealers] = useRecoilState(atomDealers);
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("order");

  function initData(): void {
    setLoading(true);
    if (orderId) {
      const params = {
        criterias: {
          dealerId: dealers,
        },
      };
      getOrdersOne(params, orderId)
        .then((ordersResult) => {
          setOrders(ordersResult.data.content);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            switch (error.response.status) {
              case 412:
                toast.error(t("toast_get_metadata_error_pim_orders"));
                break;
              case 413:
                toast.error(t("toast_get_data_error_pim_orders"));
                break;
              default:
                toast.error(t("toast_get_data_error"));
            }
          } else {
            toast.error(t("toast_get_data_error"));
          }
        });
    } else {
      const params = {
        criterias: {
          dealerId: dealers,
        },
      };
      getOrders(params)
        .then((ordersResult) => {
          setOrders(ordersResult.data.content);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            switch (error.response.status) {
              case 412:
                toast.error(t("toast_get_metadata_error_pim_orders"));
                break;
              case 413:
                toast.error(t("toast_get_data_error_pim_orders"));
                break;
              default:
                toast.error(t("toast_get_data_error"));
            }
          } else {
            toast.error(t("toast_get_data_error"));
          }
        });
    }
  }

  function exportCsvData(): void {
    setLoading(true);
    const params = {
      criterias: {
        dealerId: dealers,
      },
    };
    exportOrdersCsvData(params).then(
      (result) => {
        const anchor: HTMLAnchorElement = document.createElement("a");
        anchor.href = "data:text/csv;charset=utf-8," + encodeURI(result.data);
        anchor.download = "export-" + new Date().toLocaleDateString() + ".csv";
        anchor.click();
        setLoading(false);
      },
      (error) => {
        if (error.response && error.response.status === 412) {
          toast.error(t("toast_get_data_error_excel"));
          console.error(t("toast_get_data_error_excel"), error);
        } else {
          console.log("error: ", error);
        }
        setLoading(false);
      }
    );
  }

  function exportExcelData(): void {
    setLoading(true);
    const params = {
      criterias: {
        dealerId: dealers,
      },
    };
    exportOrdersExcelData(params).then(
      (result) => {
        const data = new Blob([result.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(data);

        const anchor: HTMLAnchorElement = document.createElement("a");
        anchor.href = url;
        anchor.download = "export-" + new Date().toLocaleDateString() + ".xls";
        anchor.click();
        setLoading(false);
      },
      (error) => {
        if (error.response && error.response.status === 412) {
          toast.error(t("toast_get_data_error_excel"));
          console.error(t("toast_get_data_error_excel"), error);
        } else {
          console.log("error: ", error);
        }
        setLoading(false);
      }
    );
  }

  function handleOpenClientInfoModal(quote: OrderResponseType | null): void {
    if (quote !== null) {
      setModal(true);
      SetIsClientInfoModal(true);
      setCurrentOrderId(quote.orderNumber);
      setCurrentClient(quote.infoClient);
    } else {
      setModal(false);
      SetIsClientInfoModal(false);
      setCurrentClient(null);
    }
  }

  function handleOpenCommentaryModal(order: OrderResponseType | null): void {
    if (order !== null) {
      setModal(true);
      setIsCommentaryModal(true);
      setCurrentOrderId(order.orderNumber);
      setCurrentCommentary(order.infoDealer);
    } else {
      setModal(false);
      setIsCommentaryModal(false);
      setCurrentCommentary(null);
    }
  }

  const [selectedCUNO, setSelectedCUNO] = useState<string>("");
  const [isPointOfSaleModal, setIsPointOfSaleModal] = useState(false);
  const [currentPointOfSaleId, setCurrentPointOfSaleId] =   useState<string | null>(null);


  const handleSelectedCUNOChange = (newCUNO: string) => {
    setSelectedCUNO(newCUNO);
  };

  const handleOpenPointOfSaleModal = (order: OrderResponseType | null) => {
    if (order !== null) {
      setModal(true);
      setIsPointOfSaleModal(true);
      setCurrentPointOfSaleId(order.orderNumber);
    } else {
      setModal(false);
      setIsPointOfSaleModal(false);
      setCurrentPointOfSaleId(null);
    }
  };


  function handleClientInfoChange(infoClient: InfoClient): void {
    setLoading(true);
    if (infoClient) {
      const order = orders.find(
        (quote) => quote.orderNumber === currentOrderId
      );
      if (order) {
        updateOrder(order.orderNumber, infoClient).then(
          () => {
            setOrders(
              orders.map((order) => {
                if (order.orderNumber === currentOrderId) {
                  order.infoClient = infoClient;
                }
                return order;
              })
            );
            setLoading(false);
          },
          () => {
            setLoading(false);
            toast.error(t("toast_get_data_error"));
          }
        );
      }
    }
  }

  function handleOrderCommentaryChange(commentary: string): void {
    setLoading(true);
    if (commentary) {
      // 1. requete.
      const order = orders.find(
        (order) => order.orderNumber === currentOrderId
      );
      if (order) {
        updateOrderCommentary(order.orderNumber, commentary).then(
          () => {
            setOrders(
              orders.map((order) => {
                if (order.orderNumber === currentOrderId) {
                  order.infoDealer = commentary;
                }
                return order;
              })
            );
            toast.success(t("order_update_commentary"));
            setLoading(false);
          },
          () => {
            setLoading(false);
            toast.error(t("toast_get_data_error"));
          }
        );
      }
    }
  }

  const handleSelectedOptionChange = (
    point_of_sale: point_of_sale | undefined
  ) => {
    setLoading(true);
    if (point_of_sale) {
      const filteredOrders = orders.find(
        (order: OrderResponseType) => order.orderNumber === currentPointOfSaleId
      );
      const selectedORNO = filteredOrders?.orderNumber;
      if (filteredOrders) {
        updatePointOfSaleOrders(selectedORNO, point_of_sale)
          .then(() => {
            initData();
            toast.success(t("point_of_sale_update_success"));
            setLoading(false);
          })
          .catch((error: { response: { status: any; }; }) => {
            setLoading(false);
            if (error.response) {
              switch (error.response.status) {
                case 412:
                  toast.error(t("toast_error_updating_point_of_sale_on_azure"));
                  break;
                case 413:
                  toast.error(t("toast_error_updating_point_of_sale_on_m3"));
                  break;
                default:
                  toast.error(t("toast_get_data_error"));
              }
            } else {
              toast.error(t("toast_get_data_error"));
            }
          });
      } else {
        setLoading(false);
        toast.error(t("toast_get_data_error"));
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dealers.length || orderId) {
      initData();
    } else {
      setOrders([]);
    }
  }, [dealers, orderId]);

  return (
    <div className="orders">
      <PageTitle
        title={t("my_orders_title")}
        description={t("my_orders_subtitle")}
      />
      <div className="orders__main-wrapper">
        <div className="orders__actions-wrapper">
          <div className="orders__export">
            <div className="orders__export-button">{t("export")}</div>
            <div className="orders__export-options">
              <button className="orders__export-option" onClick={exportCsvData}>
                {t("csv_export")}
              </button>
              <button
                className="orders__export-option"
                onClick={exportExcelData}
              >
                {t("excel_export")}
              </button>
            </div>
          </div>
        </div>
        {orders && orders.length ? (
          <OrderTable
            orders={sortByRGD2Date(orders)}
            userCanToggle={userCanToggle}
            setClientInfoModal={handleOpenClientInfoModal}
            setCommentaryModal={handleOpenCommentaryModal}
            onSelectedCUNOChange={handleSelectedCUNOChange}
            setPointOfSaleModal={handleOpenPointOfSaleModal}
          />
        ) : null}
        {isClientInfoModal && currentClient && (
          <Modal
            content={true}
            oncancel={() => SetIsClientInfoModal(false)}
            onValidate={() => SetIsClientInfoModal(false)}
          >
            <ClientInformations
              title={t("change_quote_info_client_order")}
              name="clientInfo"
              onClose={() => SetIsClientInfoModal(false)}
              client={currentClient}
              onClientChange={handleClientInfoChange}
            />
          </Modal>
        )}
        {isCommentaryModal && (
          <Modal
            content={true}
            oncancel={() => setIsCommentaryModal(false)}
            onValidate={() => setIsCommentaryModal(false)}
          >
            <CommentaryModal
              name="Commentary"
              onClose={() => setIsCommentaryModal(false)}
              onSelectedOptionChange={handleOrderCommentaryChange}
              commentary={currentCommentary}
            />
          </Modal>
        )}
            {isPointOfSaleModal && (
          <Modal
            content={true}
            oncancel={() => setIsPointOfSaleModal(false)}
            onValidate={() => setIsPointOfSaleModal(false)}
          >
            <PointOfSaleModalStock
              name="PointOfSale"
              onClose={() => setIsPointOfSaleModal(false)}
              selectedCUNO={selectedCUNO}
              onSelectedOptionChange={handleSelectedOptionChange}
            />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default OrdersPage;
