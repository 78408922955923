import { FunctionComponent, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Button, ButtonType } from "_components";
import { getUser, loginRequest } from "_api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { atomAccount, atomToken, atomUser, atomUserRole, atomUserRoles } from "_atoms";
import { useLoading } from "_hooks";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { User } from "_types";
import { useTranslation } from "react-i18next";
import './login-page.scss';
import { AccountInfo } from "@azure/msal-browser";


const LoginPage: FunctionComponent = () => {
  const { instance } = useMsal();
  const setToken = useSetRecoilState(atomToken);
  const [token] = useRecoilState(atomToken);
  const [user] = useRecoilState(atomUser);
  const setUser = useSetRecoilState(atomUser);
  const  setAccount = useSetRecoilState(atomAccount);
  const setUserRole = useSetRecoilState(atomUserRole);
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { t, i18n } = useTranslation();
  const setUserRoles = useSetRecoilState(atomUserRoles);


  const login = () => {
    setLoading(true);
    
    instance.loginPopup(loginRequest).then((result) => {

      const _token = result.accessToken;

      if (result.account) {
        setAccount(result.account as AccountInfo);
        const user: User = {
          id: result.uniqueId,
          username: result.account?.username || '',
          name: result.account?.name || '',
          language: '',
          completeName: '',
          country : ''
        }
        setUser(user);
      }
      setToken(_token);
      //navigate('/search');
    }, () => {
      setLoading(false);
      toast.error(t('login_error'));
    });
  }

  useEffect(() => {
    // Get the user roles.
    if (user && token) {
      getUser(user.id).then((result) => {
        const displayName = result.data[0].first_name + ' ' + result.data[0].last_name;
        const country = result.data[0].country;
        setUser({
         ...user,
          language : user.language,
          country : country,
          completeName: displayName,
        })
        setLoading(false);
        if (result && result.data && result.data.length && result.data[0].role) {
          setUserRole(result.data[0].role);
        }
        if (result && result.data && result.data.length && result.data[0]) {
          setUserRoles(result.data[0]);

          const lang = result.data[0].language;
          i18n.changeLanguage(lang);
        }
        navigate('/search');
      }).catch((error) => {
        setLoading(false);
        if (error.response) {
          switch (error.response.status) {
            case 412:
              toast.error(t('toast_get_data_error_user_azure_email'));
              break;
            case 413:
              toast.error(t('toast_get_data_error_user_information_from_the_pim'));
              break;
            case 414:
              toast.error(t('toast_get_data_error_roles_on_azure'));
              break;
            case 415:
              toast.error(t('toast_get_data_error_pim_user_information_from_azure'));             
               break;
            default:
              toast.error(t('login_error'));
            }
        } else {
          toast.error(t('login_error'));
        }
      });
    }
  }, [token, user]);

  return (
    <div className="login-page">
      <div className="login-page__illustration"></div>
      <div className="login-page__functionnal-wrapper">
        <div className="login-page__login-card">
          <Logo className="login-page__logo" />
          <h1 className="login-page__description">{t('login_description')}</h1>
          <div className="login-page__login-button-wrapper">
            <Button type={ButtonType.DARKSMALL} text={t('login_button')} onClick={login} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;