import { FunctionComponent, useEffect, useState } from "react";
import { Button, ButtonType, CustomSelect, OptionSelect, SelectType, TextInput } from "_components";
import { ReactComponent as Avatar } from "../../../assets/img/avatar.svg";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getOptionfromLocale, langsOptions } from "_utils";
import { DealershipResponseType, getDealershipsById, getUser, RoleResponseType, updateUser, UserResponseType } from "_api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { atomUser, atomUserRoles } from "_atoms";
import { useLoading } from "_hooks";
import { toast } from 'react-toastify';
import { AxiosResponse } from "axios";
import './profile-page.scss';
import '../../../i18n';

type FormValues = {
  firstname: string;
  lastname: string;
  name: string;
  email: string;
  language: OptionSelect;
  brands : string;
};

const ProfilePage: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
 // const user = useRecoilValue(atomUser); 

 const [user , setUser] = useRecoilState(atomUser);
  const { setLoading } = useLoading();
  const [role, setRole] = useState<RoleResponseType>();
  const [dealers, setDealers] = useState<DealershipResponseType[]>([]);
  const [userData, setUserData] = useState<UserResponseType>();
  const setUserRoles = useSetRecoilState(atomUserRoles);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Le prénom est requis'),
    email: Yup.string().required('L\'Email est requis').email('Email invalide'),
  });

  const [isEditingFirstname, setIsEditingFirstname] = useState(false);
  const [isEditingLastname, setIsEditingLastname] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      brands : '',
      language: { value: i18n.language, label: getOptionfromLocale(i18n.language).label, name: 'name' },
    }
  });

  const initData = () => {
    setIsEditingFirstname(false);
    setIsEditingLastname(false);

    if (user) {
      setLoading(true);
      getUser(user.id).then((result) => {
        if (result && result.data && result.data.length) {
          setValue('firstname', result.data[0].first_name);
          setValue('lastname', result.data[0].last_name);
          setValue('email', result.data[0].email);
          setValue('brands',result.data[0].brands);
          setRole(result.data[0].role);
          setUserData(result.data[0]);
        }
        getDealershipsById(user.username).then((dealershipsResult: AxiosResponse<DealershipResponseType[]>) => {
          setDealers(dealershipsResult.data);
          setLoading(false);
        }).catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 412:
                toast.error(t('toast_get_data_error_user_azure_email'));
                break;
              case 413:
                toast.error(t('toast_get_data_error_user_information_from_the_pim'));
                break;
              case 414:
                toast.error(t('toast_get_data_error_roles_on_azure'));
                break;
              case 415:
                toast.error(t('toast_get_data_error_pim_user_information_from_azure'));             
                 break;
              default:
                toast.error(t('toast_get_data_error'));
              }
          } else {
            toast.error(t('toast_get_data_error'));
          }
        });
      }, () => {
        toast.error(t('toast_get_data_error'));
        setLoading(false);
      })
    }
  };


  const onSubmit = (data: FormValues) => {

    const _data = {
      email: data.email,
      first_name: data.firstname || '',
      last_name: data.lastname || '', 
      language: data.language.value,
      dealers: dealers.map((dealer: DealershipResponseType) => dealer.id),
      role: role?.name || '',
      brands: Array.isArray(data.brands) ? data.brands.map((brand: { BU: any; name: string; id: string }) => brand.id.toString()) : [] 
    };
    if (user) {
      setLoading(true);
      updateUser(user.id, _data).then(() => {

        const updatedUserData: any = {
          ...userData,
          first_name: _data.first_name,
          last_name: _data.last_name, 
          language : _data.language
        };
        setUserData(updatedUserData);
        setUser((prev : any) => {
          return {
            ...prev,
            completeName: _data.first_name + " " + _data.last_name
          }
        })
        setIsEditingFirstname(false);
        setUserRoles(updatedUserData);
        toast.success(t('toast_update_success'));
        setLoading(false);
      }, () => {
        toast.error(t('toast_update_error'));
        setLoading(false);
      });
    }
  };


  useEffect(() => {
    initData();
  }, []);

  
  return (
    <div>
      <div className="content">
        <div className="label">
          <Avatar className="profile-svg" />
          <span className="label__title">{t("profile_page_Customer_account_information")}</span>
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <label className="">{t('profile_firstname')}</label>
              <div className="select-container select-container--static select-container--capitalize">
                {isEditingFirstname ? (
                  <TextInput name="firstname" type="text" control={control} />
                ) : (
                  userData ? userData.first_name : ''
                )}
                {isEditingFirstname ? (
                  <Button
                    type={ButtonType.SAVE}
                    onClick={() => {
                      setIsEditingFirstname(false); 
                      onSubmit(getValues()); 
                    }}
                  />
                ) : (
                  <Button
                    type={ButtonType.EDIT}
                    onClick={() => setIsEditingFirstname(true)}
                  />
                )}
              </div>
            </li>
            <li>
              <label className="">{t('profile_lastname')}</label>
              <div className="select-container select-container--static select-container--capitalize">
                {isEditingLastname ? (
                  <TextInput name="lastname" type="text" control={control} />
                ) : (
                  userData ? userData.last_name : ''
                )}
                {isEditingLastname ? (
                  <Button
                    type={ButtonType.SAVE}
                    onClick={() => {
                      setIsEditingLastname(false); 
                      onSubmit(getValues()); 
                    }}
                  />
                ) : (
                  <Button
                    type={ButtonType.EDIT}
                    onClick={() => setIsEditingLastname(true)} 
                  />
                )}
              </div>
            </li>

            <li>
              <label className="">{t('profile_email')}</label>
              <div className="select-container select-container--static">
                {userData ? userData.email : ''}
              </div>
            </li>
            <li>
              <label className="">{t('profile_role')}</label>
              <div className="select-container select-container--static">
                {role ? role.label : ''}
              </div>
            </li>

            <li>
              <label className="">{t('profile_lang')}</label>
              <div className="select-container">
                <CustomSelect name="language" optionsList={langsOptions} control={control} required type={SelectType.REGULAR} onChange={(e) => {
                  i18n.changeLanguage(e.value);
                  onSubmit(getValues());
                }} />
              </div>
            </li>
          </ul>
        </form>
        <div className="label label--margin-top">
          <span className="label__icon"></span>
          <span className="label__title">{t("profile_page_dealers")}</span>
        </div>
        <div className="concession-wrapper">
          {
            dealers.map((dealer: DealershipResponseType) => <div className="concession" key={dealer.id}>

              <div className="concession__header">
                <span className="concession__name">
                  {dealer.name}
                </span>
                <span>

                </span>
              </div>
              <div className="concession__adress">{dealer.address1}<br />{dealer.postal_code} {dealer.city}</div>
            </div>)
          }
        </div>
      </div>
    </div>
  );
}

export default ProfilePage;
