import { FunctionComponent, useMemo } from "react";
import { useModal } from "_hooks";
import { IconButton } from "_components";
import { useTranslation } from "react-i18next";
import { COCRequestResponseType } from "_api";

import "./table.scss";
import { atomUserRole } from "_atoms";
import { useRecoilValue } from "recoil";

type COCRequestTableProps = {
  COCRequests: COCRequestResponseType[];
  setIsToRequestOpen: (open: boolean) => void;
  selectedNumberRequest: (requestNumber: string) => void;
  selectedORNO: (orno: string) => void;
  selectedBANO: (bano: string) => void;
  selectedClientName: (clientName: string) => void;
};

const COCRequestTable: FunctionComponent<COCRequestTableProps> = ({
  COCRequests,
  setIsToRequestOpen,
  selectedNumberRequest,
  selectedORNO,
  selectedBANO,
  selectedClientName,
}) => {
  const userRole = useRecoilValue(atomUserRole);

  const { t } = useTranslation();
  const { setModal } = useModal();

  const isDAF = userRole?.name === "DAF";
  const isUserTrigano = userRole?.name === "User-trigano";

  const filteredCOCRequests = useMemo(
    () => COCRequests.filter((request) => request.RECO === "9"),
    [COCRequests]
  );

  const handleToRequestClick = (
    numberRequest: string,
    orno: string,
    bano: string,
    clientName: string
  ) => {
    setModal(true);
    setIsToRequestOpen(true);
    selectedNumberRequest(numberRequest);
    selectedORNO(orno);
    selectedBANO(bano);
    selectedClientName(clientName);
  };



  return (
    <div className="page-container">
      <div className="table">
        <div className="table__header">
          <div className="table__sticky">
            <div className="table__group">
              <div className="table__title  to-request">{t("to_request")}</div>
              <div className="table__title request-status">
                {t("request_status")}
              </div>
            </div>
          </div>
          <div className="table__group">
            <div className="table__title request-date">{t("request_date")}</div>
            <div className="table__title request-number">
              {t("commentary")}
            </div>
            <div className="table__title invoice-number">
              {t("coc_invoice_number")}
            </div>
            <div className="table__title customer">{t("customer")}</div>
            <div className="table__title brand-coc ">{t("coc_brand")}</div>
            <div className="table__title model-coc">{t("model")}</div>
            <div className="table__title vintage">{t("vintage")}</div>
          </div>
        </div>
        {filteredCOCRequests.map((COCRequest, index) => {
          return (
            <div key={index} className="table__line">
              <div className="table__sticky">
                <div className="table__group">
                  <div className="table__item to-request">
                    {COCRequest.a_demande && (
                      <IconButton
                        handlechange={() =>
                          handleToRequestClick(
                            COCRequest.numero_demande,
                            COCRequest.ORNO,
                            COCRequest.BANO,
                            COCRequest.client
                          )
                        }
                        disabled={isDAF || isUserTrigano}
                      />
                    )}
                  </div>

                  <div className="table__item request-status">
                    {COCRequest.statut}{" "}
                  </div>
                </div>
              </div>
              <div className="table__group">
                <div className="table__item request-date">
                  {COCRequest.date_demande && COCRequest.date_demande !== "0"
                    ? new Date(COCRequest.date_demande).toLocaleDateString()
                    : ""}
                </div>
                <div className="table__item request-number">
                  {COCRequest.numero_demande}
                </div>
                <div className="table__item invoice-number">
                  {COCRequest.numero_facture}
                </div>
                <div className="table__item customer">{COCRequest.client}</div>
                <div className="table__item brand-coc ">
                  {COCRequest.marque}
                </div>
                <div className="table__item model-coc">{COCRequest.model}</div>
                <div className="table__item vintage">
                  {COCRequest.millesime}
                </div>
                {/* <div className="table__item see-more">
                  <button onClick={() => openConfigurator(COCRequest)} className="see-more-btn">{t('see_more_btn')}</button>
                    </div>*/}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default COCRequestTable;
