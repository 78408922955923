import { FunctionComponent, useRef } from "react";
import { Button, ButtonType } from "_components";
import { useModal, useOutsideClick } from "_hooks";
import './modal.scss';

export type ModalProps = {
    children: React.ReactNode;
    onValidate?: () => void;
    oncancel?: () => void;
    content?: boolean;
}

export const Modal: FunctionComponent<ModalProps> = (props) => {
    const wrapperRef = useRef(null);
    
    const { visible } = useModal();
    const { setModal } = useModal();
    
    useOutsideClick(wrapperRef, () => {
        setModal(false);
        if (props.oncancel) { // Utilisez onCancel au lieu de oncancel
            props.oncancel();
        }
    });

    const validateClick = () => {
        setModal(false);
        if (props.onValidate) {
            props.onValidate();
        }
    };
    return (
        <div className={visible ? 'modal modal--open' : 'modal modal--close'} >
            <div className="modal__content" ref={wrapperRef}>
                {/*<button className="modal__close-button" onClick={() => { setModal(false) }}></button>*/}
                <div>{props.children}</div>
                {!props.content ?
                    <div className="modal__footer">
                        {/*<div className="modal__button modal__button--cancel" onClick={() => { setModal(false) }}>Annuler</div>*/}
                        {/*<div className="modal__button modal__button--validate" onClick={validateClick}>Valider</div>*/}
                        <Button type={ButtonType.SECONDARY} text="Annuler" onClick={() => { setModal(false) }} />
                        <Button type={ButtonType.PRIMARY} text="Valider" onClick={validateClick} />
                    </div> : null}
            </div>
        </div >
    );
}

export default Modal;