import { atom } from 'recoil';
import customStorage from './customStorage';

const key = 'jwt-token';

const atomToken = atom<string | null>({
  key,
  default: null,
  effects: customStorage(key),
});

export default atomToken;
