import { FunctionComponent } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { VehicleResult, Availability } from "_api";
import {
  FilterLabel,
  LocalLoader,
  PlanningDateSwitcher,
  RowVehicle,
} from "_components";
import "./planning-results.scss";

type PlanningResultsProps = {
  filtersLabels: string[];
  results: VehicleResult[];
  totalCount: number;
  onLoadMore: () => void;
  period?: string;
  availabilities: Availability[];
  onPlanningResultsPeriodChange: (period: string) => void;
  onSetFullScreenPic?: (picture: string) => void;
};

const PlanningResults: FunctionComponent<PlanningResultsProps> = (
  props: PlanningResultsProps
) => {
  return (
    <div className="planning-results">
      {props.filtersLabels.length ? (
        <div className="planning-results__filters-container">
          {props.filtersLabels.map((label) => {
            return <FilterLabel label={label} key={label} />;
          })}
        </div>
      ) : null}

      {props.period ? (
        <div className="planning-results__date-switcher-container">
          <PlanningDateSwitcher
            onPlanningResultsPeriodChange={(period) =>
              props.onPlanningResultsPeriodChange(period)
            }
            availabilities={props.availabilities}
            period={props.period}
          />
        </div>
      ) : null}

      <div className="planning-results__content">
        <InfiniteScroll
          dataLength={(props.results && props.results.length) || 0}
          next={() => props.onLoadMore()}
          loader={<LocalLoader />}
          className="planning-results__scroll-container"
          hasMore={props.results.length < props.totalCount}
        >
          {props.results && props.results.length
            ? props.results.map((result: VehicleResult, index: number) => {
                return (
                  <RowVehicle
                    key={index}
                    vehicle={result}
                    isPlanningResult={true}
                    onSetFullScreenPic={(picture) =>
                      props.onSetFullScreenPic
                        ? props.onSetFullScreenPic(picture)
                        : null
                    }
                  />
                );
              })
            : null}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default PlanningResults;
