import { FunctionComponent } from "react";
import './global-search-order-result.scss';
import { SearchOrderResponse } from "_api";
import { Link } from "react-router-dom";

export type GlobalSearchOrderResultProps = {
  result: SearchOrderResponse;
}

const GlobalSearchOrderResult: FunctionComponent<GlobalSearchOrderResultProps> = ({ result }) => {
  return (
    <Link className="global-search-order-result" to={`/orders?order=${result.commandeId}`}>
      <div className="global-search-order-result__column">
        {result.orderNumber}
      </div>
      <div className="global-search-order-result__column">
        {result.year}
      </div>
      <div className="global-search-order-result__column">
        {result.status}
      </div>
      <div className="global-search-order-result__column">
        {result.brand}
      </div>
      <div className="global-search-order-result__column">
        {result.model}
      </div>
      <div className="global-search-order-result__column">
        {result.infoClient.firstName}
      </div>
      <div className="global-search-order-result__column">
        {result.seriesNumber}
      </div>
    </Link>
  );
}

export default GlobalSearchOrderResult;

