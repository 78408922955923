import { FunctionComponent, useEffect, useState } from "react";
import { ClientInformations, Modal, PageTitle, QuoteTable, PointOfSaleModal, CommentaryModal } from "_components";
import { useTranslation } from "react-i18next";
import { useLoading, useModal } from "_hooks";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { QuoteResponseType, getQuotes, Quote, updateQuote, InfoClient, updateQuotePointOfSale } from "_api";
import "./quotation-page.scss";
import { atomDealers, atomUser } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";


const QuotationPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const { setLoading } = useLoading();
  const [dealers] = useRecoilState(atomDealers);
  const [isClientInfoModal, SetIsClientInfoModal] = useState(false);
  const [isPointOfSaleModal, setIsPointOfSaleModal] = useState(false);
  const [isCommentaryModal, setIsCommentaryModal] = useState(false);
  const [currentQuoteId, setCurrentQuoteId] = useState<number | null>(null);
  const [currentClient, setCurrentClient] = useState<InfoClient | null>(null);
  const [currentPointOfSale, setCurrentPointOfSale] = useState<string | null>(null);
  const [currentCommentary, setCurrentCommentary] = useState<string | null>(null);
  const user = useRecoilValue(atomUser);


  const { setModal } = useModal();
  const urlParams = new URLSearchParams(window.location.search);
  const ornoSearch = urlParams.get('order');

  const initData = () => {
    setLoading(true);
    if(ornoSearch) {
      const params = {
        criteria: {
          "dealerId": dealers
        },
        ORNO : ornoSearch
      };
      getQuotes(params).then((quotesResult: AxiosResponse<QuoteResponseType>) => {
      setQuotes(quotesResult.data.quotes);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error.response) {
        switch (error.response.status) {
          case 412:
            toast.error(t('toast_get_metadata_error_pim_quote'));
            break;
          case 413:
            toast.error(t('toast_get_data_error_pim_quote'));
            break;
          default:
            toast.error(t('toast_get_data_error'));
        }
      } else {
        toast.error(t('toast_get_data_error'));
      }
    });
    }else {
    const params = {
      criteria: {
        "dealerId": dealers
      },
      ORNO : ""
      };
      getQuotes(params).then((quotesResult: AxiosResponse<QuoteResponseType>) => {
      setQuotes(quotesResult.data.quotes);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error.response) {
        switch (error.response.status) {
          case 412:
            toast.error(t('toast_get_metadata_error_pim_quote'));
            break;
          case 413:
            toast.error(t('toast_get_data_error_pim_quote'));
            break;
          default:
            toast.error(t('toast_get_data_error'));
        }
      } else {
        // Handle cases where the error is not from a HTTP response
        toast.error(t('toast_get_data_error'));
      }
    });
  }
  };

  const handleOpenCommentaryModal = (quote: Quote | null) => {
    if (quote !== null) {
      setModal(true)
      setIsCommentaryModal(true);
      setCurrentQuoteId(quote.indexedId);
      setCurrentCommentary(quote.commentary)
    } else {
      setModal(false)
      setIsCommentaryModal(false);
      setCurrentCommentary(null)
    }
  };


  const handleOpenPointOfSaleModal = (quote: Quote | null) => {
    if (quote !== null) {
      setModal(true)
      setIsPointOfSaleModal(true);
      setCurrentQuoteId(quote.indexedId)
      setCurrentPointOfSale(quote.selectedPointOfSale)
    } else {
      setModal(false)
      setIsPointOfSaleModal(false);
      setCurrentPointOfSale(null)
    }
  };

  const handleOpenClientInfoModal = (quote: Quote | null) => {
    if (quote !== null) {
      setModal(true)
      SetIsClientInfoModal(true);
      setCurrentQuoteId(quote.indexedId);
      setCurrentClient(quote.infoClient)
    } else {
      setModal(false)
      SetIsClientInfoModal(false);
      setCurrentClient(null);
    }
  };

  useEffect(() => {
    if (dealers.length) {
      initData();
    }else {
      setQuotes([]);
    }
  }, [dealers]);

  const handlePointOfSaleChange = (pointOfSale: string) => {
    setLoading(true);
    if (pointOfSale) {
      const quote = quotes.find((quote) => quote.indexedId === currentQuoteId);
      if (quote) {
        updateQuotePointOfSale( quote.ORNO, null, pointOfSale).then(
          () => {
            setQuotes(quotes.map((quote) => {
              if (quote.indexedId === currentQuoteId) {
                quote.selectedPointOfSale = pointOfSale;
              }
              return quote;
            }));
            setLoading(false);
          },
          () => {
            setLoading(false);
            toast.error(t('toast_get_data_error'));
          }
        )
      }

    }
  };

  const handleCommentaryChange = (commentary: string) => {
    setLoading(true);
    if (commentary) {
      // 1. requete.
      const quote = quotes.find((quote) => quote.indexedId === currentQuoteId);
      if (quote && user) {
        updateQuote(quote.indexedId, quote.ORNO, null, commentary, null,user).then(
          () => {
            setQuotes(quotes.map((quote) => {
              if (quote.indexedId === currentQuoteId) {
                quote.commentary = commentary;
              }
              return quote;
            }));
            setLoading(false);
          },
          () => {
            setLoading(false);
            toast.error(t('toast_get_data_error'));
          }
        )
      }
    }

  };

  const handleClientInfoChange = (infoClient: InfoClient) => {
    setLoading(true);
    if (infoClient) {
      const quote = quotes.find((quote) => quote.indexedId === currentQuoteId);
      if (quote) {
        updateQuote(quote.indexedId, quote.ORNO, infoClient, null, null,user).then(
          () => {
            setQuotes(quotes.map((quote) => {
              if (quote.indexedId === currentQuoteId) {
                quote.infoClient = infoClient;
              }
              return quote;
            }));
            setLoading(false);
          },
          () => {
            setLoading(false);
            toast.error(t('toast_get_data_error'));
          }
        )
      }

    }
  };

  return (
    <div className="quotes">

      <PageTitle title={t('my_quotes_title')} description={t('my_quotes_subtitle')} />
      <div className="quotes__main-wrapper">
        {quotes && quotes.length ?
          <QuoteTable setClientInfoModal={handleOpenClientInfoModal} setPointOfSaleModal={handleOpenPointOfSaleModal} quotes={quotes} setCommentaryModal={handleOpenCommentaryModal}
          />
          : null}
        {isClientInfoModal && currentClient && (
          <Modal
            content={true}
            oncancel={() => SetIsClientInfoModal(false)}
            onValidate={() => SetIsClientInfoModal(false)}
          >
            <ClientInformations title={t('change_quote_info_client_quotation')} name="clientInfo" onClose={() => SetIsClientInfoModal(false)} client={currentClient} onClientChange={handleClientInfoChange} />
          </Modal>
        )}
        {isPointOfSaleModal && (
          <Modal content={true} oncancel={() => setIsPointOfSaleModal(false)} onValidate={() => setIsPointOfSaleModal(false)}>
            <PointOfSaleModal name="PointOfSale" onClose={() => setIsPointOfSaleModal(false)} onSelectedOptionChange={handlePointOfSaleChange} pointOfSale={currentPointOfSale} />
          </Modal>
        )}
        {isCommentaryModal &&  (
          <Modal content={true} oncancel={() => setIsCommentaryModal(false)} onValidate={() => setIsCommentaryModal(false)}>
            <CommentaryModal name="Commentary" onClose={() => setIsCommentaryModal(false)} onSelectedOptionChange={handleCommentaryChange} commentary={currentCommentary} />
          </Modal>
        )}

      </div>
    </div>
  );
};

export default QuotationPage;
