import { AxiosResponse } from 'axios';
import { AuthenticatedClient, UserResponseType, UserParamsType, UpdateUserParamsType } from '_api';


export const getUser = (id: string): Promise<AxiosResponse<UserResponseType[]>> => {
  return AuthenticatedClient.get('/user/' + id);
};

export const getUsers = (): Promise<AxiosResponse<UserResponseType[]>> => {
  return AuthenticatedClient.get('/users');
};

export const getUsersById = (id: string): Promise<AxiosResponse<UserResponseType[]>> => {
  return AuthenticatedClient.get('/users_by_dealers/' + id);
};

//TOTO change 
export const createUser = (user: UserParamsType): Promise<AxiosResponse<UserResponseType>> => {
  return AuthenticatedClient.post('/create_users/', user);
};

export const deleteUser = (user: UserResponseType): Promise<AxiosResponse<string>> => {
  return AuthenticatedClient.delete('/delete_users/' + user.email);
}

export const updateUser = (id:string, user: UpdateUserParamsType): Promise<AxiosResponse<UserResponseType>> => {
  return AuthenticatedClient.put('/edit_users/' + id, user);
}