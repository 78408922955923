import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import './dealer-contact-informations.scss';



type DealerInformationsProps = {
  onClose: ()=> void,
  stock: any
};


const DealersContactInformations: FunctionComponent<DealerInformationsProps> = ({ onClose, stock }) => {
  const { t } = useTranslation();
  return (
    <div className="modal-dealer-info">
      <div className="modal-dealer-info__title">{t('dealer_address')}</div>
      <div className="modal-dealer-info__detail">
        <div className="name big">{stock.point_of_sale.name}</div>
        <div className="name">{stock.point_of_sale.name}</div>
        <div className="address">{stock.point_of_sale.address}</div>
        <div className="city">{stock.point_of_sale.postal_code} {stock.point_of_sale.city}</div>
        <div className="contact">
          <div className="contact__label">{t('contact')}</div>
          <div className="contact__email">{stock.dealerEmail}</div>
        </div>
      </div>
          <div className="btn-container">
            <button className="cancel-btn" onClick={onClose}> {t('close_btn')}</button>
          </div>
    </div>
  );
};

export default DealersContactInformations;