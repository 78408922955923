import { FunctionComponent, useEffect, useRef, useState } from "react";
import { SearchFilters, SearchResults, PageTitle, FullScreenPic, SearchFiltersRef } from "_components";
import { useLoading } from "_hooks";
import { toast } from "react-toastify";
import { cloneDeep, isEqual } from 'lodash';
import { SearchCategory, searchData, SearchResponseType, VehicleResult } from "_api";
import { SearchFormType, FiltersParams, Pagination } from "_types";
import { AxiosError, AxiosResponse } from "axios";
import { isEmptyObject } from "_utils";
import { Trans, useTranslation } from "react-i18next";
import './search-page.scss';
import { atomDealers, atomUserRole, atomUserRoles } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { atomUser } from "_atoms";


const PAGINATION_STEP = 9;

const SearchPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const [dealers] = useRecoilState(atomDealers);
  const [userCountry] = useRecoilState(atomUser);
  const [filters, setFilters] = useState<SearchFormType | undefined>();
  const [filtersPrevious, setFiltersPrevious] = useState<SearchFormType | undefined>();
  const [sorting, setSorting] = useState<'availability_id asc, production_date asc' | 'price asc' | 'price desc'>();
  const [searchResults, setSearchResults] = useState<{ totalCount: number, results: VehicleResult[], minPrice: number | null, maxPrice: number | null }>({
    totalCount: 0,
    results: [],
    minPrice: null,
    maxPrice: null
  });
 // const [similarResults, setSimilarResults] = useState<VehicleResult[]>([]);
  const [filtersResults, setFiltersResults] = useState<SearchResponseType>();
  const [pagination, setPagination] = useState<Pagination>({
    skip: 0,
    top: PAGINATION_STEP
  });
  const [fullScreenImg, setFullScreenImg] = useState<string | null>(null);
  const [lastCategoriesUpdated, setLastCategoriesUpdated] = useState<string[]>([]);
  const searchFiltersRef = useRef<SearchFiltersRef | undefined>();

  const userRoles = useRecoilValue(atomUserRoles);
  const userRole = useRecoilValue(atomUserRole);

 /* const isPlanningPageButtonEnabled = (filters: SearchFormType | undefined) => {
    if (!filters || !filters.data) return false;
    else {
      let active = false;
      for (const property in filters.data) {
        for (const subProperty in filters.data[property]) {
          if (filters.data[property][subProperty]) {
            active = true;
          }
        }
      }
      if (!active) {
        return filters.price && typeof filters.price.min === 'number' && typeof filters.price.max === 'number';
      }
      else {
        return active;
      }
    }
  }
*/
  const isPlanningPageButtonEnabled = (filters: SearchFormType | undefined) => {
    if (!filters || !filters.data) return false;
    else {
      let active = false;
        for (const subProperty in filters.data.brand) {
          if (filters.data.brand[subProperty]) {
            active = true;
            return active
        }
      }
      if (active) {
        return filters.price && typeof filters.price.min === 'number' && typeof filters.price.max === 'number';
      }
      else {
        return active;
      }
    }
  }


  // Go to planning page.
  const onPlanningPageClick = () => {
    let params = '';
    const activeFilters: FiltersParams = {};
    let activePrices: number[] = [];
    const filtersLabels: string[] = [(filters && filters.currentForm ? (filters.currentForm === 'motorhome') ? t('motorhome') : t('caravan') : t('motorhome'))];
    const currentForm = filters ? filters.currentForm === 'motorhome' ? 'motorhome' : 'caravan' : 'motorhome';
    if (filters && (filters.data || filters.price)) {
      FullScreenPic
      for (const property in filters.data) {
        for (const subProperty in filters.data[property]) {
          if (filters.data[property][subProperty]) {
            if (!activeFilters[property]) {
              activeFilters[property] = {};
            }
            activeFilters[property][subProperty] = filters.data[property][subProperty];
            if (filtersResults && filtersResults[currentForm] && filtersResults[currentForm].categories) {
              filtersResults[currentForm].categories.forEach((category) => {
                if (category.id === property) {
                  category.fields.forEach((field) => {
                    if (field.id === subProperty) {
                      if (category.id === 'seats_number') {
                        filtersLabels.push(t('seats_number') + ' : ' + field.label);
                      }
                      else if (category.id === 'beds_number') {
                        filtersLabels.push(t('beds_number') + ' : ' + field.label);
                      }
                      else {
                        filtersLabels.push(field.label);
                      }
                    }
                  });
                }
              });
            }
          }
        }
      }
    }
    if (filters && filters.price && typeof filters.price.min === 'number' && typeof filters.price.max === 'number') {
      activePrices = [filters.price.min, filters.price.max];
    }
    const paramsObject: {
      currentForm?: 'motorhome' | 'caravan';
      filters?: FiltersParams,
      price?: number[],
      labels?: string[]
    } = {};
    // Add current form.
    paramsObject.currentForm = currentForm || 'motorhome';
    // Add regular filters.
    if (!isEmptyObject(activeFilters)) {
      paramsObject.filters = activeFilters;
    }
    // Add prices.
    if (activePrices.length) {
      paramsObject.price = activePrices;
    }
    // Add labels.
    if (filtersLabels) {
      paramsObject.labels = filtersLabels;
    }
    if (!isEmptyObject(paramsObject)) {
      params = encodeURIComponent(JSON.stringify(paramsObject));
    }
    if (params.length) params = '?filters=' + params;
    window.open('/planning' + params);
    return null;
  }

  // Set filters change.
  const onFiltersChange = (filters: SearchFormType) => {
    setFilters(filters);
  }

  // Set new sorting on select change.
  const onSortingChange = (sorting: 'availability_id asc, production_date asc' | 'price asc' | 'price desc') => {
    setSorting(sorting);
  }

  // On load more => update pagination.
  const onLoadMore = () => {
    setPagination((previous) => {
      return {
        skip: previous.skip + PAGINATION_STEP,
        top: PAGINATION_STEP
      }
    });
  };

  // Load data from API.
  const loadData = (filters: SearchFormType | null, sorting: string | null, pagination: Pagination, copyFilters: boolean) => {
    const _criterias: {
      [x: string]: Array<number | string>
    } = {};
    let _categoriesUpdated: string[] = [];

    if (filters && filters.data) {

      // Case 1: no previous filters: first click on filter || first click after motorhome/caravan.
      // We need to find the last filter category updated.
      for (const property in filters.data) {
        for (const subProperty in filters.data[property]) {
          if (filters.data[property][subProperty]) {
            if (_categoriesUpdated.indexOf(property) < 0) _categoriesUpdated.push(property);
          }
        }
      }

      // Case 2: need to compare data to get the last updated category.
      if (filtersPrevious) {
        if (filtersPrevious.currentForm === filters.currentForm) {
          for (const property in filters.data) {
            if (filtersPrevious.data[property] && !isEqual(filters.data[property], filtersPrevious.data[property])) {
              for (const subProperty in filters.data[property]) {
                if (filters.data[property][subProperty]) {
                  if (_categoriesUpdated.indexOf(property) < 0) _categoriesUpdated.push(property);
                }
              }
            }
          }
        }
      }

      if (!copyFilters) {
        _categoriesUpdated = lastCategoriesUpdated;
      }
      else {
        setLastCategoriesUpdated(_categoriesUpdated);
      }

      // Construct the criterias for regular filter categories.
      for (const property in filters.data) {
        if (filters.data[property]) {
          for (const subProperty in filters.data[property]) {
            if (filters.data[property][subProperty]) {
              if (!_criterias[property]) {
                _criterias[property] = [];
              }
              _criterias[property].push(subProperty);
            }
          }
        }
      }
    }
    // Add the price criteria.
    if (filters && filters.price && typeof filters.price.min === 'number' && typeof filters.price.max === 'number') {
      _criterias.price = [filters.price.min, filters.price.max];
    }
    // Add the vehicle type criteria.
    _criterias.vehicle_type = [1];
    if (filters && filters.currentForm) {
      _criterias.vehicle_type = [filters.currentForm === 'motorhome' ? 1 : 2];
    }
    // Add the dealers to the criterias.
    if (dealers) {
      _criterias.dealers = dealers;
    }

    const isAgentOrAgentLimiteOrUserTrigano = (
      userRole?.name === "Agent" ||
      userRole?.name === "Agent-Limite" ||
      userRole?.name === "User-trigano"
    );
    
    const userBrand = userRoles?.brands;

    const filteredDealerIds = userBrand.map((dealer : any)=> dealer.id);

    if (isAgentOrAgentLimiteOrUserTrigano && userBrand) {
      _criterias.brand = filteredDealerIds;
    }
    // setLoading(true);
    searchData({
      criterias: _criterias,
      skip: pagination.skip,
      top: pagination.top,
      sort: sorting || 'availability_id asc, production_date asc',
      country : userCountry?.country
    }).then((result: AxiosResponse<SearchResponseType>) => {
      // DEBUG...
      if (filters && copyFilters) {
        setFiltersPrevious(cloneDeep(filters));
      }

      const currentForm = (filters && filters.currentForm) ? filters.currentForm === 'motorhome' ? 'motorhome' : 'caravan' : 'motorhome';

      // Get min & max price.
      let minPrice: number | null = null;
      let maxPrice = 0;
      if (result && result.data && result.data[currentForm].categories && result.data[currentForm].categories) {
        result.data[currentForm].categories.forEach((category) => {
          if (category.id === 'price') {
            category.fields.forEach((field) => {
              if (minPrice === null) minPrice = Number(field.id);
              if (minPrice !== null && Number(field.id) < minPrice) minPrice = Number(field.id);
              if (Number(field.id) > maxPrice) maxPrice = Number(field.id);
            })
          }
        });
      }
      // Update results.
      setSearchResults((previous) => {
        const newResults = pagination.skip === 0 ? result.data.value : [...previous.results, ...result.data.value];
        return (
          {
            totalCount: result.data.count_total,
            results: newResults,
            minPrice,
            maxPrice
          }
        );
      })
      // Update filters.
      setFiltersResults((previous: SearchResponseType | undefined) => {
        const newResult = result.data;
        if (result.data && result.data.caravan && result.data.caravan.categories) {
          result.data.caravan.categories.forEach((category: SearchCategory, index: number) => {
            if (_categoriesUpdated.indexOf(category.id) > -1) {
              if (previous && previous.caravan && previous.caravan.categories && previous.caravan.categories[index]) {
                newResult.caravan.categories[index] = previous.caravan.categories[index];
              }
            }
          });
        }
        if (result.data && result.data.motorhome && result.data.motorhome.categories) {
          result.data.motorhome.categories.forEach((category: SearchCategory, index: number) => {
            if (_categoriesUpdated.indexOf(category.id) > -1) {
              if (previous && previous.motorhome && previous.motorhome.categories && previous.motorhome.categories[index]) {
                newResult.motorhome.categories[index] = previous.motorhome.categories[index];
              }
            }
          });
        }
        return newResult;
      });
      if (pagination.skip === 0) {
        setLoading(false);
      } else {
        setLoading(false);
      }
     // Load similiar results.
     /* if (pagination.skip === 0) {
        searchSimilarData({ criterias: _criterias }).then((similarResult: AxiosResponse<SearchSimilarResultsResponseType>) => {
          setSimilarResults(similarResult.data);
          setLoading(false);
        }, () => {
          0
          setLoading(false);
          toast.error(t('toast_get_data_error'));
        });
      }
      else {
        setLoading(false);
      }*/
    }, (error : AxiosError) => {
      setLoading(false);
      if( error.response?.status != 412){
        toast.error(t('toast_get_data_error'));
      }
    })
  }

  // 0. On dealers change (from header) => reinit filters.
  useEffect(() => {
    if (filtersResults && searchFiltersRef) {
      searchFiltersRef.current?.reset();
    }

    // force request if dealers changed
    if (dealers && dealers.length) {
      setLoading(true);
      const _pagination: Pagination = {
        skip: 0,
        top: PAGINATION_STEP
      }
      loadData(filters || null, sorting || null, _pagination, true);
      setPagination(_pagination); 
    }else {
      // If there are no dealers, set an empty result
      setSearchResults({
        totalCount: 0,
        results: [],
        minPrice: null,
        maxPrice: null
      });
      setLoading(false);
    }

  }, [dealers]);

  // 1. On filters change | dealers change => reinit pagination & load data (with global loader).
  useEffect(() => {
    setLoading(true);
    const _pagination: Pagination = {
      skip: 0,
      top: PAGINATION_STEP
    }
    loadData(filters || null, sorting || null, _pagination, true);
    setPagination(_pagination); // This will not load dada (3).
  }, [filters]);

  // 2. On sorting change => reinit pagination (with global loader).
  useEffect(() => {
    setLoading(true);
    setPagination({
      skip: 0,
      top: PAGINATION_STEP
    })
  }, [sorting]);

  // 3. On pagination change => load data (without global loader).
  useEffect(() => {
    if (pagination.skip > 0 || sorting) {
      loadData(filters || null, sorting || null, pagination, false);
    }
  }, [pagination]);

  return <>

    <FullScreenPic img={fullScreenImg} onClose={() => { setFullScreenImg(null) }} />

    <PageTitle title={t('search_page_title')} description={<Trans i18nKey="search_page_description"></Trans>} searchPage={true} onPlanningPageClick={onPlanningPageClick} enablePlanningPageButton={isPlanningPageButtonEnabled(filters)} />

    <div className="search">
      <div className="search__main-content">

        <div className="search__filters">
          <div className="search-page__search-wrapper">

            <SearchFilters
              ref={searchFiltersRef}
              onChange={onFiltersChange}
              filters={filtersResults || null}
              minPrice={searchResults.minPrice}
              maxPrice={searchResults.maxPrice}
            />

          </div>
        </div>

        <SearchResults
          onSortingChange={onSortingChange}
          onLoadMore={onLoadMore}
          results={searchResults.results}
         //similarResults={similarResults} 
          totalCount={searchResults.totalCount}
          onSetFullScreenPic={(picture) => setFullScreenImg(picture)}
        />

      </div>
    </div>
  </>
}

export default SearchPage;
