import { FunctionComponent, useState } from "react";
import { TextInput } from "_components";
import { useTranslation } from "react-i18next";
import "../ClientInformations/client-informations.scss";
import { InfoClient } from "_api";
import { useRecoilValue } from "recoil";
import { atomUser } from "../../../_atoms";

type ClientInformationsProps = {
  client?: InfoClient;
  name: string;
  title: string;
  onClientChange: (client: InfoClient) => void;
  onSubmit?: () => void;
  onClose: () => void;
};

const ClientInformations: FunctionComponent<ClientInformationsProps> = ({
  name,
  onClose,
  title,
  client,
  onClientChange,
}) => {
  const { t } = useTranslation();
  const user = useRecoilValue(atomUser);

  const [values, setValues] = useState({
    name: client?.lastName || "",
    firstname: client?.firstName || "",
    email: client?.clientEmail || "",
    phone: client?.clientPhone || "",
  });

  const handleChange = (key: string, value: string) => {
    setValues((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleSubmit = (event: any) => {
    onClientChange({
      clientId: client?.clientId || 0,
      lastName: values.name || "",
      firstName: values.firstname || "",
      clientEmail: values.email || "",
      clientPhone: values.phone || "",
      dealerEmail: client?.dealerEmail ?? user?.username ?? "",
    });
    event.preventDefault();
    onClose();
  };

  return (
    <div className="modal-client-info">
      <div className="modal-client-info__title">{title}</div>
      <form className="modal-client-info__form">
        <TextInput
          id="clientName"
          type="text"
          name={`${name}.client.name`}
          placeholder={t("profile_lastname") + " *"}
          value={values.name}
          onChange={(e: any) => handleChange("name", e.currentTarget.value)}
        />
        <TextInput
          id="clientFirstname"
          type="text"
          name={`${name}.client.firstname`}
          placeholder={t("profile_firstname")}
          value={values.firstname}
          onChange={(e: any) =>
            handleChange("firstname", e.currentTarget.value)
          }
        />
        <TextInput
          id="clientEmail"
          type="email"
          name={`${name}.client.email`}
          placeholder={t("profile_email") + " *"}
          value={values.email}
          onChange={(e: any) => handleChange("email", e.currentTarget.value)}
        />
        <TextInput
          id="clientPhone"
          type="text"
          name={`${name}.client.phone`}
          placeholder={t("profile_phone")}
          value={values.phone}
          onChange={(e: any) => handleChange("phone", e.currentTarget.value)}
        />
        <div className="btn-container">
          <button
            className="save-btn"
            onClick={handleSubmit}
            disabled={!values.name || !values.email}
          >
            {" "}
            {t("users_add")}
          </button>
          <button className="cancel-btn" onClick={onClose}>
            {" "}
            {t("cancel_btn")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientInformations;
