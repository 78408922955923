import { FunctionComponent } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.scss';

const Toast: FunctionComponent = () => {

  return (
    <ToastContainer hideProgressBar={true} />
  );
}

export default Toast;