import { FunctionComponent } from "react";
import { useLoading } from "_hooks";
import './global-loader.scss';

const Header: FunctionComponent = () => {
  const { loading } = useLoading();

  return (
    <>
      {loading ? (
        <div className="global-loader">

          <div className="loading-box">
              <div className="loader"></div>
          </div>

          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          {/*<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>*/}

        </div>
      ) : null}
    </>
  );
}

export default Header;