import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './radio.scss';

export type Option = {
  id: string,
  label: string | JSX.Element,
  value: string
}

export type RadioProps = {
  // Shared
  options: Array<Option>,
  name: string,
  // Native
  value?: string|null,
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
  // react-hook-form required
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const Radio: FunctionComponent<RadioProps> = (props) => {
  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({field}) => {
        return (
          <div className="radio" {...props}>
            {props.options.map((option) => {
              return (
                <div key={option.id} className="radio__option-container">
                  <input type="radio" id={option.id} value={option.value} name={props.name} onChange={() => field.onChange(option.value)} checked={field.value === option.value} className="radio__option-input"/>
                  <label htmlFor={option.id} className="radio__option-label">{option.label}</label>
                </div>
              )
            })}
        </div>
        );
      }}
    />
    );
  }
  else {
    return (
      <div className="radio" {...props}>
        {props.options.map((option) => {
          return (
            <div key={option.id} className="radio__option-container">
              <input type="radio" id={option.id} value={option.value} name={props.name} onChange={props.onChange} checked={props.value === option.value} className="radio__option-input"/>
              <label htmlFor={option.id} className="radio__option-label">{option.label}</label>
            </div>
          )
        })}
      </div>
    );
  }
}

export default Radio;