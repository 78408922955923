import { FunctionComponent, useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import './account-base-page.scss';
import '../../i18n';
import { useTranslation } from "react-i18next";
import { PageTitle } from "_components";

const getNavClass = ({ isActive }: { isActive: boolean }) => {
  if (isActive) {
    return 'profile-layout__header__link--active ';
  }
  else {
    return 'profile-layout__header__link';
  }
}

const AccountBasePage: FunctionComponent = () => {
  
  const { t,i18n } = useTranslation()
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState('');
  const [dynamicText, setDynamicText] = useState('');

  const getElementFromUrl = () => {

    const url = window.location.href;
    if (url.includes('/account/profile')) {
      setDynamicText(t('account_nav_profile'))
      return 'account_my_account'
    }
    if (url.includes('/account/manage-user')) {
      setDynamicText(t('account_nav_manage_users'))
      return 'account_manage_users'
    }
    if (url.includes('/account/alerts')) {
      setDynamicText(t('account_nav_manage_notifications'))
      return 'account_notifications'
    }
    if (url.includes('/account/contacts')) {
      setDynamicText(t('account_nav_contacts'))
      return 'account_contacts'
    }
    else {
      return ''
    }
  }

  useEffect(() => {
    setPageTitle(getElementFromUrl)
  }, [navigate , i18n.language]);

  return (

    <div className="profile-layout">

      {/*
      <div className="profile-layout__header">
        <div className="profile-layout__header__content-wrapper">

          <div className="profile-layout__header__nav">
            {t(pageTitle)}
          </div>

          <div className="profile-layout__header__config">
            {dynamicText}
          </div>

        </div>
      </div>
      */}

      <PageTitle title={t(pageTitle)} description={dynamicText} />

      <div className="profile-layout__main-content-wrapper">

        <div className="profile-layout__menu">

          <nav>
            <NavLink
              to="/account/profile"
              className={getNavClass}
            >
              <div className="icon-home"></div>
              <div>{t("profile_layout_my_account")}</div>
            </NavLink>
            <NavLink
              to="/account/manage-users"
              className={getNavClass}

            >
              <div className="icon-account-group"></div>
              <div>{t("profile_layout_user_management")}</div>
            </NavLink>
            <NavLink
              to="/alerts/all"
              className={getNavClass}
            >
              <div className="icon-bell"></div>
              <div>{t("profile_layout_my_notifications")}</div>
            </NavLink>
            <NavLink
              to="/account/contacts"
              className={getNavClass}
            >
              <div className="icon-handshake"></div>
              <div>{t("profile_layout_contacts")}</div>
            </NavLink>
          </nav>
        </div>

        <div className="profile-layout__content">
          <Outlet />
        </div>

      </div>

    </div>);
}

export default AccountBasePage;