import { atom } from 'recoil';
import customStorage from './customStorage';
import { Role } from '_api';

const key = 'userRole';

const atomUserRole = atom<Role | null>({
  key,
  default: null,
  effects: customStorage(key),
});

export default atomUserRole;
