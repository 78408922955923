import { FunctionComponent, useEffect, useState } from "react";
import './general-search-page.scss';
import { GlobalSearchOrderResult, GlobalSearchQuoteResult, PageTitle, Searchbar } from "_components";
import { useLocation } from "react-router-dom";
import { useLoading } from "_hooks";
import { GlobalSearchResponse, SearchOrderResponse, SearchQuoteResponse, globalSearch } from "_api";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { atomDealers } from "_atoms";
import { useTranslation } from "react-i18next";

const GeneralSearchPage: FunctionComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const [activeTab, setActiveTab] = useState<'orders' | 'quote'>('orders');
  const [dealers] = useRecoilState(atomDealers);
  const { setLoading } = useLoading();
  const [results, setResults] = useState<GlobalSearchResponse>({
    commandes: {
      content: []
    },
    quotes: {
      content: []
    }
  });

  const searchData = (query: string) => {
    setLoading(true);
    globalSearch(query , dealers).then((result) => {
      setResults(result.data);
      setLoading(false);
    }, () => {
      toast.error('Une erreur est survenue lors de la récupération des données');
      setLoading(false);
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const _search = params.get('q') || '';
    setSearch(_search);
    searchData(_search);
  }, [location]);


  return (
    <div className="general-search-page">

      <PageTitle title={t("search")} description={t("header_nav_general_search")}/>

      <div className="general-search-page__top-page">
        <div className="general-search-page__top-page-content">

          <form className="general-search-page__input-container" onSubmit={(e) => { e.preventDefault(); searchData(search); }} >
            <Searchbar value={search} onChange={(e) => setSearch(e.currentTarget.value)} onSubmit={() => { null }} />
          </form>

        </div>
      </div>

      <div className="general-search-page__body-page">

        <div className="general-search-page__body-page-content">

          <div className="general-search-page__tabs">
            <button className={`general-search-page__tab ${activeTab === 'orders' ? 'general-search-page__tab--active' : ''}`} onClick={() => setActiveTab('orders')}>{t("general_search_orders")}<span className="general-search-page__results-nb">({results.commandes.content.length})</span></button>
            <button className={`general-search-page__tab ${activeTab === 'quote' ? 'general-search-page__tab--active' : ''}`} onClick={() => setActiveTab('quote')}>{t("general_search_quotes")}<span className="general-search-page__results-nb">({results.quotes.content.length})</span></button>
          </div>

          <div className="general-search-page__results">
            {activeTab === 'orders' ? (
              <>
                <div className="general-search-page__results-header">
                  <div className="general-search-page__results-header-title">{t("order_number")}</div>
                  <div className="general-search-page__results-header-title">{t("year")}</div>
                  <div className="general-search-page__results-header-title">{t("status")}</div>
                  <div className="general-search-page__results-header-title">{t("brand")}</div>
                  <div className="general-search-page__results-header-title">{t("model")}</div>
                  <div className="general-search-page__results-header-title">{t("info_client")}</div>
                  <div className="general-search-page__results-header-title">{t("serie_number")}</div>
                </div>
                <div className="general-search-page__results-wrapper">
                  {results.commandes.content.map((order: SearchOrderResponse) => {
                    return <GlobalSearchOrderResult  key={order.commandeId} result={order} />;
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="general-search-page__results-header">
                  <div className="general-search-page__results-header-title">{t("status")}</div>
                  <div className="general-search-page__results-header-title">{t("quote_number")}</div>
                  <div className="general-search-page__results-header-title">{t("year")}</div>
                  <div className="general-search-page__results-header-title">{t("client_name")}</div>
                  <div className="general-search-page__results-header-title">{t("brand")}</div>
                  <div className="general-search-page__results-header-title">{t("model")}</div>
                  <div className="general-search-page__results-header-title">{t("amount")}</div>
                  <div className="general-search-page__results-header-title">{t("creation_date")}</div>
                  <div className="general-search-page__results-header-title">{t("point_of_sale")}</div>
                </div>
                {results.quotes.content.map((quote: SearchQuoteResponse) => {
                  return <GlobalSearchQuoteResult   key={quote.ORNO}  result={quote} />
                })}
              </>
            )}
          </div>

        </div>

      </div>
    </div >
  );
}

export default GeneralSearchPage;


