import { atom } from 'recoil';
import customStorage from './customStorage';

const key = 'dealers';

const atomDealers = atom<string[]>({
  key,
  default: [],
  effects: customStorage(key),
});

export default atomDealers;
