import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './text-input.scss';

export type TextInputProps = {
    // shared
    id?: string;
    name: string;
    type: string;
    hidden?: boolean;
    // native
    value?: string|number;
    onChange?: (event: FormEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    // react-hook-form required
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
    maxLength?: number; // Add maxLength
}

const TextInput: FunctionComponent<TextInputProps> = (props) => {
  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({field, fieldState}) => {
          return (
            <div className="text-input">
              <input
                type={props.type}
                placeholder={props.placeholder}
                disabled={!!props.disabled}
                required={props.required}
                className={`text-input__input ${fieldState.error ? ' text-input__input--error' : ''}`} 
                value={field.value}
                maxLength={props.maxLength || undefined}
                onChange={field.onChange}
                hidden={!!props.hidden}
              />
              <p className="text-input__error-message">{fieldState?.error?.message}</p>
            </div>
          );
        }}
      />
    );
  }
  else {
    return (
      <div className="text-input">
        <input
          type={props.type}
          placeholder={props.placeholder}
          disabled={!!props.disabled}
          required={props.required}
          className={`text-input__input`}
          value={props.value}
          maxLength={props.maxLength || undefined}
          onChange={props.onChange}
          hidden={!!props.hidden}
        />
      </div>
    );
  }
}

export default TextInput;