import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice } from "_utils";
import { AccountStatementResponseType } from "_api";
import './account-statement-table.scss';
import { Total } from "_pages/AccountStatementPage/AccountStatementPage";

export type accountStatementTableProps = {
  accountStatements: AccountStatementResponseType[];
  totals: Total;
}

const AccountStatementTable: FunctionComponent<accountStatementTableProps> = ({ accountStatements, totals }) => {

  const { t } = useTranslation();

  return (
    <>
      <div className="table_AccountStatement">

        <div className="table__header">
          <div className="table__group">
            <div className="table__title accountStatement-date">{t('date')}</div>
            <div className="table__title accountStatement-piece">{t('piece')}</div>
            <div className="table__title accountStatement-label">{t('label')}</div>
            <div className="table__title accountStatement-date">{t('due_date')}</div>
            <div className="table__title accountStatement-debit">{t('debit')}</div>
            <div className="table__title accountStatement-credit">{t('credit')}</div>
            <div className="table__title accountStatement-balance">{t('balance')}</div>
          </div>
        </div>
        {accountStatements.map((statement) => {
          const debitAmount = statement.Debit && typeof statement.Debit === 'string' ? Number(statement.Debit) : 0.0;
          return (
            <div className="table__line" key={statement.Piece}>
              <div className="table__group">
                <div className="table__item accountStatement-date">{new Date(statement.Date).toLocaleDateString()}</div>
                <div className="table__item accountStatement-piece">{statement.Piece}</div>
                <div className="table__item accountStatement-label"> 
                  {statement.alerte ? <span className="warning-icon"></span> : null}
                  {statement.Libelle}
                </div>
                <div className="table__item accountStatement-date">{new Date(statement.Echeance).toLocaleDateString()}</div>
                <div className="table__item accountStatement-credit">{formatPrice(Number(debitAmount))}</div>
                <div className="table__item accountStatement-debit">{formatPrice(Number(statement.Credit))}</div>
                <div className="table__item accountStatement-balance"></div>
              </div>
            </div>
          );
        })}
        <div className="table__line total-line">
          <div className="table__item accountStatement-total">Total</div>
          <div className="table__item accountStatement-debit">{formatPrice(totals?.debits)}</div>
          <div className="table__item accountStatement-credit">{formatPrice(totals?.credits)}</div>
          <div className="table__item accountStatement-balance">{formatPrice(totals?.balances)}</div>
        </div>
      </div>
      <div>
      </div>
    </>
  );
}

export default AccountStatementTable;
