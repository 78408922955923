import { AxiosResponse } from 'axios';
import { AuthenticatedClient, SearchParamsType, SearchResponseType, SimilarResultsSearchParamsType, SearchSimilarResultsResponseType } from '_api';

export const searchData = (params: SearchParamsType): Promise<AxiosResponse<SearchResponseType>> => {
  return AuthenticatedClient.post('/search/', params);
};

export const searchSimilarData = (params: SimilarResultsSearchParamsType): Promise<AxiosResponse<SearchSimilarResultsResponseType>> => {
  return AuthenticatedClient.post('/search-similar-configs/', params);
};
