import { atom } from "recoil";
import customStorage from "./customStorage";

type DealerBrand = {
  label: string;
  value: string;
  name: string;
  CUNO: string;
  brands: {
    id: number;
    name: string;
  }[];
}
const key = 'dealersBrand';

const atomDealersBrand = atom<DealerBrand[]>({
  key,
  default: [],
  effects: customStorage(key),
});

export default atomDealersBrand;