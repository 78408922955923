import { FunctionComponent, useEffect, useState } from "react";
import {
  getPointOfSaleList,
  point_of_sale,
} from "_api";
import { useLoading } from "_hooks";
import "./select-point-of-sale.scss";
import { useTranslation } from "react-i18next";


export type SelectPointOfSaleProps = {
    selectedCUNO: string;
    onSelectionChange: (selectedPointOfSale: point_of_sale) => void;
  };

const SelectPointOfSale: FunctionComponent<SelectPointOfSaleProps> = ({
  selectedCUNO,onSelectionChange
}) => {
  const [pointOfSaleList, setPointOfSaleList] = useState<point_of_sale[]>([]);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<point_of_sale>();
  const { setLoading } = useLoading();


    const fetchData = () => {
      setLoading(true);
      getPointOfSaleList(selectedCUNO)
        .then(pointOfSaleResult => {
          setPointOfSaleList(pointOfSaleResult.data.point_of_sale);
          setLoading(false);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    };
    
    useEffect(() => {
    fetchData();
  }, [selectedCUNO]);




  function handlePointOfSaleChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    const cunoAdid = e.target.value;
    const selectedPointOfSale = pointOfSaleList.find(pointOfSale => pointOfSale.cunoAdid === cunoAdid);
  
    setSelectedValue(selectedPointOfSale);
    
    if (selectedPointOfSale) {
      onSelectionChange(selectedPointOfSale);
    }
  }
  return (
    <div className="point-of-sale-stock">
      <select
        className="point-of-sale-stock__dealer-pointOfSale"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handlePointOfSaleChange(e)}
        value={selectedValue?.cunoAdid}
      >
        <option value="" disabled selected hidden>
         {t('select_point_of_sale')}
        </option>
        {pointOfSaleList?.map((pointOfSale) => (
          <option key={pointOfSale.cunoAdid} value={pointOfSale?.cunoAdid}>
            {pointOfSale?.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export default SelectPointOfSale;
