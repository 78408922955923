import React, { FunctionComponent } from "react";
import StockItem from "./StockItem"; // Make sure to correctly import StockItem
import { Stock } from "_api";
import { useTranslation } from "react-i18next";

type StockTableProps = {
  stockProducts: Stock[];
  setStockInfoModal: (stock: Stock | null) => void;
  setClientInfoModal: (stock: Stock | null) => void;
  setPointOfSaleModal: (stock: Stock | null) => void;
  onSelectedCUNOChange: (newCUNO: string) => void;
};

const StockTable: FunctionComponent<StockTableProps> = ({
  stockProducts,
  setStockInfoModal,
  setClientInfoModal,
  setPointOfSaleModal,
  onSelectedCUNOChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="page-container">
      <div className="table">
        <div className="table__header">
          <div className="table__sticky">
            <div className="table__group">
              <div className="table__title final-client">
                {t("final_client")}
              </div>
              <div className="table__title stock-exchange">
                {t("stock_exchange")}
              </div>
            </div>
          </div>
          <div className="table__group">
            <div className="table__title type">{t("type")}</div>
            <div className="table__title dealer-location">
              {t("dealer_name_location_short")}
            </div>
            <div className="table__title brand">{t("brand")}</div>
            <div className="table__title model">{t("model")}</div>
            <div className="table__title year">{t("year")}</div>
            <div className="table__title carrier">{t("carrier")}</div>
            <div className="table__title bano">{t("bano")}</div>
            <div className="table__title billing-date">{t("billing_date")}</div>
          </div>
        </div>

        {stockProducts.map((stockproduct, index) => (
          <StockItem
            key={stockproduct.invoiceNumber || index}
            stock={stockproduct}
            setStockInfoModal={setStockInfoModal}
            setClientInfoModal={setClientInfoModal}
            setPointOfSaleModal={setPointOfSaleModal}
            onSelectedCUNOChange={onSelectedCUNOChange}
          />
        ))}
      </div>
    </div>
  );
};

export default StockTable;
