import { atom } from 'recoil';
import customStorage from './customStorage';
import {  UserResponseType } from '_api';

const key = 'userRoles';

const atomUserRoles = atom<UserResponseType | null>({
  key,
  default: null,
  effects: customStorage(key),
});

export default atomUserRoles;
