import { FunctionComponent, useEffect, useState } from "react";
import "./planning-date-switcher.scss";
import { formatDate } from "_utils";
import { Availability } from "_api";
import { useTranslation } from "react-i18next";

type PlanningDateSwitcherProps = {
  period: string;
  availabilities: Availability[];
  onPlanningResultsPeriodChange: (period: string) => void;
};

const PlanningDateSwitcher: FunctionComponent<PlanningDateSwitcherProps> = (
  props: PlanningDateSwitcherProps
) => {
  const { t } = useTranslation();
  const [sortedNextAvailabilities, setSortedNextAvailabilities] = useState<
    Availability[]
  >([]);
  const [sortedPastAvailabilities, setSortedPastAvailabilities] = useState<
    Availability[]
  >([]);

  const getNextAvailabilities = () => {
    const [periodYear, periodMonth] = props.period.split("-").map(Number);
    const futureAvailabilities = props.availabilities.filter((availability) => {
      const [year, month] = availability.value.split("-");
      return (
        Number(year) > periodYear ||
        (Number(year) === periodYear && Number(month) > periodMonth)
      );
    });
    return futureAvailabilities.sort((number1, number2) => {
      const [yearA, monthA] = number1.value.split("-");
      const [yearB, monthB] = number2.value.split("-");
      return Number(yearA) - Number(yearB) || Number(monthA) - Number(monthB);
    });
  };

  const getPastAvailabilities = () => {
    const [periodYear, periodMonth] = props.period.split("-").map(Number);
    const today = new Date();
    const pastAvailabilities = props.availabilities.filter((availability) => {
      const [availabilityYear, availabilityMonth] = availability.value
        .split("-")
        .map(Number);
      const availabilityDate = new Date(
        availabilityYear,
        availabilityMonth - 1
      );
      return (
        availabilityDate < new Date(periodYear, periodMonth - 1) &&
        (availabilityDate.getFullYear() > today.getFullYear() ||
          (availabilityDate.getFullYear() === today.getFullYear() &&
            availabilityDate.getMonth() >= today.getMonth()))
      );
    });
    return pastAvailabilities.sort((number1, number2) => {
      const [yearA, monthA] = number1.value.split("-");
      const [yearB, monthB] = number2.value.split("-");
      return Number(yearB) - Number(yearA) || Number(monthB) - Number(monthA);
    });
  };

  function handleNextButtonClick() {
    if (sortedNextAvailabilities && sortedNextAvailabilities[0]) {
      props.onPlanningResultsPeriodChange(sortedNextAvailabilities[0].value);
    }
  }

  function handlePastButtonClick() {
    if (sortedPastAvailabilities && sortedPastAvailabilities[0]) {
      props.onPlanningResultsPeriodChange(sortedPastAvailabilities[0].value);
    }
  }

  useEffect(() => {
    setSortedNextAvailabilities(getNextAvailabilities());
    setSortedPastAvailabilities(getPastAvailabilities());
  }, [props.period, props.availabilities]);

  return (
    <div className="planning-date-switcher">
      <div className="planning-date-switcher__header">
        <span className="planning-date-switcher__label">
          {t("planning_date_switcher_production")}
        </span>
        <div className="planning-date-switcher__actions-container">
          <button
            disabled={!sortedPastAvailabilities.length}
            className={`planning-date-switcher__change-date-btn planning-date-switcher__change-date-btn--prev`}
            onClick={handlePastButtonClick}
          ></button>

          <button
            disabled={!sortedNextAvailabilities.length}
            className={`planning-date-switcher__change-date-btn planning-date-switcher__change-date-btn--next`}
            onClick={handleNextButtonClick}
          ></button>
        </div>
      </div>
      <div className="planning-date-switcher__main-content">
        <span className="planning-date-switcher__current-date">
          {formatDate(props.period, t)}
        </span>
      </div>
    </div>
  );
};

export default PlanningDateSwitcher;
