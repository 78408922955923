import { useState, useEffect, useRef, useCallback, memo, forwardRef, useImperativeHandle, Ref } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, ValueSwitch, Radio, FormSearch, PriceSelector, TextInput, PriceSelectorRef } from "_components";
import { debounce } from 'lodash';
import { useTranslation } from "react-i18next";
import { SearchCategory, SearchResponseType } from "_api";
import { SearchFormType } from "_types";
import "./search-filters.scss";

const SEARCH_DELAY = 600; // ms
const CARAVAN_MAX_PRICE = 70000.00; // €
const MOTORHOME_MAX_PRICE = 200000; // €

export type SearchFiltersProps = {
  onChange: (newFilters: SearchFormType) => void;
  filters: SearchResponseType | null;
  minPrice: number | null;
  maxPrice: number | null;
}

export type SearchFiltersRef = {
  reset(): void;
}

type Field = {
  id: string;
  label: string;
  nbItems: number;
  value?: string;
}

const SearchFilters = (props: SearchFiltersProps, ref: Ref<SearchFiltersRef | undefined>) => {
  const { t } = useTranslation();
  const [visibleSections, setVisibleSections] = useState<string[]>(['']);
  const [currentForm, setCurrentForm] = useState<'motorhome' | 'caravan'>('motorhome');
  const [formFieldsFilters, setFormFieldsFilters] = useState<{
    [x: string]: string;
  }>({});
  const defaultValues: SearchFormType = {
    currentForm: currentForm,
    price: {
      min: null,
      max: null
    },
    data: {}
  };
  props.filters && props.filters[currentForm].categories.map((category) => {
    defaultValues.data[category.id] = {};
    category.fields.forEach((field) => {
      defaultValues.data[category.id][field.id] = false;
    })
  });
  const priceSelectorRef = useRef<PriceSelectorRef | undefined>();

  const {
    control,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const changeCurrentForm = (formName: 'motorhome' | 'caravan') => {
    reset();
    setValue('currentForm', formName);
    setCurrentForm(formName);
    setVisibleSections([]);
  }

  useImperativeHandle(ref, () => {
    return {
      reset: () => {
        changeCurrentForm('motorhome');
      }
    }
  }, []);

  const toggleVisibleSection = (event: React.MouseEvent<HTMLButtonElement>, name: string) => {
    event.preventDefault();
    /*
    const index = visibleSections.indexOf(name);
    if (index > -1) {
      setVisibleSections(visibleSections.filter((_undefined, _index) => _index !== index));
    }
    else {
      setVisibleSections([...visibleSections, name]);
    }
    */
    const index = visibleSections.indexOf(name);
    if (index > -1) {
      setVisibleSections([]);
    }
    else {
      setVisibleSections([name]);
    }
  };

  const isSectionVisible = (name: string) => {
    return visibleSections.indexOf(name) > -1;
  }

  const updateDataDebounced = useCallback(debounce((data: SearchFormType) => {
    props.onChange(data);
  }, SEARCH_DELAY), [props.onChange]);

  useEffect(() => {
    const { unsubscribe } = watch((data) => {
      updateDataDebounced(data as SearchFormType);
    });
    return () => unsubscribe();
  }, [watch]);

  const isCategoryActive = useCallback((category: SearchCategory) => {
    if (category.id === 'price') {
      const values = getValues(`price`);
      return values && typeof values.min === 'number' && typeof values.max === 'number';
    }
    else {
      const values = getValues(`data.${category.id}`);
      let validValue = false;
      if (values) {
        for (const property in values) {
          if (values[property]) {
            validValue = true;
          }
        }
      }
      return validValue;
    }
  }, []);

  const handleReset = () => {
    reset();
    if (priceSelectorRef) {
      priceSelectorRef.current?.reset();
    }
    setValue('currentForm', currentForm);
    setVisibleSections([]);
  }


  const getTranslatedFields = (category : any) => {

    let fields : any = [];

    if (props.filters?.motorhome) {
      const motorhomeFields = props.filters.motorhome.categories.find(c => c.id === category)?.fields || [];
      if (motorhomeFields.length > 0) {
          fields = motorhomeFields;
      }
 }
  if (fields.length === 0 && props.filters?.caravan) {
      fields = props.filters.caravan.categories.find(c => c.id === category)?.fields || [];
  }

  return fields.map((field : any )=> {
      let translationKey = '';

      switch (field.label) {
        case 'Disponible en stock concession' :
           translationKey = 'search_result_stock_label_available';
           break;
        case 'Commandé usine':
          translationKey = 'vehicle_status_factory_ordered';
          break;
        case 'Disponible en stock usine':
          translationKey = 'vehicle_status_available_factory';
          break;
        case 'En cours de production':
          translationKey = 'vehicle_status_in_production';
          break;
        case 'À configurer':
          translationKey = 'vehicle_status_configurable';
          break;
          case 'Automatique':
            translationKey = 'gearbox_automatic';
            break;
          case 'Manuelle':
            translationKey = 'gearbox_manual';
            break;
          case 'Lit central':
              translationKey = 'bed_central';
              break;
          case 'Lit transversal':
              translationKey = 'bed_transverse';
              break;
          case 'Lits jumeaux':
              translationKey = 'bed_twin';
              break;
          case 'Lits de pavillon':
              translationKey = 'bed_drop_down';
              break;
        default:
          // Si aucun cas n'est correspondant, utiliser le label tel quel (ou gérer autrement)
          translationKey = field.label;
      }
  
      // Retourner l'objet field avec le label traduit
      return {
        ...field,
        label: t(translationKey)
      };
    });
  };
  

  const renderSectionCategory = (category: SearchCategory) => {

    let fieldsToRender = category.fields;
    if (category.id === 'availability_id' || category.id === 'gearbox' ||  category.id === 'implantation' ) {
      fieldsToRender = getTranslatedFields(category.id);
    }
  

    // Checkboxes.
    if (category.type === 'multipleSelection') {
      return (
        <div key={category.id} className={`search-filters__form-section ${!isSectionVisible(category.label) ? ' search-filters__form-section--hidden' : ''}`}>
          <button className="search-filters__form-section-header" onClick={(e) => toggleVisibleSection(e, category.label)}>
            <span className={`search-filters__form-section-title ${isCategoryActive(category) ? 'search-filters__form-section-title--active' : ''} ${category.hasFilter ? 'search-filters__form-section-title--search' : ''}`}>{t(category.label)}</span>
          </button>
          <div className="search-filters__form-section-content">
            {/* Form search filter */}
            {(category.hasFilter) ? (
              <div>
                <FormSearch
                  type="string"
                  name="search"
                  value={formFieldsFilters[category.id]}
                  onChange={(e) => { setFormFieldsFilters({ [category.id]: e.target.value }); }}
                  onEnter={() => {
                    const filteredFilters = fieldsToRender.filter((field: Field) => {
                      return !category.hasFilter || !field.label || !formFieldsFilters[category.id] || field.label.toLowerCase().indexOf(formFieldsFilters[category.id].toLowerCase()) > -1;
                    });
                    if (filteredFilters.length === 1 && category.id) {
                      const id = `data-${category.id}-${filteredFilters[0].id}`;
                      const checkbox = document.querySelector('#' + id) as HTMLInputElement;
                      if (checkbox) {
                        checkbox.click();
                      }
                    }
                    setFormFieldsFilters({ [category.id]: '' });
                  }}
                />
              </div>
            ) : null}

            <div className={`${category.hasFilter ? 'search-filters__form-group-container' : ''}`}>
              {fieldsToRender.filter((field: Field) => {
                return !category.hasFilter || !field.label || !formFieldsFilters[category.id] || field.label.toLowerCase().indexOf(formFieldsFilters[category.id].toLowerCase()) > -1;
              }).map((field: Field) => {
                return (
                  <div className="search-filters__form-group" key={field.id}>
                    <div className="search-filters__value-label-container">
                      <Checkbox id={`data-${category.id}-${field.id}`} name={`data.${category.id}.${field.id}`} control={control} />
                      <label className="search-filters__form-label" htmlFor="spareTime">{field.label}</label>
                    </div>
                    <span className="search-filters__form-filter-number">{field.nbItems}</span>
                  </div>
                );
              })}
            </div>

          </div>
        </div>
      );
    }
    // Radio.
    else if (category.type === 'singleSelection') {
      return (
        <div key={category.id} className={`search-filters__form-section ${!isSectionVisible(category.label) ? ' search-filters__form-section--hidden' : ''}`}>
          <button className="search-filters__form-section-header" onClick={(e) => toggleVisibleSection(e, category.label)}>
            <span className={`search-filters__form-section-title ${isCategoryActive(category) ? 'search-filters__form-section-title--active' : ''}`}>{t(category.label)}</span>
          </button>
          <div className="search-filters__form-section-content">
            <div className="search-filters__form-group">
              <div className="search-filters__value-label-container">
                <Radio name={`data.${category.id}`} control={control} options={category.fields.map((field: Field) => {
                  return {
                    id: field.id,
                    label: field.label,
                    value: field.value || ''
                  }
                })} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Toggles.
    else if (category.type === 'multipleSelectionToggle') {
      return (
        <div key={category.id} className={`search-filters__form-section ${!isSectionVisible(category.label) ? ' search-filters__form-section--hidden' : ''}`}>
          <button className="search-filters__form-section-header" onClick={(e) => toggleVisibleSection(e, category.label)}>
            <span className={`search-filters__form-section-title ${isCategoryActive(category) ? 'search-filters__form-section-title--active' : ''}`}>{t(category.label)}</span>
          </button>
          <div className="search-filters__form-section-content">
            <div className="search-filters__form-group search-filters__form-group--value-switch">
              <div className="search-filters__value-switch-values-container">
                {category.fields.map((field: Field) => {
                  return (
                    <ValueSwitch key={field.id} name={`data.${category.id}.${field.id}`} text={field.label} nbItems={field.nbItems} control={control} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
    // Price.
    else if (category.type === 'price') {
      return (
        <div key={category.id} className={`search-filters__form-section ${!isSectionVisible(category.label) ? ' search-filters__form-section--hidden' : ''}`}>
          <button className="search-filters__form-section-header" onClick={(e) => toggleVisibleSection(e, category.label)}>
            <span className={`search-filters__form-section-title ${isCategoryActive(category) ? 'search-filters__form-section-title--active' : ''}`}>{t(category.label)}</span>
          </button>
          <div className="search-filters__form-section-content">
            <PriceSelector ref={priceSelectorRef} name="price" control={control} min={props.minPrice !== null ? props.minPrice : 0} max={props.maxPrice !== null ? props.maxPrice : (currentForm === 'motorhome' ? MOTORHOME_MAX_PRICE : CARAVAN_MAX_PRICE)} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="search-filters">

      <div className="search-filters__header">
        <span className="search-filters__form-title">{t('filters_title')}</span>
        <button className="search-filters__reset-button" onClick={() => handleReset()}><span className="search-filters__reset-button-text">{t('filters_reinit')}</span></button>
        {/* hidden input used to store currentForm */}
        <TextInput type="text" name="currentForm" control={control} hidden={true} />
      </div>

      <div className="search-filters__vehicle-types">
        <button className={`search-filters__vehicle-type-button ${currentForm === 'motorhome' ? ' search-filters__vehicle-type-button--active' : ''}`} onClick={() => changeCurrentForm('motorhome')}>{t('motorhome')}</button>
        <button className={`search-filters__vehicle-type-button ${currentForm === 'caravan' ? ' search-filters__vehicle-type-button--active' : ''}`} onClick={() => changeCurrentForm('caravan')}>{t('caravan')}</button>
      </div>

      <form>

        {currentForm === 'motorhome' ? (
          <div className="search-filters__camping-car-form">
            {props.filters && props.filters['motorhome'] ? (
              props.filters['motorhome'].categories.map((category: SearchCategory) => {
                return renderSectionCategory(category);
              })
            ) : null}
          </div>
        ) : (
          <div className="search-filters__caravan">
            {props.filters && props.filters.caravan ? (
              props.filters.caravan.categories.map((category: SearchCategory) => {
                return renderSectionCategory(category);
              })
            ) : null}
          </div>
        )}

      </form>

    </div>
  );
}

export default memo(forwardRef(SearchFilters));

