import { FunctionComponent, useState, useEffect } from "react";
import {
  FullScreenPic,
  PageTitle,
  PlanningCalendar,
  PlanningResults,
} from "_components";
import {
  Availability,
  SearchResponseType,
  SearchParamsType,
  searchPlanningData,
  VehicleResult,
} from "_api";
import { AxiosResponse } from "axios";
import { useLoading } from "_hooks";
import { toast } from "react-toastify";
import { FiltersParams, Pagination, RequestParamsFromUrl } from "_types";
import { formatPrice, isEmptyObject } from "_utils";
import { useTranslation } from "react-i18next";
import "./planning-page.scss";
import { atomDealers, atomUser } from "_atoms";
import { useRecoilState, useRecoilValue } from "recoil";

const PAGINATION_STEP = 9;

const PlanningPage: FunctionComponent = () => {
  const { setLoading } = useLoading();
  const { t } = useTranslation();
  const [dealers] = useRecoilState(atomDealers);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>();
  const [planningResultsPeriod, setPlanningResultsPeriod] =
    useState<string | undefined>();
  const [fullScreenImg, setFullScreenImg] = useState<string | null>(null);
  const userLogged = useRecoilValue(atomUser);
  const [planningResults, setPlanningResults] = useState<{
    totalCount: number;
    results: VehicleResult[];
  }>({
    totalCount: 0,
    results: [],
  });

  const [pagination, setPagination] = useState<Pagination>({
    skip: 0,
    top: PAGINATION_STEP,
  });

  const getUrlParams = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const filtersString = queryParameters.get("filters");
    if (filtersString) {
      try {
      const params: {
        currentForm?: "motorhome" | "caravan";
        filters?: FiltersParams;
        labels?: string[];
        price?: number[];
      } = JSON.parse(filtersString);
      return params;
    } catch (error) {
      console.error("Erreur lors du parsing du JSON :", error);
      return {};
  }
    } else {
      return {};
    }
  };

  const getLabelsFromUrlParams = () => {
    const params = getUrlParams();
    if (params.price && params.price.length === 2) {
      if (!params.labels) params.labels = [];
      params.labels.push(
        t("price_label") +
          formatPrice(params.price[0]) +
          " - " +
          formatPrice(params.price[1])
      );
    }
    return params.labels;
  };

  const getRequestDataFromUrl = () => {
    const params = getUrlParams();
    const searchParams: RequestParamsFromUrl = {
      vehicule_type: params.currentForm === "motorhome" ? [1] : [2],
      price: params.price,
      filters: params.filters,
    };
    return searchParams;
  };

  // On load more => update pagination.
  const onLoadMore = () => {
    setPagination((previous) => {
      return {
        skip: previous.skip + PAGINATION_STEP,
        top: PAGINATION_STEP,
      };
    });
  };

  const loadData = (
    selectedPeriod: string | null,
    urlData: RequestParamsFromUrl | null,
    pagination: Pagination
  ) => {
    // setLoading(true);
    const params: SearchParamsType = {
      criterias: {
        vehicle_type: urlData?.vehicule_type ? urlData?.vehicule_type : [1],
        dealers: dealers,
      },
      skip: pagination.skip,
      top: pagination.top,
      sort: "availability_id asc, production_date asc",
      country: userLogged?.country,
    };
    if (selectedPeriod) {
      params.criterias.production_month = [selectedPeriod];
    }
    if (urlData && urlData.filters && !isEmptyObject(urlData.filters)) {
      for (const property in urlData.filters) {
        if (urlData.filters[property]) {
          for (const subProperty in urlData.filters[property]) {
            if (urlData.filters[property][subProperty]) {
              if (!params.criterias[property]) {
                params.criterias[property] = [];
              }
              params.criterias[property].push(subProperty);
            }
          }
        }
      }
    }
    if (urlData && urlData.price) {
      params.criterias.price = urlData.price;
    }
    searchPlanningData(params)
      .then(
        (result: AxiosResponse<SearchResponseType>) => {
          setPlanningResults((previous) => {
            const newResults =
              pagination.skip === 0
                ? result.data.value
                : [...previous.results, ...result.data.value];
            return {
              totalCount: result.data.count_total,
              results: newResults,
            };
          });
        },
        () => {
          toast.error(
            "Une erreur est survenue lors de la récupération des données"
          );
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  // Reinit pagination on dealers change (from header).
  useEffect(() => {
    if (pagination.skip > 0) {
      setPagination({
        skip: 0,
        top: PAGINATION_STEP,
      });
    } else {
      const filters = getRequestDataFromUrl();
      setLoading(true);
      loadData(selectedPeriod || null, filters || null, pagination);
    }
  }, [dealers]);

  // Reinit pagination on period change (with global loader).
  useEffect(() => {
    setLoading(true);
    if (selectedPeriod) {
      setPagination({
        skip: 0,
        top: PAGINATION_STEP,
      });
    }
  }, [selectedPeriod]);

  // Load data on filters change or pagination change or period change (without global loader).
  useEffect(() => {
    const filters = getRequestDataFromUrl();
    loadData(selectedPeriod || null, filters || null, pagination);
  }, [pagination]);

  return (
    <div className="planning">
      <FullScreenPic
        img={fullScreenImg}
        onClose={() => {
          setFullScreenImg(null);
        }}
      />
      <PageTitle
        title={t("planning_title")}
        description={t("planning_description")}
      />
      <div className="planning__main-content">
        <div className="planning__calendar">
          <PlanningCalendar
            planningResultsPeriod={planningResultsPeriod}
            onAvailabilitiesChange={(availabilities) =>
              setAvailabilities(availabilities)
            }
            onChange={(date) => setSelectedPeriod(date)}
            urlData={getRequestDataFromUrl()}
          />
        </div>
        <div className="planning__results">
          <PlanningResults
            availabilities={availabilities}
            filtersLabels={getLabelsFromUrlParams() || []}
            period={selectedPeriod}
            onLoadMore={onLoadMore}
            results={planningResults.results}
            totalCount={planningResults.totalCount}
            onPlanningResultsPeriodChange={setPlanningResultsPeriod}
            onSetFullScreenPic={setFullScreenImg}
          />
        </div>
      </div>
    </div>
  );
};
export default PlanningPage;
