import { FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './value-switch.scss';

export type ValueSwitchProps = {
  // shared.
  text: string;
  name: string;
  nbItems?: number;
  // native.
  value?: boolean;
  onChange?(value: boolean): (value: boolean) => void;
  // react hook form.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
}

const ValueSwitch: FunctionComponent<ValueSwitchProps> = (props) => {

  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {
          return (
            <button className={`value-switch ${field.value ? ' value-switch--active' : ''}`} onClick={(e) => {
              e.preventDefault();
              field.onChange(!field.value);
            }}>
              <span className="value-switch__text">{props.text} {typeof props.nbItems === 'number' ? <span className="value-switch__nb-items">({props.nbItems})</span> : null}</span>
            </button>
          );
        }} />
    );
  }
  else {
    return (
      <button className={`value-switch ${props.value ? ' value-switch--active' : ''}`} onClick={() => props.onChange ? props.onChange(!props.value) : null}>
        <span className="value-switch__text">{props.text} {typeof props.nbItems === 'number' ? <span className="value-switch__nb-items">({props.nbItems})</span> : null}</span>
      </button>
    )
  }

}

export default ValueSwitch;