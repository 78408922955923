import { FunctionComponent, useEffect, useState } from "react";
import { Modal, PageTitle } from "_components";
import DealersContactInformations from "_components/common/DealerContactInformations/DealerContactInformations";
import StockExchangeTable from "_components/common/Table/StockExchangeTable";
import { useLoading, useModal } from "_hooks";
import { StockExchangeResponseType, getStockExchangeProduct } from "_api";
import { AxiosError, AxiosResponse } from "axios";
import { } from "_api/endpoints/stockExchange";
import { toast } from "react-toastify";
import './stock-exchange-page.scss';
import { Trans, useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { atomDealers, atomUser } from "_atoms";

const StockExchangePage: FunctionComponent = () => {

  const { setLoading } = useLoading();
  const { setModal } = useModal();
  const { t } = useTranslation();
  const [currentStock, setCurrentStock] = useState<any>(null);
  const [stockExchangeProducts, setStockExchangeProducts] = useState<any[]>([]);
  const [dealers] = useRecoilState(atomDealers);

  const user = useRecoilValue(atomUser);

  const initData = () => {
    setLoading(true);
    getStockExchangeProduct(dealers,user?.country).then((exchangeResult: AxiosResponse<StockExchangeResponseType>) => {
      setStockExchangeProducts(exchangeResult.data.string);
      setLoading(false);
      
    }).catch((error: AxiosError) => {
      setLoading(false);
      if (error.response) {
        switch (error.response.status) {
          case 413:
            toast.error(t('toast_error_retrieving_azure_data_exchangeData'));
            break;
          case 414:
            toast.error(t('toast_error_retrieving_azure_data_searchStock'));
            break;
          case 415:
            toast.error(t('toast_error_when_retrieving_dealers_on_the_PIM'));
            break;
          case 412:
            toast.error(t('toast_not_found_in_stock_exchange'));
            break;
          default:
            toast.error(t('toast_get_data_error'));
        }
      } else {
        toast.error(t('toast_get_data_error'));
      }
    });
  }

  useEffect(() => {
    if (dealers && dealers.length) {
    initData();
    }
    else {
      setStockExchangeProducts([]);
    }
  }, [dealers]);

  const handleOpenModal = (stock: any) => {
    setModal(true)
   setCurrentStock(stock)
  };

  return (
    <div className="stockexchange">

      <PageTitle title={t('header_nav_exchange')}  description={<Trans i18nKey="header_nav_exchange_description"></Trans>} />

      <div className="stockexchange__main-wrapper">
      {stockExchangeProducts && stockExchangeProducts.length ?
        <StockExchangeTable stockExchangeProducts={stockExchangeProducts} setIsContactModal={handleOpenModal} />
          : null}
        {currentStock && (
          <Modal content={true} oncancel={() => setCurrentStock(null)} onValidate={() => setCurrentStock(null)}>
            <DealersContactInformations stock={currentStock} onClose={() => setCurrentStock(null)} />
          </Modal>
        )}

      </div>
    </div>
  );
}

export default StockExchangePage;
