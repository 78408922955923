import { createContext, useContext, useState } from "react";

type ModalProviderProps = { children: React.ReactNode }

const ModalContext = createContext<{ visible: boolean, setModal: ((value: boolean) => void) }>({
    visible: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setModal: (value: boolean) => { null }
});

export function ModalProvider(props: ModalProviderProps) {
    const [visible, setModal] = useState(false);
    return (
        <ModalContext.Provider value={{ visible, setModal }}>
            {props.children}
        </ModalContext.Provider>
    );
}

export function useModal() {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within provider");
    }
    return context;
}
