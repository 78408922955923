import { atom } from 'recoil';
import customStorage from './customStorage';
import { User } from '_types';

const key = 'user';

const atomUser = atom<User | null>({
  key,
  default: null,
  effects: customStorage(key),
});

export default atomUser;
