import { TFunction } from "i18next";
import { isEmptyObject } from "./utils";
import {
  PlanningCalendarAvailabilities,
  getPlanningCalendarData,
} from "../_api";
import { RequestParamsFromUrl, User } from "../_types";

export type IMonth = {
  label: string;
  monthNumber: string;
  year: number;
};

export type IMonthAvailability = {
  nbVehicle: number;
  isConfigurableNone: boolean;
  isConfigurablePartial: boolean;
  isConfigurableFull: boolean;
} & IMonth;

export type IPeriod = {
  minYear: number;
  maxYear: number;
};

export type IBoxMonth = {
  year: number;
  monthNumber: string;
};

export async function fetchPlanningCalendarData(
  dealers: string[],
  user: User | null,
  urlData?: RequestParamsFromUrl
): Promise<PlanningCalendarAvailabilities> {
  const _criterias: {
    [x: string]: Array<number | string>;
  } = {};
  if (urlData && !isEmptyObject(urlData)) {
    // Construct the criterias for regular filter categories.
    for (const property in urlData.filters) {
      if (urlData.filters[property]) {
        for (const subProperty in urlData.filters[property]) {
          if (urlData.filters[property][subProperty]) {
            if (!_criterias[property]) {
              _criterias[property] = [];
            }
            _criterias[property].push(subProperty);
          }
        }
      }
    }
  }
  if (urlData && urlData.price) {
    _criterias.price = urlData.price;
  }
  _criterias.vehicle_type =
    urlData && urlData.vehicule_type ? urlData.vehicule_type : [1];
    _criterias.dealers = dealers;
  const params = {
    criterias: _criterias,
    country: user?.country,
    top: 0,
  };
  const response = await getPlanningCalendarData(params);
  return {
    production_month: response.data["@search.facets"].production_month,
    configurable_months_none:
      response.data["@search.facets"].configurable_months_none,
    configurable_months_partial:
      response.data["@search.facets"].configurable_months_partial,
    configurable_months_full:
      response.data["@search.facets"].configurable_months_full,
  };
}

export function getMonths(currentPeriod: IPeriod, t: TFunction): IMonth[] {
  return [
    {
      label: t("planning_september"),
      monthNumber: "09",
      year: currentPeriod.minYear,
    },
    {
      label: t("planning_october"),
      monthNumber: "10",
      year: currentPeriod.minYear,
    },
    {
      label: t("planning_november"),
      monthNumber: "11",
      year: currentPeriod.minYear,
    },
    {
      label: t("planning_december"),
      monthNumber: "12",
      year: currentPeriod.minYear,
    },
    {
      label: t("planning_january"),
      monthNumber: "01",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_february"),
      monthNumber: "02",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_march"),
      monthNumber: "03",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_april"),
      monthNumber: "04",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_may"),
      monthNumber: "05",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_june"),
      monthNumber: "06",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_july"),
      monthNumber: "07",
      year: currentPeriod.maxYear,
    },
    {
      label: t("planning_august"),
      monthNumber: "08",
      year: currentPeriod.maxYear,
    },
  ];
}
