import { FunctionComponent, useEffect, useState } from "react";
import { Availability, PlanningCalendarAvailabilities } from "_api";
import { useLoading } from "_hooks";
import { padNumber } from "_utils";
import { toast } from "react-toastify";
import { RequestParamsFromUrl } from "_types";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { atomDealers, atomUser } from "_atoms";
import {
  fetchPlanningCalendarData,
  IBoxMonth,
  IPeriod,
} from "../../../_utils/planning-calendar";
import { PlanningCalendarMonths } from "./PlanningCalendarMonths/PlanningCalendarMonths";
import "./planning-calendar.scss";
import { PlanningCalendarLegend } from "./PlanningCalendarLegend/PlanningCalendarLegend";
import LocalLoader from "../LocalLoader/LocalLoader";

export type PlanningCalendarProps = {
  onChange: (monthDate: string) => void;
  onAvailabilitiesChange: (availabilities: Array<Availability>) => void;
  urlData?: RequestParamsFromUrl;
  planningResultsPeriod?: string;
};

const PlanningCalendar: FunctionComponent<PlanningCalendarProps> = (
  props: PlanningCalendarProps
) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const userLogged = useRecoilValue(atomUser);
  const [dealers] = useRecoilState(atomDealers);
  const [selectedBoxMonth, setSelectedBoxMonth] = useState<IBoxMonth>();
  const [monthsAvailabilities, setMonthsAvailabilities] =
    useState<PlanningCalendarAvailabilities>();

  function getCurrentPeriod(): IPeriod {
    const today = new Date();
    const currentMonth = today.getMonth();
    if (currentMonth >= 8 && currentMonth <= 11) {
      return {
        minYear: today.getFullYear(),
        maxYear: today.getFullYear() + 1,
      };
    } else {
      return {
        minYear: today.getFullYear() - 1,
        maxYear: today.getFullYear(),
      };
    }
  }

  const [currentPeriod, setCurrentPeriod] = useState<IPeriod>(
    getCurrentPeriod()
  );

  function handleRightArrowClick(): void {
    setCurrentPeriod({
      minYear: currentPeriod.minYear + 1,
      maxYear: currentPeriod.maxYear + 1,
    });
  }

  function handleLeftArrowClick(): void {
    setCurrentPeriod({
      minYear: currentPeriod.minYear - 1,
      maxYear: currentPeriod.maxYear - 1,
    });
  }

  function monthClick(year: number, monthNumber: number): void {
    const monthNumberString: string = padNumber(monthNumber, 2).toString();
    props.onChange(year.toString() + "-" + monthNumberString);
    setSelectedBoxMonth({ year, monthNumber: monthNumberString });
  }

  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const data = await fetchPlanningCalendarData(
        dealers,
        userLogged,
        props.urlData
      );
      setMonthsAvailabilities(data);
      setLoading(false);
    } catch (_unknown) {
      setLoading(false);
      toast.error(t("toast_get_data_error"));
    }
  };

  // Reload availabilities on dealers change (from header).
  useEffect(() => {
    void fetchDataFromBackend();
  }, [dealers]);

  // New period from planning results (arrow buttons).
  useEffect(() => {
    if (props.planningResultsPeriod) {
      const newPeriodSplitted = props.planningResultsPeriod.split("-");
      if (newPeriodSplitted.length == 2) {
        // Cast new period.
        const newPeriod = {
          year: Number(newPeriodSplitted[0]),
          month: Number(newPeriodSplitted[1]),
          monthString: newPeriodSplitted[1],
        };
        // Case 1: new month < september : new period : [Sep periodYear-1, Aou periodYear].
        if (newPeriod.month < 9) {
          setCurrentPeriod({
            minYear: newPeriod.year - 1,
            maxYear: newPeriod.year,
          });
        }
        // Case 2: new month >= september : new period : [Sep periodYear, Aou periodYear+1].
        else {
          setCurrentPeriod({
            minYear: newPeriod.year,
            maxYear: newPeriod.year + 1,
          });
        }
        // Select new box month.
        setSelectedBoxMonth({
          year: newPeriod.year,
          monthNumber: newPeriod.monthString,
        });
        // Update parent => will load new data.
        props.onChange(newPeriod.year.toString() + "-" + newPeriod.monthString);
      }
    }
  }, [props.planningResultsPeriod]);

  const dateRangeText = `${t("planning_september")} ${
    currentPeriod.minYear
  } - ${t("planning_august")} ${currentPeriod.maxYear}`;

  return (
    <div className="planning-calendar">
      <div className="planning-calendar__header">
        <button
          className="planning-calendar__button planning-calendar__button--left"
          onClick={handleLeftArrowClick}
        />
        <div className="planning-calendar__date-range">{dateRangeText}</div>
        <button
          className="planning-calendar__button planning-calendar__button--right"
          onClick={handleRightArrowClick}
        />
      </div>
      <hr className="planning-calendar__hr--calendar" />
      <div className="planning-calendar__title">
        <span className="planning-calendar__form-title">
          {t("planning_select_month")}
        </span>
      </div>
      {monthsAvailabilities === undefined ? (
        <div className="planning-calendar__loader">
          <LocalLoader />
        </div>
      ) : (
        <PlanningCalendarMonths
          currentPeriod={currentPeriod}
          selectedBoxMonth={selectedBoxMonth}
          monthsAvailabilities={monthsAvailabilities}
          onMonthClick={monthClick}
        />
      )}
      <hr className="planning-calendar__hr" />
      <PlanningCalendarLegend />
    </div>
  );
};

export default PlanningCalendar;
