import { AccountInfo } from '@azure/msal-browser';
import { atom } from 'recoil';
import customStorage from './customStorage';


const atomAccount = atom<AccountInfo | null>({
    key: 'accountInfo',
    default: null,
    effects: customStorage('accountInfo'),
});

export default atomAccount;
