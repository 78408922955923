import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import './table.scss';

type StockExchangeTableProps = {
  stockExchangeProducts: any[];
  setIsContactModal: (stock: any) => void;
}

const StockExchangeTable: FunctionComponent<StockExchangeTableProps> = ({ stockExchangeProducts, setIsContactModal }) => {

  const { t } = useTranslation();



  const handleClickContact = (stock: any) => {
    setIsContactModal(stock);
  }

 /* const openConfigurator = (stockproduct: any) => {
    const link = getConfiguratorLinkFromQuotation(
      stockproduct.CUNO,
      stockproduct.ORNO,
      stockproduct.FACI,
      stockproduct.ATNR,
      stockproduct.WHLO,
      stockproduct.ITNO,
      stockproduct.vehicleId,
      '',
      stockproduct.OREF,
      stockproduct.availability_id,
      stockproduct.year,
      stockproduct.SLOT,
      stockproduct.SCOM,
      dealers,
      userLogged ? userLogged.username : null,
    );
    window.open(link);
  };
*/
  return (
    <div className="page-container">

      <div className="table">

        <div className="table__header">
          <div className="table__sticky">
            <div className="table__group">
              <div className="table__title contact">{t('contact')}</div>
            </div>
          </div>
          <div className="table__group">
            <div className="table__title type">{t('type')}</div>
            <div className="table__title brand">{t('brand')}</div>
            <div className="table__title model">{t('model')}</div>
            <div className="table__title year">{t('year')}</div>
            <div className="table__title zip">{t('zip_code')}</div>
            <div className="table__title dealer-location">{t('point_of_sale')}</div>
            <div className="table__title commentary">{t('commentary')}</div>
          </div>
        </div>
        {
          // All other roles
          stockExchangeProducts.map((stockproduct) => (
            <div key={stockproduct.id} className="table__line">
              <div className="table__sticky">
                <div className="table__group">
                  <div className="table__item contact" onClick={() => handleClickContact(stockproduct)}></div>
                </div>
              </div>
              <div className="table__group">
                <div className="table__item type">{stockproduct.type}</div>
                <div className="table__item brand">{stockproduct.marque}</div>
                <div className="table__item model">{stockproduct.modele}</div>
                <div className="table__item year">{stockproduct.millesime_vehicle}</div>
                <div className="table__item zip">{stockproduct.point_of_sale.postal_code}</div>
                <div className="table__item dealer-location">{stockproduct.point_of_sale.name}</div>
                <div className="table__item commentary">{stockproduct.commentaire}</div>
                {/*<div className="table__item see-more">
                  <button className="see-more-btn" onClick={() => openConfigurator(stockproduct)}>{t('see_more_btn')}</button>
                 </div>*/}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default StockExchangeTable;
