import React, { FunctionComponent, memo, useState, useEffect } from "react";
import {
  CustomSelect,
  OptionSelect,
  SelectType,
  RowVehicle,
  VignetteCard,
  LocalLoader,
  Modal,
  GroupVehicles,
  GroupVignettes,
} from "_components";
import InfiniteScroll from "react-infinite-scroll-component";
import { VehicleResult } from "_api";
import { useTranslation } from "react-i18next";
import "../Modal/modal.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export type SearchResultsProps = {
  results: Array<VehicleResult>;
  //similarResults: Array<VehicleResult>;
  totalCount: number;
  onSortingChange: (
    sorting:
      | "availability_id asc, production_date asc"
      | "price asc"
      | "price desc"
  ) => void;
  onLoadMore: () => void;
  onSetFullScreenPic?: (picture: string) => void;
};

const SearchResults: FunctionComponent<SearchResultsProps> = (
  props: SearchResultsProps
) => {
  const { t } = useTranslation();
  const [view, setView] = useState<"raw" | "vignette">("raw");
  const sortingOptions: Array<OptionSelect> = [
    {
      name: "availability",
      label: t("search_order_availability"),
      value: "availability_id asc, production_date asc",
    },
    {
      name: "price-asc",
      label: t("search_order_price_asc"),
      value: "price asc",
    },
    {
      name: "price-desc",
      label: t("search_order_price_desc"),
      value: "price desc",
    },
  ];
  const [currentSorting, setCurrentSorting] = useState<OptionSelect>(
    sortingOptions[0]
  );
  const onSortingFilterChange = (data: OptionSelect) => {
    props.onSortingChange(
      data.value as
        | "availability_id asc, production_date asc"
        | "price asc"
        | "price desc"
    );
    setCurrentSorting(data);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalContentKey, setModalContentKey] = useState(0);

  useEffect(() => {
    if (isModalOpen) {
      setModalContentKey(modalContentKey + 1);
    }
  }, [modalContent, isModalOpen]);

  const setGroupModal = () => {
    if (isModalOpen) {
      return (
        <Modal
          oncancel={() => {
            setIsModalOpen(false);
            setModalContent([]);
          }}
          onValidate={() => {
            setIsModalOpen(false);
            setModalContent([]);
          }}
        >
          <Carousel key={modalContentKey} showThumbs={false}>
            {modalContent.map((group, index) => {
              return (
                <VignetteCard
                  key={index}
                  vehicle={group}
                  isPlanningResult={false}
                />
              );
            })}
          </Carousel>
        </Modal>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {setGroupModal()}

      <div className="search__list-content">
        <div className="search__header">
          <div className="search__header__results">
            <div className="search__count">
              <span className="search__count--value">{props.totalCount}</span>
              <span>{t("search_results_nb_vehicles")}</span>
            </div>
            <div className="search__view-mode">
              <span
                className={`${view === "raw" ? "active" : ""}`}
                onClick={() => setView("raw")}
              ></span>
              <span
                className={`${view === "vignette" ? "active" : ""}`}
                onClick={() => setView("vignette")}
              ></span>
            </div>
          </div>

          <div className="search__sorting-container">
            <CustomSelect
              name="order"
              optionsList={sortingOptions}
              placeHolder={t("search_results_order_by")}
              required
              values={currentSorting}
              type={SelectType.DARKMEDIUM}
              onChange={onSortingFilterChange}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="search__content">
          {view === "raw" ? (
            <InfiniteScroll
              dataLength={(props.results && props.results.length) || 0}
              next={() => props.onLoadMore()}
              loader={<LocalLoader />}
              className="search__scroll-container"
              hasMore={props.results.length < props.totalCount}
            >
              {props.results && props.results.length
                ? props.results.map((result: VehicleResult) => {
                    const group = result.nb_stock !== 1;
                    if (group) {
                      return (
                        <GroupVehicles
                          key={result.id}
                          vehicle={result}
                          onSetFullScreenPic={(picture) =>
                            props.onSetFullScreenPic
                              ? props.onSetFullScreenPic(picture)
                              : null
                          }
                        />
                      );
                    } else
                      return (
                        <RowVehicle
                          key={result.id}
                          vehicle={result}
                          isPlanningResult={false}
                          onSetFullScreenPic={(picture) =>
                            props.onSetFullScreenPic
                              ? props.onSetFullScreenPic(picture)
                              : null
                          }
                        />
                      );
                  })
                : null}
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              dataLength={(props.results && props.results.length) || 0}
              next={() => props.onLoadMore()}
              loader={<LocalLoader />}
              hasMore={props.results.length < props.totalCount}
              className="search__infinite-scroll search__infinite-scroll--vignette"
            >
              {props.results && props.results.length
                ? props.results.map((result: VehicleResult) => {
                    const group = result.nb_stock !== 1;
                    if (group) {
                      return (
                        <GroupVignettes
                          key={result.id}
                          vehicle={result}
                          onSetFullScreenPic={(picture) =>
                            props.onSetFullScreenPic
                              ? props.onSetFullScreenPic(picture)
                              : null
                          }
                          setIsModalOpen={setIsModalOpen}
                          setModalContent={setModalContent}
                        />
                      );
                    } else
                      return (
                        <VignetteCard
                          key={result.id}
                          vehicle={result}
                          isPlanningResult={false}
                          onSetFullScreenPic={(picture) =>
                            props.onSetFullScreenPic
                              ? props.onSetFullScreenPic(picture)
                              : null
                          }
                        />
                      );
                  })
                : null}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(SearchResults);
