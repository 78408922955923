import { ChangeEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './form-search.scss';

export type FormSearchProps = {
    // shared
    name: string;
    type: string;
    onEnter?: () => void;
    // native
    value?: string|number;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    // react-hook-form required
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
}

const FormSearch: FunctionComponent<FormSearchProps> = (props) => {
  if (props.control) {
    return (
      <Controller
        control={props.control}
        name={props.name}
        render={({field}) => {
          return (
            <div className="form-search">
              <input
                type={props.type}
                placeholder={props.placeholder}
                disabled={!!props.disabled}
                required={props.required}
                className={`form-search__input`} 
                value={field.value}
                onChange={field.onChange}
                onKeyDown={(e) => {
                  if (props.onEnter && e.key && e.key === 'Enter') {
                    e.preventDefault();
                    props.onEnter();
                  }}}
              />
            </div>
          );
        }}
      />
    );
  }
  else {
    return (
      <div className="form-search">
        <input
          type={props.type}
          placeholder={props.placeholder}
          disabled={!!props.disabled}
          required={props.required}
          className={`form-search__input`}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={(e) => {
            if (props.onEnter && e.key && e.key === 'Enter') {
              e.preventDefault();
              props.onEnter();
            }}}
        />
      </div>
    );
  }
}

export default FormSearch;