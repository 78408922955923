import { OrderResponseType, VehicleResult } from "_api";
import { OptionSelect } from "_components";
import { TFunction } from "i18next";

const REACT_APP_CONFIGURATOR = process.env.REACT_APP_CONFIGURATOR;

export const langsOptions = [
  {
    label: "Français",
    value: "fr",
    name: "fr",
  },
  {
    label: "English",
    value: "en",
    name: "en",
  },
  {
    label: "Español",
    value: "es",
    name: "es",
  },
  {
    label: "German",
    value: "de",
    name: "de",
  },
  {
    label: "Italiano",
    value: "it",
    name: "it",
  },
];

export const getOptionfromLocale = (local: string) => {
  const langOption = langsOptions.find((lang) => lang.value === local);
  if (langOption) return langOption as OptionSelect;
  else return langsOptions[0] as OptionSelect;
};

export function formatPrice(number: number) {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
  }).format(number);
}

export function padNumber(num: number, size: number) {
  let _num = num.toString();
  while (_num.length < size) _num = "0" + _num;
  return _num;
}

export function isEmptyObject(obj: object) {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export const monthsTranslationKeys = [
  "planning_calendar_january",
  "planning_calendar_february",
  "planning_calendar_march",
  "planning_calendar_april",
  "planning_calendar_may",
  "planning_calendar_june",
  "planning_calendar_july",
  "planning_calendar_august",
  "planning_calendar_september",
  "planning_calendar_october",
  "planning_calendar_november",
  "planning_calendar_december",
];

export function formatDate(date: string, t: TFunction) {
  const dateRegex = /^\d{4}-\d{2}$/;
  if (!dateRegex.test(date)) {
    return "";
  } else {
    const parts = date.split("-");
    const year = parts[0];
    const monthIndex = parseInt(parts[1]) - 1;
    const month = t(monthsTranslationKeys[monthIndex]);
    return `${month} ${year}`;
  }
}

export function isConfiguratorLinkPresents(vehicle: VehicleResult) {
  return (
    vehicle.WHLO !== null &&
    vehicle.itno_m3 &&
    vehicle.vehicle_id &&
    vehicle.FACI &&
    vehicle.availability_id
  );
  //return vehicle.ATNR !== null && vehicle.CUNO !== null && vehicle.FACI !== null && vehicle.WHLO !== null && vehicle.itno_m3 !== null && vehicle.vehicle_id !== null && vehicle.DANR !== null && vehicle.OREF !== null;
}

export function getConfiguratorLink(
  vehicle: VehicleResult,
  country: string | undefined,
  dealers: string[],
  usermail: string | null
) {
  let cuno;
  if (vehicle.availability_id === 1) {
    cuno = vehicle.CUNO;
  } else {
    cuno = "Z005";
  }
  let uri =
    REACT_APP_CONFIGURATOR +
    "/authent-redirection?cuno=" +
    encodeURI(cuno || "") +
    "&faci=" +
    encodeURI(vehicle.FACI || "") +
    "&atnr=" +
    encodeURI(vehicle.ATNR || "") +
    "&whlo=" +
    encodeURI(vehicle.WHLO || "") +
    "&itno=" +
    encodeURI(vehicle.itno_m3 || "") +
    "&vehicle_id=" +
    encodeURI(vehicle.vehicle_id || "") +
    "&danr=" +
    encodeURI(vehicle.DANR || "") +
    "&oref=" +
    encodeURI(vehicle.OREF || "") +
    "&orno=" +
    encodeURI(vehicle.ORNO || "") +
    "&availability_id=" +
    encodeURI(
      vehicle.availability_id !== null ? vehicle.availability_id.toString() : ""
    ) +
    "&dealerslist=" +
    encodeURI(dealers.toString()) +
    "&millesime=" +
    encodeURI(vehicle.millesime.toString()) +
    "&slot=" +
    encodeURI(vehicle.SLOT) +
    "&scom=" +
    encodeURI(vehicle.SCOM || "") +
    "&countries=" +
    encodeURI(country || "") +
    "&dead_line_production_date=" +
    encodeURI(vehicle.dead_line_production_date || "") +
    "&stat=" +
    encodeURI(vehicle.STAT || "") +
    "&stas=" +
    encodeURI(vehicle.STAS || "") +
    "&fidt=" +
    encodeURI(vehicle.FIDT || "") +
    "&dead_line_due_date=" +
    encodeURI(vehicle.dead_line_due_date || "");
  if (dealers && dealers.length) {
    uri += "&dealer=" + encodeURI(dealers[0]);
  }
  if (usermail) {
    uri += "&usermail=" + encodeURI(usermail);
  }

  return uri;
}

export function getConfiguratorLinkFromQuotation(
  CUNO: string,
  ORNO: string,
  FACI: string,
  ATNR: string,
  WHLO: string,
  itno_m3: string,
  vehicle_id: string,
  DANR: string,
  OREF: string,
  availability_id: string,
  millesime: string | null,
  SLOT: string,
  SCOM: string | null,
  dealers: string[],
  usermail: string | null
) {
  let uri =
    REACT_APP_CONFIGURATOR +
    "/authent-redirection?cuno=" +
    encodeURI(CUNO || "") +
    "&orno=" +
    encodeURI(ORNO || "") +
    "&faci=" +
    encodeURI(FACI || "") +
    "&atnr=" +
    encodeURI(ATNR || "") +
    "&whlo=" +
    encodeURI(WHLO || "") +
    "&itno=" +
    encodeURI(itno_m3 || "") +
    "&vehicle_id=" +
    encodeURI(vehicle_id || "") +
    "&danr=" +
    encodeURI(DANR || "") +
    "&oref=" +
    encodeURI(OREF || "") +
    "&millesime=" +
    encodeURI(millesime || "") +
    "&slot=" +
    encodeURI(SLOT) +
    "&scom=" +
    encodeURI(SCOM || "") +
    "&availability_id=" +
    encodeURI(availability_id || "") +
    "&dealerslist=" +
    encodeURI(dealers.toString());
  if (dealers && dealers.length) {
    uri += "&dealer=" + encodeURI(dealers[0]);
  }
  if (usermail) {
    uri += "&usermail=" + encodeURI(usermail);
  }
  return uri;
}

export const sortByProductionDate = (
  orders: OrderResponseType[]
): OrderResponseType[] => {
  const mergeSort = (arr: OrderResponseType[]): OrderResponseType[] => {
    if (arr.length <= 1) {
      return arr;
    }

    const middle = Math.floor(arr.length / 2);
    const left = arr.slice(0, middle);
    const right = arr.slice(middle);

    return merge(mergeSort(left), mergeSort(right));
  };

  const merge = (
    left: OrderResponseType[],
    right: OrderResponseType[]
  ): OrderResponseType[] => {
    const result: OrderResponseType[] = [];
    let leftIndex = 0;
    let rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      const dateA = new Date(left[leftIndex].productionDate);
      const dateB = new Date(right[rightIndex].productionDate);

      // Check if dates are valid before comparison
      if (isValidDate(dateA) && isValidDate(dateB)) {
        if (dateA.getTime() > dateB.getTime()) {
          result.push(left[leftIndex]);
          leftIndex++;
        } else {
          result.push(right[rightIndex]);
          rightIndex++;
        }
      } else {
        // Handle the case where one or both dates are invalid
        if (!isValidDate(dateA)) {
          result.push(right[rightIndex]);
          rightIndex++;
        } else {
          result.push(left[leftIndex]);
          leftIndex++;
        }
      }
    }

    return result.concat(left.slice(leftIndex), right.slice(rightIndex));
  };
  const isValidDate = (date: Date): boolean => !isNaN(date.getTime());
  return mergeSort([...orders]);
};

export const sortByRGD2Date = (
  orders: OrderResponseType[]
): OrderResponseType[] => {
  const mergeSort = (arr: OrderResponseType[]): OrderResponseType[] => {
    if (arr.length <= 1) {
      return arr;
    }

    const middle = Math.floor(arr.length / 2);
    const left = arr.slice(0, middle);
    const right = arr.slice(middle);

    return merge(mergeSort(left), mergeSort(right));
  };

  const merge = (
    left: OrderResponseType[],
    right: OrderResponseType[]
  ): OrderResponseType[] => {
    const result: OrderResponseType[] = [];
    let leftIndex = 0;
    let rightIndex = 0;

    while (leftIndex < left.length && rightIndex < right.length) {
      const rgd2A = parseFloat(left[leftIndex].RGD2);
      const rgd2B = parseFloat(right[rightIndex].RGD2);

      // Check if RGD2 values are valid numbers
      if (!isNaN(rgd2A) && !isNaN(rgd2B)) {
        if (rgd2A > rgd2B) {
          result.push(left[leftIndex]);
          leftIndex++;
        } else if (rgd2A < rgd2B) {
          result.push(right[rightIndex]);
          rightIndex++;
        } else {
          // If RGD2 values are equal, compare by nmureo (orderNumber)
          const nmureoA = parseInt(left[leftIndex].orderNumber);
          const nmureoB = parseInt(right[rightIndex].orderNumber);

          if (nmureoA > nmureoB) {
            result.push(left[leftIndex]);
            leftIndex++;
          } else {
            result.push(right[rightIndex]);
            rightIndex++;
          }
        }
      } else {
        // Handle the case where one or both RGD2 values are invalid
        if (isNaN(rgd2A)) {
          result.push(right[rightIndex]);
          rightIndex++;
        } else {
          result.push(left[leftIndex]);
          leftIndex++;
        }
      }
    }

    return result.concat(left.slice(leftIndex), right.slice(rightIndex));
  };

  return mergeSort([...orders]);
};
