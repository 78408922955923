import { AuthenticatedClient, GlobalSearchResponse } from "_api";

/*const orders = [
  {
    "id": "1ABC",
    "orderNumber": 326559,
    "year": "2020",
    "status": "Commande en attente concessionnaire",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "infoClient": "",
    "seriesNumber": 1121545455
  },
  {
    "id": "1ABC",
    "orderNumber": 326559,
    "year": "2020",
    "status": "Commande en attente concessionnaire",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "infoClient": "",
    "seriesNumber": 1121545455
  },
  {
    "id": "1ABC",
    "orderNumber": 326559,
    "year": "2020",
    "status": "Commande en attente concessionnaire",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "infoClient": "",
    "seriesNumber": 1121545455
  },
  {
    "id": "1ABC",
    "orderNumber": 326559,
    "year": "2020",
    "status": "Commande en attente concessionnaire",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "infoClient": "",
    "seriesNumber": 1121545455
  },
  {
    "id": "1ABC",
    "orderNumber": 326559,
    "year": "2020",
    "status": "Commande en attente concessionnaire",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "infoClient": "",
    "seriesNumber": 1121545455
  }
];
*/
/*
const quotes = [
  {
    "id": "1",
    "status": "Devis en cours",
    "quoteNumber": "55632233",
    "year": "2022",
    "clientName": "TESTTESTTESTTEST",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "price": 50000,
    "creationDate": "02/05/2023",
    "dealerName": "Caravane Cassegrain SA"
  },
  {
    "id": "2",
    "status": "Devis en cours",
    "quoteNumber": "55632233",
    "year": "2022",
    "clientName": "TESTTESTTESTTEST",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "price": 50000,
    "creationDate": "02/05/2023",
    "dealerName": "Caravane Cassegrain SA"
  },
  {
    "id": "3",
    "status": "Devis en cours",
    "quoteNumber": "55632233",
    "year": "2022",
    "clientName": "TESTTESTTESTTEST",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "price": 50000,
    "creationDate": "02/05/2023",
    "dealerName": "Caravane Cassegrain SA"
  },
  {
    "id": "4",
    "status": "Devis en cours",
    "quoteNumber": "55632233",
    "year": "2022",
    "clientName": "TESTTESTTESTTEST",
    "brand": "Benimar",
    "model": "Chausson Vans Road Line 2022 V594",
    "price": 50000,
    "creationDate": "02/05/2023",
    "dealerName": "Caravane Cassegrain SA"
  }
];
*/
export const globalSearch = (query: string, dealersId : Array<string> ): Promise<{ data: GlobalSearchResponse }> => {
  return AuthenticatedClient.post('/quicksearch/', {
    top: 20,
    query: query,
    criterias: {
      "dealerId": dealersId
    }
  });
  /*return new Promise((resolve) => {
    setTimeout(() => {
      const result = {
        data: {
          orders,
          quotes
        }
      };
      resolve(result);
    }, 1000)
  });
  */
};