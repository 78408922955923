import { FunctionComponent, useState } from "react";
import {  SelectPointOfSale } from "_components";
import { useTranslation } from "react-i18next";
import "./point-of-sale-modal.scss";
import {  point_of_sale } from "_api";


type PointOfSaleProps = {
  name: string;
  onSubmit?: () => void;
  onClose: () => void;
  selectedCUNO: string;
  onSelectedOptionChange: (pointOfSale: point_of_sale | undefined) => void;
};

export const PointOfSaleModalStock: FunctionComponent<PointOfSaleProps> = ({
  onClose,
  onSelectedOptionChange,
  selectedCUNO,
}) => {


  const [selectedpointOfSale, setselectedpointOfSale] = useState<point_of_sale>();

  const { t } = useTranslation();

  function handleSelectionChange(selectedPointOfSale: point_of_sale) {
    setselectedpointOfSale(selectedPointOfSale);
  }
  
  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSelectedOptionChange(selectedpointOfSale);
    onClose();
  };

  return (
    <>
      <div className="point-of-sale-modal">
        <div className="point-of-sale-modal__group-label">
          {t("modal_point_of_sale_label")} :
        </div>
        <form className="point-of-sale-modal__form">
          <div className="point-of-sale-modal__point-of-sale-select">
          <SelectPointOfSale selectedCUNO={selectedCUNO} onSelectionChange={handleSelectionChange} />
          </div>
          <div className="point-of-sale-modal__save-btn-container">
            <button
              onClick={handleSubmit}
              className="point-of-sale-modal__save-btn"
            >
              {t("validate")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PointOfSaleModalStock;
