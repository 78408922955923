import { FunctionComponent } from 'react';
import './icon-button.scss'

type IconButtonProps = {
  handlechange?: () => void;
  disabled?: boolean;

}

const IconButton: FunctionComponent<IconButtonProps> = (props) => {
  const { handlechange, disabled } = props;

  return (
    <label>
      <div className={`icon ${disabled ? 'disabled' : ''}`} onClick={disabled ? undefined : handlechange}>
      </div>
    </label>
  );
};

export default IconButton;