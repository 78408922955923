import { atom } from 'recoil';
import customStorage from './customStorage';


const key = 'dealersObjects';

const atomCOC = atom<{ CUNO: string ; name: string}[]>({
    key,
    default: [],
    effects: customStorage(key),

});

export default atomCOC;
