import { FormEvent, FunctionComponent } from "react";
import { Control, Controller } from "react-hook-form";
import './searchbar.scss';

export type SearchbarProps = {
    // shared
    onSubmit: () => void;
    // native
    value?: string|number;
    onChange?: (event: FormEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    // react-hook-form required
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: Control<any>;
}

const TextInput: FunctionComponent<SearchbarProps> = (props) => {
  if (props.control) {
    return (
      <Controller
        control={props.control}
        name="search"
        render={({field}) => {
          return (
            <div className="searchbar">
              <input
                type="search"
                placeholder={props.placeholder}
                disabled={!!props.disabled}
                required={props.required}
                className={`searchbar__input`}
                value={field.value}
                onChange={field.onChange}
              />
              <button className="searchbar__submit-button" onClick={props.onSubmit}></button>
            </div>
          );
        }}
      />
    );
  }
  else {
    return (
      <div className="searchbar">
        <input
          type="search"
          name="search"
          placeholder={props.placeholder}
          disabled={!!props.disabled}
          required={props.required}
          className={`searchbar__input`}
          value={props.value}
          onChange={props.onChange}
        />
        <button className="searchbar__submit-button" onClick={props.onSubmit} ></button>
      </div>
    );
  }
}

export default TextInput;