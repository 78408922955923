import { FunctionComponent } from "react";
import './filter-label.scss';

type FilterLabelProps = {
  label: string,
}

const FilterLabel: FunctionComponent<FilterLabelProps> = (props: FilterLabelProps) => {
  return (
    <span className="filter-label">
      {props.label}
    </span>
  );
}

export default FilterLabel