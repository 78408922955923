import { FunctionComponent, memo, useCallback } from "react";
import { VehicleResult } from "_api";
import { useModal } from "_hooks";
import { useTranslation } from "react-i18next";
import { formatPrice, getVehicleDateOrStatus, getVehicleStatusWording } from "_utils";
import './group-vehicles.scss';
import { useRecoilValue } from "recoil";
import { atomUser } from "_atoms";

type GroupVignettesProps = {
    vehicle: VehicleResult,
    isChildren?: boolean,
    onSetFullScreenPic?: (picture: string) => void;
    onClick?:()=>void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setIsModalOpen: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setModalContent: any;
}

const GroupVignettes: FunctionComponent<GroupVignettesProps> = (props) => {
    const vehicle = props.vehicle;
    const { t } = useTranslation();
    const userLogged = useRecoilValue(atomUser);

    const getStatusClassName = (vehicle: VehicleResult) => {
      if (vehicle.availability == 'disponible_ou_en_stock_concession')
          return 'result-card__overlay-header--available';
      else if (vehicle.availability == 'disponible_en_stock_usine_ou_sous_deux_semaines')
          return 'result-card__overlay-header--available';
      else if (vehicle.availability == 'commande_usine_ou_production_sous_x_semaines')
          return 'result-card__overlay-header--ordered';
      else if (vehicle.availability == 'prochaine_disponibilite_usine_ou_disponibilite_sous_x_semaine')
          return 'result-card__overlay-header--production';
      else
          return 'result-card__overlay-header--configurable';
    }

    const getPriceByCountry = (vehicle : any, country : any) => {
        switch (country) {
          case 'FR':
            return formatPrice(vehicle.price_fr);
          case 'DE':
            return formatPrice(vehicle.price_de);
          case 'ES':
            return formatPrice(vehicle.price_es);
          case 'IT':
            return formatPrice(vehicle.price_it);
          case 'EN':
            return formatPrice(vehicle.price_en);
          default:
            return formatPrice(vehicle.price);
        }
      };
    const setIsModalOpen = props.setIsModalOpen;
    const setModalContent = props.setModalContent;

    const { setModal } = useModal();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toggleModal = useCallback((vehicle: any) => () => { 
        setModal(true)
        setIsModalOpen(true);
        setModalContent(vehicle.config_group);
    }, []);

    // function to

    return (
      <div >
        <div className="result-card-wrapper">

          {vehicle.config_group?.length ?(
              <div className="result-card__overlay">
                  <div className={`result-card__overlay-header ${getStatusClassName(props.vehicle)}`}></div>
                  <div className="result-card__overlay-img"></div>
                  <div className="result-card__overlay-body"></div>
              </div>
          ):null} 

          <div className="result-card">
            {typeof props.vehicle.nb_stock === 'number' && props.vehicle.availability === 'disponible_ou_en_stock_concession' ? (
                <div className="result-card__stock-nb-container">
                    <div className="result-card__stock-nb">{props.vehicle.nb_stock}</div>
                    <div className="result-card__stock-status">{t('search_result_stock_label')}</div>
                </div>
            ) : null}

            <div className={`result-card__header ${getStatusClassName(props.vehicle)}`}>
                <div className="result-card__status">{getVehicleStatusWording(props.vehicle, t)}</div>
                <div className="result-card__date">{getVehicleDateOrStatus(props.vehicle, false, t)}</div>
            </div>

            <div className="result-card__background-img-container" onClick={() => props.onSetFullScreenPic ? props.onSetFullScreenPic(props.vehicle.image_implantationUrl) : null}>
                <div className="result-card__background-img" style={{ backgroundImage: `url(${props.vehicle.image_implantationUrl}` }}></div>
            </div>

            <div className="result-card__main-content">
                <div className="result-card__brand">{props.vehicle.brand_name}</div>
                <div className="result-card__config-name">{props.vehicle.config_name}</div>
                <div className="result-card__model">{props.vehicle.model}</div>
            </div>

            <div className="result-card__footer">
                <div className="result-card__various-infos-container">
                    <div className="result-card__item-info">
                        <div className="result-card__item-icon result-card__item-icon--seats"></div>
                        <div className="result-card__item-nb">{props.vehicle.seats_number}</div>
                    </div>
                    <div className="result-card__item-info">
                        <div className="result-card__item-icon result-card__item-icon--beds"></div>
                        <div className="result-card__item-nb">{props.vehicle.beds_number}</div>
                    </div>
                    <div className="result-card__item-info">
                        <div className="result-card__item-icon result-card__item-icon--length"></div>
                        <div className="result-card__item-nb">{Math.round(props.vehicle.length * 100) / 100}m</div>
                    </div>
                </div>

                <div className="result-card__price-container">
                    <div className="result-card__price-label">à partir de</div>
                    <div className="result-card__price">
                    {userLogged ? getPriceByCountry(vehicle, userLogged.country) : formatPrice(vehicle.price)}
                    </div>
                </div>
            </div>

            <div className="result-card__btn-container">
              <button className="result-card__btn" onClick={toggleModal(vehicle)}>{t('vehicle_list_state_see_more')} </button>
            </div>
          </div>
        </div>
      </div>
    );
}

export default memo(GroupVignettes);
