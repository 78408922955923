import { FunctionComponent } from "react";
import "./poc.scss";


const POC: FunctionComponent = () => {

  return (
    <div className="poc">

      <div className="poc__main-container">

        <h1>POC Page</h1>

        <div className="scroll-table">

          <div className="scroll-table__fixed-content">
            <div className="scroll-table__header">
              <div className="scroll-table__title">Validation</div>
              <div className="scroll-table__title">N° de Commande</div>
            </div>
            <div className="scroll-table__body">
              <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
             </div>
             <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
             </div>
             <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
             </div>
             <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
             </div>
             <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
             </div>
            </div>
          </div>

          <div className="scroll-table__scrollable-content">
            <div className="scroll-table__header">
              <div className="scroll-table__title">Millésime</div>
              <div className="scroll-table__title">Statut</div>
              <div className="scroll-table__title">Concession</div>
              <div className="scroll-table__title">Marque</div>
              <div className="scroll-table__title">Modèle</div>
              <div className="scroll-table__title">Accusé de réception</div>
              <div className="scroll-table__title">Info concessionnaire</div>
              <div className="scroll-table__title">Infos client</div>
              <div className="scroll-table__title">Date de production</div>
              <div className="scroll-table__title">N° de facture</div>
              <div className="scroll-table__title">Facture</div>
              <div className="scroll-table__title">Date de facturation</div>
              <div className="scroll-table__title">N° de série</div>
              <div className="scroll-table__title">Crédit/stock</div>
              <div className="scroll-table__title">Date d'enlèvement</div>
            </div>
            <div className="scroll-table__body">
              <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
              </div>
              <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
              </div>
              <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
              </div>
              <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
              </div>
              <div className="scroll-table__row">
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
                <div className="scroll-table__item">item</div>
              </div>            
            </div>
          </div>

        </div>


      </div>

    </div>
  );
};

export default POC;
