import axios, { AxiosRequestConfig } from 'axios';

const TOKEN_EXPIRE_CODE = 498;
const API_URL = process.env.REACT_APP_API_URL;
const MOCK_API_URL = process.env.REACT_APP_MOCK_API_URL;
let token: string | null = null;
let language: string | null = null;
let onTokenExpire: () => void = () => {null};

// Set new token.
export function setAuthorizationToken(newToken: string | null, _onTokenExpire: () => void) {
  token = newToken;
  onTokenExpire = _onTokenExpire;
}

// Set new language.
export function setApiLanguage(newLanguage: string | null) {
  language = newLanguage;
}

// Export clients.
export const AuthenticatedClient = axios.create({baseURL: API_URL});
export const AuthenticatedClientMock = axios.create({baseURL: MOCK_API_URL});
export const UnauthenticatedClient = axios.create({baseURL: API_URL});

// Inject token & language in authenticated client.
AuthenticatedClient.interceptors.request.use((config: AxiosRequestConfig) => {
  if (!token) {
    return Promise.reject(new Error('Trying to use authenticated client in a non-authenticated context'));
  }
  return {
    ...config,
    headers: {
      access_token: `${token}`,
      'accept_language': language ? language : '',
      Accept: 'application/json',
      ...config.headers,
    },
  };
});

// OnTokenExpire callback.
AuthenticatedClient.interceptors.response.use(undefined, (error) => {
  if (axios.isAxiosError(error) && error.response?.status === TOKEN_EXPIRE_CODE) {
    onTokenExpire();
  }
  return Promise.reject(error);
});
