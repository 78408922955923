import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stock } from "_api";
import './stock-exchange-sharing.scss';

type StockExchangeSharingProps = {
  onSubmit: () => void,
  onClose: () => void,
  onCancel: (orno : string) => void;
  onStockExchangeSharingChange: (StockExchange: string) => void;
};

const StockExchangeSharing: FunctionComponent<StockExchangeSharingProps> = ({ onSubmit, onClose, onCancel ,onStockExchangeSharingChange }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [stock] = useState<Stock | undefined>();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setValues(inputValue);
    onStockExchangeSharingChange(inputValue);
    setIsButtonDisabled(inputValue.trim() === '');
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onSubmit();
    onClose();
  };

  const handleCancel = () => {
    if (stock) {
      onCancel(stock.ORNO);
    } else {
      //onCancel(null);
    }
    onClose();
  };

  return (
    <div className="modal-stock-info">
      <div className="modal-stock-info__title">{t('stock_exchange_sharing')}</div>
      <form className="modal-stock-info__form">
        <textarea
          name="stock-exchange-comment"
          rows={5}
          cols={40}
          placeholder={t('comment')}
          value={values}
          onChange={handleChange}
        ></textarea>

        <div className="btn-container">
          <button className="save-btn" onClick={handleSubmit} disabled={isButtonDisabled}>
            {t('share_btn')}
          </button>
          <button className="cancel-btn" onClick={handleCancel}>
            {t('cancel_btn')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StockExchangeSharing;