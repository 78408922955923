import { FunctionComponent, useState } from 'react';
import './toggle-button.scss'

type ButtonProps = {
  toggled: boolean;
  handlechange?: () => void;
  disabled?: boolean;
}

const Toggle: FunctionComponent<ButtonProps> = (props) => {
  const [isToggled] = useState(props.toggled)
  const handleChange = props.handlechange;

  return (
    <label className={`toggle ${props.disabled ? 'disabled' : ''}`}>
      <input type="checkbox" defaultChecked={isToggled} onClick={handleChange} disabled={props.disabled} />
      <span />
    </label>
  )
}
export default Toggle;