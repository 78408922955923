import { FunctionComponent, useState } from "react";
import {  TextInput } from "_components";
import { useTranslation } from "react-i18next";
import './commentary-modal.scss';

type CommentaryProps = {
    name: string;
    commentary : string | null;
    onSubmit?: () => void;
    onClose: () => void;
    onSelectedOptionChange: (selectedOption: string) => void;
};

export const CommentaryModal: FunctionComponent<CommentaryProps> = ({ onClose ,onSelectedOptionChange ,commentary }) => {


    const [commentarySelectedOption, setCommentarySelectedOption] = useState({
        commentary : commentary
    });

    const { t } = useTranslation();

    const handleChange = (key: string, value: string) => {
        setCommentarySelectedOption((prev) => {
      return {
        ...prev,
        [key]: value
      }
    });
  };
   
  const handleSubmit = (event: any) => {

    onSelectedOptionChange(commentarySelectedOption.commentary || ''
    )
    event.preventDefault();
    onClose();
  };
    return (
        <>
            <div className="commentary-modal">
                <div className="commentary-modal__group-label">{t('modal_commentary_label')} :</div>
                <form className="commentary-modal__form">
                    <div className="commentary-modal__commentary-select">
                      <TextInput id="titleSelect" name="" placeholder={t('modal_commentary')} value={commentarySelectedOption.commentary || ''}
                        onChange={(e: any) => handleChange('commentary', e.target.value)}
                        maxLength={20} type="text"/>
                    </div>
                    <div className="commentary-modal__save-btn-container">
                        <button
                            onClick={handleSubmit}
                            className="commentary-modal__save-btn"
                        >
                            {t('validate')}
                        </button>
                    </div>
                </form>
            </div>
        </>

    );
}

export default CommentaryModal;