import { useTranslation } from "react-i18next";
import { ReactElement } from "react";
import {
  PlanningCalendarCard,
  TPlanningCalendarCardStyle,
} from "../PlanningCalendarCard/PlanningCalendarCard";
import "./planning-calendar-legend.scss";

export function PlanningCalendarLegend(): ReactElement {
  const { t } = useTranslation();

  const LegendItem = (props: {
    label: string;
    style: TPlanningCalendarCardStyle;
  }): ReactElement => {
    return (
      <div className="planning-calendar-legend__container">
        <PlanningCalendarCard style={props.style} />
        <span className="planning-calendar-legend__label">{props.label}</span>
      </div>
    );
  };

  return (
    <div className="planning-calendar-legend">
      <LegendItem
        label={t("planning_configurable_production_fully")}
        style={{ configurabilities: ["fully-configurable"] }}
      />
      <LegendItem
        label={t("planning_configurable_production_partially")}
        style={{ configurabilities: ["partially-configurable"] }}
      />
      <LegendItem
        label={t("planning_configurable_production_none")}
        style={{ configurabilities: ["not-configurable"] }}
      />
      <LegendItem
        label={t("planning_more_than_fifteen_vehicles_available")}
        style={{ quantity: "high" }}
      />
      <LegendItem
        label={t("planning_between_five_and_fifteen_vehicles_available")}
        style={{ quantity: "medium" }}
      />
      <LegendItem
        label={t("planning_less_than_five_vehicles_available")}
        style={{ quantity: "low" }}
      />
      <LegendItem
        label={t("planning_no_vehicle_available")}
        style={{ quantity: "none" }}
      />
    </div>
  );
}
