import { FunctionComponent } from "react";
import './not-found.scss';
import { Footer, Header, PageTitle } from "_components";
import { useNavigate } from "react-router-dom";
import '../../i18n';
import { Trans, useTranslation } from "react-i18next";

const NotFoundPage: FunctionComponent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    return (

        <div className="not-found">
            <Header />
            <PageTitle title={t('not_found_text')} />
            {/*
            <div className="not-found__header">
                <span className="not-found__header__text">
                    {t('not_found_text')}
                </span>
            </div>
            */}
            <div className="not-found__content">
                <div className="content-wrapper">
                    <div className="bg-wrapper"></div>
                    <div className="text-wrapper">
                        <span className="not-found__404">404</span>
                        <span className="lost-text">  {t('not_found_lost')}</span>
                        <span className="lost-advice"><Trans i18nKey="not_found_advice" /></span>
                        <span className="redirect-button" onClick={() => navigate('/')}> {t('not_found_home')}</span>
                    </div>
                </div>

            </div>
            <Footer />
        </div >);
}

export default NotFoundPage;