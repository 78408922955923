import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { atomToken, atomUserRole } from "_atoms";
import {
  AccountBasePage,
  LoginPage,
  AlertsBasePage,
  DealershipPage,
  OrdersPage,
  ProfilePage,
  QuotationPage,
  SearchPage,
  StockExchangePage,
  StockPage,
  ManageUsersPage,
  ContactsPage,
  NotFoundPage,
  PlanningPage,
  GeneralSearchPage,
  AccountStatementPage,
  AlertsPage,
  SettingsPage,
  POC,
} from "_pages";
import { GlobalLoader, Toast } from "_components";
import { setApiLanguage, setAuthorizationToken } from "_api";
import { LoadingProvider, ModalProvider } from "_hooks";
import ConnectedPage from "_pages/ConnectedPage/ConnectedPage";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ConformityCertificatePage from "_pages/ConformityCertificatePage/ConformityCertificatePage";
import PrivacyPolicyPage from "./_pages/PrivacyPolicy/PrivacyPolicyPage";
import LegalInfosPage from "./_pages/LegalInfos/LegalInfosPage";
//import { useMsal } from "@azure/msal-react";
//import { User } from '_types';
//import { AccountInfo, SilentRequest } from '@azure/msal-browser';

function App() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [token] = useRecoilState(atomToken);
  // const [accountInfo] = useRecoilState(atomAccount);
  const resetToken = useResetRecoilState(atomToken);
  // const { instance } = useMsal();
  /* const setUser = useSetRecoilState(atomUser);
               const setAccount = useSetRecoilState(atomAccount);
               const setToken = useSetRecoilState(atomToken);
               const { setLoading } = useLoading();
               const { t } = useTranslation();
             */
  const [userRole] = useRecoilState(atomUserRole);

  const isADV = userRole?.name === " ADV";
  const isCommercial = userRole?.name === "Manager_commercial";
  const isCommercialLimite = userRole?.name === "Commercial";
  const isDAF = userRole?.name === "DAF";

  useEffect(() => {
    // const language = i18n.language;
    const language = i18n.resolvedLanguage;
    document.documentElement.setAttribute("lang", language);
    setApiLanguage(language);
  }, []);

  useEffect(() => {
    setAuthorizationToken(token, () => {
      // On token expire.
      resetToken();
      navigate("/login");
      setTimeout(() => {
        toast.dismiss();
      });
      /*
                                          instance.acquireTokenSilent({ account : accountInfo} as SilentRequest).then((result) => {
                                    
                                            console.log('2 - login success: ', result);
                                            const _token = result.accessToken;
                                      
                                            if (result.account) {
                                              console.log("account",result.account)
                                              setAccount(result.account as AccountInfo);
                                              const user: User = {
                                                id: result.uniqueId,
                                                username: result.account?.username || '',
                                                name: result.account?.name || '',
                                                completeName: '',
                                                country : ''
                                              }
                                              setUser(user);
                                            }
                                            console.log('token: ', _token);
                                            setToken(_token);
                                            //navigate('/search');
                                          }, (error) => {
                                            setLoading(false);
                                            console.log('3 - login error: ', error);
                                            toast.error(t('login_error'));
                                          });
                                          */
    });
  }, [token]);

  i18n.on("languageChanged", () => {
    const language = i18n.language;
    document.documentElement.setAttribute("lang", language);
    setApiLanguage(language);
  });

  return (
    <ModalProvider>
      <Toast />
      <LoadingProvider>
        <GlobalLoader />
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/" element={<ConnectedPage />}>
            <Route path="/account" element={<AccountBasePage />}>
              <Route path="profile" element={<ProfilePage />} />
              <Route
                path="manage-users"
                element={
                  isDAF ||
                  isCommercial ||
                  isCommercialLimite ||
                  isADV ? (
                    <Navigate to={"*"} />
                  ) : (
                    <ManageUsersPage />
                  )
                }
              />
              <Route path="contacts" element={<ContactsPage />} />
              <Route path="" element={<Navigate to="/account/profile" />} />
            </Route>
            <Route path="/alerts" element={<AlertsBasePage />}>
              <Route path="all" element={<AlertsPage />} />
              <Route path="settings" element={<SettingsPage />} />
            </Route>
            <Route path="/dealership" element={<DealershipPage />}></Route>
            <Route path="/orders" element={<OrdersPage />}></Route>
            <Route path="/quotation" element={<QuotationPage />}></Route>
            <Route path="/search" element={<SearchPage />}></Route>
            <Route path="/planning" element={<PlanningPage />}></Route>
            <Route path="/exchange" element={<StockExchangePage />}></Route>
            <Route path="/stock" element={<StockPage />}></Route>
            <Route
              path="/account-statement"
              element={
                isCommercialLimite || isCommercial || isADV ? (
                  <Navigate to={"*"} />
                ) : (
                  <AccountStatementPage />
                )
              }
            />
            <Route
              path="/conformity-certificate"
              element={
                isDAF ? <Navigate to={"*"} /> : <ConformityCertificatePage />
              }
            />
            <Route
              path="/general-search"
              element={<GeneralSearchPage />}
            ></Route>
            <Route path="/poc" element={<POC />}></Route>
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicyPage />}
            ></Route>
            <Route path="/legal-infos" element={<LegalInfosPage />}></Route>
            <Route path="/" element={<Navigate to="/search" />} />
          </Route>
        </Routes>
      </LoadingProvider>
    </ModalProvider>
  );
}

export default App;
