import { AxiosResponse } from 'axios';
import { AuthenticatedClient, InfoClient, StockResponseType, point_of_sale} from '_api';
import { User } from '_types';

export const getStock = (dealerslist: string[] , country : string | undefined): Promise<AxiosResponse<StockResponseType>> => {
   const params = {
    dealerslist,
    country 
  };
  return AuthenticatedClient.post('/instock',params);
};


export const getStockById = (id: string): Promise<AxiosResponse<StockResponseType[]>> => {
  return AuthenticatedClient.get('/quotedetailed/' + id);
};

export const updateStock = ( ORNO : string | null ,CUNO : string | null , client : InfoClient | null ,user : User | null,selectedPointOfSale : string | null , stockExchange : boolean  ): Promise<AxiosResponse<void>> => {
  const params = {
    finalclient: {
      firstName: client?.firstName,
      lastName: client?.lastName,
      clientPhone: client?.clientPhone,
      clientEmail: client?.clientEmail,
    },
    ORNO: ORNO,
    CUNO : CUNO,
    stockExchange  :  stockExchange, // true  ou false 
    pointOfSale: selectedPointOfSale,
    dealerEmail : user?.username,
    country_user : user?.country
  };
  return AuthenticatedClient.post('/instock/updateinfo', params);
};

export const getPointOfSaleList = (CUNO: string): Promise<AxiosResponse<{ point_of_sale: point_of_sale[] }>> => {
  return AuthenticatedClient.get(`/point_of_sale?CUNO=${CUNO}`);
};

export const updatePointOfSale = ( ORNO : string | undefined , ADID : string | null ,point_of_sale : point_of_sale): Promise<AxiosResponse<void>> => {
  const params = {
    ORNO: ORNO,
    ADID : ADID,
    point_of_sale : point_of_sale,
  };
  return AuthenticatedClient.post('/change_point_of_sale/', params);
};

export const updatePointOfSaleOrders = ( ORNO : string | undefined  ,point_of_sale : point_of_sale): Promise<AxiosResponse<void>> => {
  const params = {
    ORNO: ORNO,
    point_of_sale : point_of_sale,
  };
  return AuthenticatedClient.post('/change_point_of_sale_order/', params);
};