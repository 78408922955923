import { FunctionComponent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { atomUser } from "_atoms";
import { CardContact } from "_components";
import { useLoading } from "_hooks";
import { ContactResponseType, getContacts } from "_api";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import './contacts-page.scss';
import { useTranslation } from "react-i18next";

const ContactsPage: FunctionComponent = () => {
    const userLogged = useRecoilValue(atomUser);
    const [contacts, setContacts] = useState<ContactResponseType[]>([]);
    const { setLoading } = useLoading();
    const { t } = useTranslation();


    useEffect(() => {
        if (userLogged && userLogged.username) {
            setLoading(true);
            getContacts(userLogged.username).then((result: AxiosResponse<ContactResponseType[]>) => {
                setContacts(result.data);
                setLoading(false);
            }, () => {
                setLoading(false);
                toast.error(t('toast_get_data_error'));
            });
        }
    }, []);

    return (
        <div className="contacts">
            {contacts.map((contact) => <ul key={contact.id}>
                <li>
                    <CardContact contact={contact} />
                </li>
            </ul>)}
        </div>
    );
}





export default ContactsPage;