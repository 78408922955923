import { useEffect } from "react";

export function useOutsideClick(ref: React.RefObject<HTMLInputElement>, action: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element.
         */
        function handleClickOutside({target}: MouseEvent) {
            if (ref.current && target && !ref.current.contains(target as Node)) {
                action();
            }
        }
        // Bind the event listener.
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up.
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
