import DOMPurify from "dompurify";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../_components";
import "./privacy-policy-page.scss";

const PrivacyPolicyPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <PageTitle title={t("privacy_policy_title")} />
      <div className="privacy-policy__content">
        <h2>{t("privacy_policy_private_data_protection")}</h2>
        <div
          className="privacy-policy__box"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("privacy_policy_private_data_protection_content_html")
            ),
          }}
        />
        <h3>{t("privacy_policy_when")}</h3>
        <div
          className="privacy-policy__box"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t("privacy_policy_when_content_html")),
          }}
        />
        <h3>{t("privacy_policy_who")}</h3>
        <div className="privacy-policy__box">
          {t("privacy_policy_who_content")}
        </div>
        <h3>{t("privacy_policy_where")}</h3>
        <div
          className="privacy-policy__box"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t("privacy_policy_where_content_html")),
          }}
        />
        <h3>{t("privacy_policy_cookies")}</h3>
        <div
          className="privacy-policy__box"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("privacy_policy_cookies_content_html")
            ),
          }}
        />
        <h3>{t("privacy_policy_commitments")}</h3>
        <div
          className="privacy-policy__box"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("privacy_policy_commitments_content_html")
            ),
          }}
        />
        <h2>{t("privacy_policy_your_rights")}</h2>
        <div
          className="privacy-policy__box"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("privacy_policy_your_rights_content_html")
            ),
          }}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
