import { FunctionComponent } from "react";
import { NavLink, Outlet } from "react-router-dom";
import './alerts-base-page.scss';
import '../../i18n';
import { useTranslation } from "react-i18next";
import { PageTitle } from "_components";

const getNavClass = ({ isActive }: { isActive: boolean }) => {
  if (isActive) {
    return 'alerts-layout__header__link--active ';
  }
  else {
    return 'alerts-layout__header__link';
  }
}

const AlertsBasePage: FunctionComponent = () => {
  const { t } = useTranslation()

  return (

    <div className="alerts-layout">

      <PageTitle title={t('account_notifications')} description={t('account_nav_manage_notifications')} />

      <div className="alerts-layout__main-content-wrapper">
        <div className="alerts-layout__menu">

          <nav>
            <NavLink
              to="/alerts/all"
              className={getNavClass}
            >
              <div className="icon-bell"></div>
              <div>{t('all')}</div>
            </NavLink>
            <NavLink
              to="/alerts/settings"
              className={getNavClass}
            >
              <div className="icon-handshake"></div> {/* remplacer par rouage */}
              <div>{t('settings')}</div>
            </NavLink>
          </nav>
        </div>

        <div className="alerts-layout__content">
          <Outlet />
        </div>

      </div>
    </div>);
}

export default AlertsBasePage;